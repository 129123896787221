import React from 'react'
import { Typography } from '@mui/material'
import styles from './style.module.scss'

const ReleaseOfUSCATokenContent = () => {
  return (
    <>
      <Typography className={styles.title}>Dear Clients and Partners!</Typography>
      <Typography className={styles.subtitle}>
        SCA ALLIANCE announces the successful completion of another key milestone in its strategic
        development according to the Road Map.
      </Typography>
      <Typography className={styles.subtitle}>
        We are pleased to announce the release of a new token USCA token of the SCA ALLIANCE
        project, the decision on which was made in accordance with the agreement reached at the
        level of senior management of the companies:
      </Typography>
      <Typography component={'ul'} className={styles.listWrapper}>
        <Typography component={'li'}>SCA Alliance Ltd, UK.</Typography>
        <Typography component={'li'}>247 & Co. ApS, Denmark.</Typography>
        <Typography component={'li'}>Scandinavian Capital Association ApS, Denmark.</Typography>
        <Typography component={'li'}>Imperial Insurance Broker Ltd, UK.</Typography>
      </Typography>

      <Typography className={styles.title}>New functionality and new features</Typography>
      <Typography className={styles.subtitle}>
        USCA token will be equipped with additional functions to be added in nearest future:
      </Typography>
      <Typography component={'ul'} className={styles.listWrapper}>
        <Typography component={'li'}>
          Possibility of tracking the trading results of Mutual Funds on the Stock Market!
        </Typography>
        <Typography component={'li'}>
          Possibility of test staking of tokens or so-called Paper Trading!
        </Typography>
        <Typography component={'li'}>
          Partner training courses paid in USCA tokens on various aspects of working in SCA ALLIANCE
          and 247andCo. Company!
        </Typography>
      </Typography>

      <Typography className={styles.title}>Payments to USCA</Typography>
      <Typography className={styles.subtitle}>
        We note that all payments for previously launched mutual investment funds will be made in a
        new USCA. Having received income and/or income along with the body of the previously placed
        investment, each user will be able to exchange the USCA token through the exchange for any
        of the stablecoins serviced by the project - USDT (BEP-20) or USDC (BEP-20).
      </Typography>

      <Typography className={styles.title}>Displaying a token on the exchange (listing)</Typography>
      <Typography className={styles.subtitle}>
        The new USCA token is intended to be listed on the cryptoexchanges, which will attract a
        significant number of new potential investors, since trading will be open, which means that
        anyone can purchase this token on the open market. This will cause a significant increase in
        demand for the token and an increase in its price!
      </Typography>

      <Typography className={styles.title}>SCA/USCA tokens exchange</Typography>
      <Typography className={styles.subtitle}>
        All available USCA tokens must be exchanged for new USCA in Your personal account (only the
        owner of USCA tokens can do this by connecting using their own wallet). No one except the key
        owner has access to the wallet funds: neither the project administration, nor the support
        service specialists or the 247&Co. Company will be able to do this, since they do not own
        the key to the Blockchain wallet, on which the funds are completely safe. To carry out the
        exchange, you will need to sign one transaction using MetaMask or Trustwallet. This means
        only one standard commission provided by the Binance Smart Chain Blockchain Network, that is
        approximately 20 US cents in BNB .
      </Typography>

      <Typography className={styles.title}>Specialized funds for bonus tokens</Typography>
      <Typography className={styles.subtitle}>
        Clients and partners have the opportunity to invest bonus tokens received in December 2023 –
        February 2024. Investment can be made in any of the active Mutual Funds of your choice.
      </Typography>
    </>
  )
}

export default ReleaseOfUSCATokenContent
