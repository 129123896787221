import { getBlock } from '@wagmi/core';
import { GetBlockParameters, Log } from 'viem';
import { GetBlockReturnType } from '@wagmi/core/src/actions/getBlock';
import { APP_CONFIG } from '../../../pages/_app';
import { Config } from 'wagmi';

interface IHasTransactionHash {
  transactionHash: string | null;
}
export const addEventIfNotExists = <T extends IHasTransactionHash>(
  currentEvents: T[],
  newEvent: T,
): T[] => {
  const eventExists = currentEvents.some(
    (event) => event?.transactionHash === newEvent?.transactionHash,
  );
  return !eventExists ? [...currentEvents, newEvent] : currentEvents;
};

export const getBlockByBlockHash = async (
  blockHash: `0x${string}` | null,
): Promise<GetBlockReturnType> => {
  const config: Config = APP_CONFIG;
  return (await getBlock(config, {
    blockHash: blockHash,
  } as GetBlockParameters)) as GetBlockReturnType;
};
