import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { ECurrentModal, ETxStatus } from '@app/types'
import { IModalContext } from './modalContextTypes'

export const ModalContext = createContext<IModalContext>({} as IModalContext)

type Props = {
  children: React.ReactNode
}

export const ModalContextProvider = ({ children }: Props) => {
  //boolean
  const [isClosable, setIsClosable] = useState<boolean>(true)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [titleName, setTitleName] = useState<string>('')
  const [currentModal, setCurrentModal] = useState<IModalContext['currentModal']>('')
  const [txData, setTxData] = useState<IModalContext['txData']>({
    status: ETxStatus.LOADING,
    description: '',
  })
  const [customContentClass, setCustomContentClass] = useState('')
  const [referralLinkProps, setReferralLinkProps] = useState<IModalContext['referralLinkProps']>()

  const [imageDataProps, setImageDataProps] = useState<IModalContext['imageDataProps']>({
    image: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
  })

  const [notificationContentProps, setNotificationContentProps] = useState<ReactNode>(null)

  useEffect(() => {
    if (currentModal) {
      setIsOpen(true)
    }
  }, [currentModal])

  const close = () => {
    setCurrentModal('')
    setIsOpen(false)
  }

  const openTxStatus = () => {
    setTxData({
      status: ETxStatus.LOADING,
      description: '',
    })
    setCurrentModal(ECurrentModal.TX_STATUS)
  }

  // TODO refactor
  const openNeedMM = () => {
    setTxData({
      status: ETxStatus.LOADING,
      description: '',
    })
    setCurrentModal(ECurrentModal.AUTH__NEED_MM)
  }

  const openConfirmEmailPhone = () => {
    setTxData({
      status: ETxStatus.LOADING,
      description: '',
    })
    setCurrentModal(ECurrentModal.COMFIRM_EMAIL_PHONE)
  }

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        isClosable,
        currentModal,
        txData,
        titleName,
        customContentClass,
        notificationContentProps,
        close,
        setCurrentModal,
        setIsClosable,
        setTxData,
        setTitleName,
        setCustomContentClass,
        openTxStatus,
        openNeedMM,
        openConfirmEmailPhone,
        referralLinkProps,
        setReferralLinkProps,
        imageDataProps,
        setImageDataProps,
        setNotificationContentProps,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContext
