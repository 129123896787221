import { IGridData } from './fetchData';
import { IUserPackage } from '@app/stores/auth/authTypes';

export type EPackagesHistoryStatusType = keyof typeof EPackagesHistoryStatus & keyof typeof EBonusPackagesHistoryStatus;

export interface IPackage {
	id: string;
	name: string; //title
	tokens: string; // accrued MTOK
	commission: string;
	price: string; // price in USCA
	tokenPrice: string; //rate for purchasing additional tokens
	additionalTokens: string | null; //additional tokens MTOK
	currency: string;
	availableToPurchase?: string[];
	isLimitless: boolean;
}

export interface IAdditionalTokenAvailable {
	limit: number,
	isLimitless: boolean;
}


export interface IPackageHistory extends IGridData {
	data: IPackageHistoryItem[];
}

export interface IPackageHistoryItem {
	createdAt: string;
	package: {
		additionalTokens: number | null;
		commission: number | null;
		id: string;
		name: string;
		tokens: number | null;
		price: number | null;
		status?: string;
	};
	status: EPackagesHistoryStatus,
}

export interface IBonusPackageHistoryItem {
	createdAt: string;
	id: number,
	packageName: string,
	type: EBonusPackagesHistoryStatus,
}

export interface IHistoryParams {
	key: EHistoryKeys;
	page: number;
	take: number;
	status: EPackagesHistoryStatus | string;
	'date-before': Date | null | string; //dateTo
	'date-after': Date | null | string; //dateFrom
}

export interface IColumnData {
	id: number;
	title: string;
	fieldName: string; //*на его основе выводятся данные колонки
	valueGetter?: (params: any) => string | number | null | React.ReactNode; //* кастомизировать вывод данных
}

// bonus packages
export interface IBonusPackage {
	id: string,
	name: string,
	tokens: number,
	additionalTokens: number,
	commission: number,
	price: number,
	tokenPrice: number,
	currency: string,
	availableToPurchase: string[]
}

export interface IAcceptBonusPackage {
	bonusPackageId: IBonusPackage['id'],
	tokens: IBonusPackage['tokens'],
}

export interface IAcceptBonusPackageWithCallback {
	bonusPackageId: IBonusPackage['id'],
	tokens: IBonusPackage['tokens'],
}

export interface IAcceptBonusPackageResponse {
	packageInfo: IBonusPackage,
	success: boolean,
}

export interface IGiveBonusPackage {
	userAddress: string, // wallet address
	bonusPackageId: IBonusPackage['id'],
	tokens: IBonusPackage['tokens'],
}

export interface IGiveBonusPackageHandler {
	bonusPackageId: IBonusPackage['id'],
	tokens: IBonusPackage['tokens'],
}

export interface IGiveBonusPackageWithCallback {
	userAddress: string,
	bonusPackageId: IBonusPackage['id'],
	tokens: IBonusPackage['tokens'],
}

export interface IGiveBonusPackageResponse {
	bonusPackageId: IBonusPackage['id'],
	success: boolean,
}

export interface ISetPackageReturn {
	previousPackagePrice?: number
	item: IUserPackage,
	id: string,
}

export interface IGetAvailableList {
	item: IUserPackage,
}

export interface ISSRProps {
	packages: IPackage[] | [],
}

// enums
export enum EHistoryKeys {
	TOKEN = 'token',
	PACKAGES = 'packages',
	BONUS = 'packages-bonus',
	STAKING = 'staking',
	CLAIM = 'claim',
}

export enum EPackagesHistoryStatus {
	ALL = 'All',
	ACTIVE = 'active',
	EXPIRED = 'expired',
}

export enum EBonusPackagesHistoryStatus {
	ALL = 'All',
	RECEIVED = 'received',
	ASSIGNED = 'assigned',
	RECEIVED_AND_ACTIVATED = 'received_and_activated',
	ASSIGNED_AND_ACTIVATED = 'assigned_and_activated',
}

//variables
export const AllPackagesHistoryStatus = {
  ...EPackagesHistoryStatus,
  ...EBonusPackagesHistoryStatus,
};

