import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogContentText, DialogTitle, IconButton,Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import style from './style.module.scss';

type Props = {
  title: string;
  open: boolean;
  handleClose?: () => void;
  children?: React.ReactNode;
  iconTitle?: React.ReactNode;
  sx?: { [key: string]: any };
};

export const CustomDialog: React.FC<Props> = ({ open, handleClose, title, children, sx,iconTitle }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        classes: {
          root: style.content,
        },
      }}
      sx={sx}
    >
      <DialogTitle>
        <Box sx={{display:'flex'}}>
          {iconTitle? <Box mr={2}>{iconTitle}</Box>:''}
        {title}
        </Box>
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            classes={{
              root: style.btnClose,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'}>
          <div className={style.dialogText}>{children}</div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
