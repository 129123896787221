import {
  ACCEPT_PARTNERSHIP,
  AUTH,
  EMAIL_VERIFICATION,
  GENERATE_LINK,
  GET_ME,
  PHONE_VERIFICATION,
  USER,
  WELCOME_TOKEN,
} from '../../constants/routes';
import { httpClient } from '../httpClient';
import { IVerificationRequest } from '../../types';
import {
  IAcceptPartnership,
  IGenerateLink,
  IReqData,
  IUser,
  IUserData,
  IUserSettings,
} from '../../stores/auth/authTypes';

export const authService = {
  setAuthToken(token: string) {
    localStorage.setItem('token', token);
    httpClient.setAxiosToken(token);
  },

  deleteAuthToken() {
    localStorage.removeItem('token');
    httpClient.deleteAxiosToken();
  },

  getAuthToken(): string | null {
    return localStorage.getItem('token');
  },

  getWelcomeToken() {
    return httpClient.get<{ welcomeToken: string }>(WELCOME_TOKEN);
  },
  // need to catch 400 error
  getAuthResponse(data: IReqData) {
    return httpClient.put(AUTH, data).catch((err) => err);
  },

  getMe() {
    return httpClient.get<IUserData>(GET_ME);
  },

  createUser(customData: IUserSettings) {
    return httpClient.post<IUser>(USER, customData);
  },

  async updateUser(data: IUserSettings) {
    return await httpClient.patch<IUser>(USER, data);
  },

  sendEmailVerificationRequest() {
    return httpClient.get<IVerificationRequest>(EMAIL_VERIFICATION);
  },

  verifyEmail(code: string) {
    const urlParams = new URLSearchParams({ code }).toString();
    return httpClient.post(`${EMAIL_VERIFICATION}?${urlParams}`);
  },

  sendPhoneNumberVerificationRequest() {
    return httpClient.get<IVerificationRequest>(PHONE_VERIFICATION);
  },

  verifyPhoneNumber(code: string) {
    const urlParams = new URLSearchParams({ code }).toString();
    return httpClient.post(`${PHONE_VERIFICATION}?${urlParams}`);
  },

  deleteUser() {
    return httpClient.delete(USER);
  },

  acceptPartnership() {
    return httpClient.post<IAcceptPartnership>(ACCEPT_PARTNERSHIP);
  },

  generateLink() {
    return httpClient.get<IGenerateLink>(GENERATE_LINK);
  },
};
