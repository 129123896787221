import {httpClient} from '../httpClient';

export type EventsHistory = {
    id: string;
    userAddress: string;
    eventTime: string;
    eventType: string;
    packageId?: number;
    tokens: string;
    transactionHash: string;
};

export const usersHistoryService = {
    async getUsersHistory(params: {
        address: string;
    }) {
        const { data } = await httpClient.get<EventsHistory[]>(
            '/users/history/userdata',
            { params }
        );
        return data;
    },
};
