/* eslint-disable react/no-unescaped-entities */
import styles from '../../../Agreements/style.module.scss';
import classnames from 'classnames';

export const PrivacyPolicy = () => {
  return (
    <div className={classnames(styles.wrapper, styles.wrapper_padding, 'with-scroll')}>
      <section className={styles.section}>
        <p>SCA PRIVACY POLICY</p>
        <p>
          #1.000-01.11/2023-SCA <br /> 01/11/2023
        </p>
        <p>United Kingdom</p>
        <p></p>
        <p>
          <strong>GENERAL:</strong>
        </p>
        <p>
          This Privacy Policy applies to the SCA Project for website located at
          www.sca-innovative.com and describes how SCA ALLIANCE LIMITED collects and uses your
          personal data in the context of its website.
        </p>
        <p>
          SCA ALLIANCE LIMITED is located at 277 Gray's Inn Road, London, United Kingdom, WC1X 8QF.
          SCA ALLIANCE LIMITED is responsible for the collection and use of your personal data
          described in this privacy policy. References to “SCA”, “SCA ALLIANCE”, “SCA ALLIANCE
          LIMITED”, “SCA Project”, “we” and “our” throughout this privacy notice collectively refer
          to the aforementioned legal entities.
        </p>
        <p>
          SCA Project is based on a trading and investment blockchain platform built on the
          innovative, fast and safe Binance Smart Chain protocol with an integrated core strategy
          for risk-free investments in mutual funds of three market segments with marginal trading
          yields in an extremely short period.
        </p>
        <p>
          We provide strong protection of your assets and their value; you will receive stable
          passive income from professional work on the stock market and investment activity in the
          real sectors of the world economy. Our company promotes network marketing through our
          Partners, uses modern cryptocurrency trends and stays beyond political conjuncture.
        </p>
        <p>
          <strong>INFORMATION COLLECTED:</strong>
        </p>
        <p>
          When you register on <a href="https://sca-innovative.com/">SCA Project</a> website we may
          collect and process the following categories of personal data:
        </p>
        <ul>
          <li>
            Name, address, telephone number, e-mail address, photo (incl. selfie) and other contact
            information. When you start using <a href="https://sca-innovative.com/">SCA Project </a>
            services, you need to provide us with all necessary information in accordance with
            KYC/AML rules what may include e-mail, telephone number, selfie photos, ID-card photos
            etc. We may use this information to respond to your questions and requests.
          </li>
          <li>
            Our communication with you. When you send us an e-mail or otherwise communicate with us
            online (for example, via social media), we register your communication with us.
          </li>
          <li>
            Information we collect when you use this website. When you use this website, certain
            information may be collected automatically, such as name of the domain and host from
            which you access the Internet, the browser software and operating system of your
            computer, the Internet protocol (IP) address of your computer and the Internet address
            of the site from which you linked directly to our site. We also collect information via
            cookies and similar technologies when you visit this website. For more information,
            please read our Cookie Policy.
          </li>
          <li>
            Information about order processing. When you are a customer of us, we also collect data
            for the purpose of order processing, pricing, and other information related to your
            order history.
          </li>
          <li>
            Information in relation to social media. We are active on various social media platforms
            such as LinkedIn, Facebook and Twitter
          </li>
          <li>
            We receive, or we can access data about you. We only use this information to communicate
            with you and for statistical research. We encourage you to review the privacy policy of
            the social media platform that you used to use to get more information about the
            processing of your personal data.
          </li>
        </ul>
        <p>
          For specific services or software, we may collect other types of information and use such
          information for different purposes than described in this privacy policy. We inform you
          about this when you register for the specific service or download the specific software.
        </p>
        <p>
          <strong>INFORMATION COLLECTIONING:</strong>
        </p>
        <p>We collect the mentioned above categories of personal data in the following ways:</p>
        <ul>
          <li>
            We collect data you provide to us, including information you provide for KYC
            verification purposes.
          </li>
          <li>Companies and Partners we co-operate with also may share your data with us.</li>
          <li>
            Depending on your social network settings, we may receive information from your social
            network provider.
          </li>
          <li>
            When you use our website, we collect information via cookies and similar technologies.
          </li>
        </ul>
        <p>
          <strong>COLLECTED INFORMATION USAGE:</strong>
        </p>
        <p>The main purposes for which we use your personal information are:</p>
        <ul>
          <li>
            To communicate with you. If you send us an e-mail or communicate with us online or via
            social media, we process your data to respond to you.
          </li>
          <li>
            For statistical research. We use automatic tools (such as cookies) to perform
            statistical research into general trends regarding the use of this website and the
            behavior of the website’s visitors to improve this website.
          </li>
          <li>
            For record keeping and to comply with statutory obligations. We collect, store and use
            your data for internal business purposes, such as record keeping and to comply with our
            legal and fiscal obligations.
          </li>
          <li>
            To process your orders as KYC verified person. You need to provide us with all necessary
            personal data in order to complete KYC verification and start investing in Mutual Funds
            as verified USCA Client.
          </li>
        </ul>
        <p>
          <strong>LEGAL BASIS:</strong>
        </p>
        <p>
          We collect, use and store your personal data to comply with the legal obligations we are
          subject to, if necessary for our legitimate interests or the interests of a third party,
          for the execution of an agreement, or on the basis of your consent.
        </p>
        <p>
          If you refuse to provide personal data that we require for the execution of an agreement
          or compliance with a legal obligation, we may not be able to provide all or parts of the
          products or services you have requested from us. For example, when you lost the order
          details of an order you placed in the past and refuse to provide information related to
          this order, we may not be able to provide you with a copy of the agreement because we are
          not able to find the order in our systems.
        </p>
        <p>
          When we process your personal data for our legitimate interests or the interests of a
          third party, we have balanced these interests against your legitimate interests. Where
          necessary we have taken appropriate measures to limit implications and prevent unwarranted
          harm to you. Our legitimate interests may, for example, include security and safety
          purposes, to improve this website, or to provide better products and offers to you. For
          more information on these interests, please see the purposes, for which we process your
          personal data above. More information on the balancing tests we perform is available upon
          request. Where we process your personal data for our legitimate interests or the
          third-party interests, you have the right to object at any time on grounds relating to
          your particular situation.
        </p>
        <p>
          Where we process your personal data for a purpose other than that for which we collected
          it initially (and we rely on a legal basis other than consent or complying with legal
          obligations for this new purpose), we will ascertain whether processing for this new
          purpose is compatible with the purpose for which the personal data were initially
          collected.
        </p>
        <p>
          <strong>LEGAL BASIS:</strong>
        </p>
        <p>
          SCA ALLIANCE LIMITED do not sell or share your personal data with anyone outside the SCA
          Project. However, we do disclose or share data with the following categories of recipients
          for the following purposes.
        </p>
        <ul>
          <li>
            For the support services we involve. We use third parties for support services to this
            website, social network providers, marketing agencies, credit and charge card companies
            and anti-fraud screening service providers. All such third parties will be required to
            adequately safeguard your personal data and only process it by following our
            instructions.
          </li>
          <li>
            Statistical research. For statistical research we make use of third-party software such
            as Google Analytics, Roistat or social media platforms. These platforms also have access
            to the data they collect. For example, when you accept cookies for this website, we
            analyze cookie data for statistical research via Google Analytics.
          </li>
          <li>
            Partner companies. When you use one of our services, it is possible that we share data
            with one of our partner companies.
          </li>
          <li>
            Public authorities. If we are required to by law, we also collect and share your
            identifying information with public authorities or governmental organizations.
          </li>
        </ul>
        <p>
          <strong>SECURITY AND RETENTION:</strong>
        </p>
        <p>
          SCA ALLIANCE LIMITED ensures that your personal data are properly secured by appropriate
          technical and organizational measures, so that they are protected against unauthorized or
          unlawful use, alteration, unauthorized access or disclosure, accidental or wrongful
          destruction, and loss. Your personal data will be retained for as long as required for the
          purposes described in this privacy policy or in so far as such is necessary for compliance
          with statutory obligations and for solving any disputes. For the retention period of the
          cookies used on this website, please see our Cookie Policy.
        </p>
        <p>
          <strong>CLIENT RIGHTS:</strong>
        </p>
        <p>
          You may contact us to exercise any of the rights you are granted under applicable data
          protection laws, which includes (1) the right to access your data, (2) to rectify them,
          (3) to erase them, (4) to restrict the processing of your data, (5) the right to data
          portability and (6) the right to object to processing.
        </p>
        <ol>
          <li>
            Right to access
            <p>
              You may ask us whether or not we process any of your personal data and, if so, receive
              access to that data in the form of a copy. When complying with an access request, we
              will also provide you with additional information, such as the purposes of the
              processing, the categories of personal data concerned as well as any other information
              necessary for you to exercise the essence of this right.
            </p>
          </li>
          <li>
            Right to rectification
            <p>
              You have the right to have your data rectified in case of inaccuracy or
              incompleteness. Upon request, we will correct inaccurate personal data about you and,
              taking into account the purposes of the processing, complete incomplete personal data,
              which may include the provision of a supplementary statement.
            </p>
          </li>
          <li>
            Right to erasure
            <p>
              You also have the right to have your personal data erased, which means the deletion of
              your data by us and, where possible, by any other controller to whom your data has
              previously been made public by us. The certain cases include situations where your
              personal data are no longer necessary in relation to the initial purposes for which
              they were processed as well as situations where they were processed unlawfully. Due to
              the way we maintain certain services, it may take some time before backup copies are
              erased.
            </p>
          </li>
          <li>
            Right to restriction of processing
            <p>
              You have the right to obtain the restriction of the processing of your personal data,
              which means that we suspend the processing of your data for a certain period of time.
              Circumstances which may give rise to this right include situations where the accuracy
              of your personal data was contested but some time is needed for us to verify their
              (in)accuracy. This right does not prevent us from continue storing your personal data.
              We will inform you before the restriction is lifted.
            </p>
          </li>
          <li>
            Right to object
            <p>
              You also have the right to object to the processing of your personal data, which means
              you may request us to no longer process your personal data. This only applies in case
              the ‘legitimate interests’ ground constitutes the legal basis for processing.
            </p>
          </li>
        </ol>
        <p>
          At any time and free of charge you can object to direct marketing purposes in case your
          personal data are processed for such purposes, which includes profiling purposes to the
          extent that it is related to such direct marketing. In case you exercise this right, we
          will no longer process your personal data for such purposes.
        </p>
        <p>
          You may withdraw your consent at any time by following the specific instructions in
          relation to the processing for which you provided your consent, by adjusting your settings
          (if available) or by contacting us.
        </p>
        <p>
          For more information on how you can withdraw your consent for cookies and similar
          technologies we use when you visit our websites, please check our Cookie Policy.
        </p>
        <p>
          Under conditions, we are entitled to deny or restrict your rights as described above. In
          any case, we will carefully assess whether such an exemption applies and inform you
          accordingly.
        </p>
        <p>
          We may, for example, deny your request for access when necessary to protect the rights and
          freedoms of other individuals or refuse to delete your personal data in case the
          processing of such data is necessary for compliance with legal obligations. The right to
          data portability, for example, does not apply in case the personal data was not provided
          by you or if we process the data not on the basis of your consent or for the performance
          of a contract.
        </p>
        <p>
          When you would like to exercise your rights, all you have to do is send your request to us
          using the contact details stated below.
        </p>
        <p>
          You can also contact us at if you have any questions, remarks or complaints in relation to
          this privacy policy.
        </p>
        <p>
          <strong>CHANGES TO PRIVACY POLICY:</strong>
        </p>
        <p>
          This privacy policy was most recently amended on 01 November 2023 and replaces earlier
          versions. We may update and/or change the terms of our privacy policy, and we will post
          any substantive changes on our website and provide to you by e-mail prior to these changes
          taking effect, so that our users and/or visitors are aware of the type of information we
          collect, how it will be used, and under what circumstances, if any, we may disclose such
          information.
        </p>
        <p>
          <strong>CONTACT INFORMATION:</strong>
        </p>
        <p>
          If you have any questions or concerns regarding the Privacy Policy related to our website,
          please feel free to contact us at:
        </p>
        <p>E-mail: direct@sca-innovative.com</p>
        <p>
          Mailing address: SCA ALLIANCE LIMITED, 277 Gray's Inn Road, London, United Kingdom, WC1X
          8QF.
        </p>
      </section>
    </div>
  );
};
