import {
  isMobile, isDesktop, isChrome, isFirefox, isEdge, isOpera, isIOS,
} from 'react-device-detect';

export const isDesktopBrowser = () => isDesktop;
export const isMobileBrowser = () => isMobile;
export const isIOSBrowser = () => isIOS;
export const isMMDesktopSupported = isDesktop && (isFirefox || isChrome || isEdge || isOpera);

export const isMMSupported = () => {
  if (isDesktop) {
    return isMMDesktopSupported;
  }

  if (isMobile) return true;
};
