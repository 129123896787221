import { Box, Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';
import { useTranslation } from 'next-i18next';

export const PackageNotPurchasedContent: React.FC<{
  handleClose: (isShow: boolean) => void;
}> = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography>{t('package_not_purchased.content_1')}</Typography>
      <Box sx={{ display: 'flex' }}>
        {t('package_not_purchased.please')}{' '}
        <Typography sx={{ margin: '0px 6px' }} onClick={() => handleClose(!open)}>
          <Link href="/packages" passHref>
            {t('package_not_purchased.purchase_any_package')}{' '}
          </Link>
        </Typography>
        {t('package_not_purchased.first')}
      </Box>
    </Box>
  );
};
