import { hasOnlyMessage, isUnclearMMError } from '@app/helpers';
import parseError from './parseError';
import { DefaultFetchDataErrorMsg } from '@app/components/shared/DefaultFetchDataErrorMsg';
import getErrorType from './getErrorType';
import { ETxStatus, ICatchErrorCallback } from '@app/types';

const catchErrorCallback = ({
  error,
  fnName,
  customErrorCallback,
  txStatusCallback,
  snackbarCallback,
  customDefaultMsg,
  parseServerError,
  throwErrors,
}: ICatchErrorCallback) => {
  const { handledError, errorType } = getErrorType({ error });

  /**
   * Данные варианты находятся в разработке.
   * Если будет найдено стабильное решение, перенесем в getErrorType
   * */
  const nestedMMError = isUnclearMMError(error) ? error.error : undefined;
  const parsedStringifiedErrorMsg = hasOnlyMessage(error) ? JSON.parse(JSON.stringify(error.message)) : undefined;
  let parsedErrorMsg;
  try {
    parsedErrorMsg = hasOnlyMessage(error) ? JSON.parse(error.message) : undefined;
  } catch (e) {
    console.error('Could not get parsedErrorMsg');
  }
  const msg = parseError({ error: handledError, parseServerError });

  console.error(`Error in ${fnName}`, {
    error,
    handledError,
    experimental: {
      nestedMMError,
      parsedStringifiedErrorMsg,
      parsedErrorMsg,
    },
    msg,
  });

  customErrorCallback && customErrorCallback(handledError);

  txStatusCallback && txStatusCallback({
    status: ETxStatus.ERROR,
    description: msg || customDefaultMsg,
    errorType,
  });

  if (snackbarCallback) {
    const snackBarMsg = msg || <DefaultFetchDataErrorMsg type={errorType} />;
    snackbarCallback(snackBarMsg);

    setTimeout(() => { snackbarCallback(''); }, 5000);
  }

  if (throwErrors) throw handledError;
};

export default catchErrorCallback;
