import { setupInterceptorsTo } from './interceptors';
import Axios from 'axios';

const config = {
  baseURL: process.env.REACT_APP_HTTP_API_URL,
  withCredentials: true,
};

export const axios = Axios.create(config);

setupInterceptorsTo(axios);
