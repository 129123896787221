import { Address, parseAbiItem } from 'viem';
import { Config, useAccount, usePublicClient } from 'wagmi';
import { APP_CONFIG } from '../../pages/_app';
import { useEffect } from 'react';

interface IUseWatchPreviousEvents<T> {
  fromBlock: Address;
  address: Address;
  args?: { [key: string]: Address | null | undefined };
  onLogs?: (logs: T | never[]) => void;
  onError?: (error: unknown) => void;
  event:
    | 'BuyTokens'
    | 'BuyPackage'
    | 'UpgradePackage'
    | 'ClaimPackage'
    | 'UpgradeClaimedPackage'
    | 'ClaimBonusPackage'
    | 'UpgradeClaimedBonusPackage'
    | 'Dividended'
    | 'Unstaked'
    | 'Staked'
    | 'SellTokens'
    | 'BonusClaimed'
    | 'ConvertTokens';
}

const events = {
  BuyTokens: parseAbiItem(
    'event BuyTokens(address indexed user, uint256 amount, address coin, uint256 coinValue, uint256 packageId)',
  ),
  BuyPackage: parseAbiItem(
    'event BuyPackage(address referralAddress, address indexed user, uint256 packageId, uint256 prevPackageId, uint256 nonce, address coin, uint256 coinValue, uint256 scaValue)',
  ),
  UpgradePackage: parseAbiItem(
    'event UpgradePackage(address referralAddress, address indexed user, uint256 packageId, uint256 prevPackageId, address coin, uint256 coinValue, uint256 scaValue)',
  ),
  ClaimPackage: parseAbiItem(
    'event ClaimPackage(address referralAddress, address indexed user, uint256 packageId, uint256 prevPackageId, uint256 nonce, uint256 scaValue)',
  ),
  UpgradeClaimedPackage: parseAbiItem(
    'event UpgradeClaimedPackage(address referralAddress, address indexed user, uint256 packageId, uint256 prevPackageId, uint256 nonce, uint256 scaValue)',
  ),
  ClaimBonusPackage: parseAbiItem(
    'event ClaimBonusPackage(address referralAddress, address indexed user, uint256 packageId, uint256 prevPackageId, uint256 nonce, uint256 scaValue)',
  ),
  UpgradeClaimedBonusPackage: parseAbiItem(
    'event UpgradeClaimedBonusPackage(address referralAddress, address indexed user, uint256 packageId, uint256 prevPackageId, uint256 nonce, uint256 scaValue)',
  ),
  Dividended: parseAbiItem(
    'event Dividended(uint256 indexed fundId, address indexed staker, uint256 amount, uint256 profit)',
  ),
  Unstaked: parseAbiItem(
    'event Unstaked(uint256 indexed fundId, address indexed staker, uint256 amount, uint256 profit)',
  ),
  Staked: parseAbiItem(
    'event Staked(uint256 indexed fundId, address indexed staker, uint256 amount, uint256 insuranceAmount, uint256 withdrawFreq)',
  ),
  SellTokens: parseAbiItem(
    'event SellTokens(address indexed user, uint256 amount, address coin, uint256 coinValue, uint256 gas)',
  ),
  BonusClaimed: parseAbiItem('event BonusClaimed(address indexed user, uint256 amount)'),
  ConvertTokens: parseAbiItem(
    'event ConvertTokens(address indexed user, string migrationOperationResult, uint256 user_package_id, bool is_bonus_package, bool is_banned_user, uint256 scaBalanceOf, uint256 scaBonusOf, uint256 scaCurrentBalance, uint256 scaWelcomeBonusBonusOf, uint256 bonusResult, uint256 welcomeBonusResult)',
  ),
};
export const useWatchPreviousEvents = <T>(options: IUseWatchPreviousEvents<T>) => {
  const config: Config = APP_CONFIG;
  const publicClient = usePublicClient({ config });
  const { address } = useAccount();
  const start = async () => {
    try {
      const fromBlock = !!publicClient
        ? Number(await publicClient?.getBlockNumber()) -
          (Number(process.env.GET_LATESTS_BLOCK_EVENT_HISTORY) || 500)
        : options?.fromBlock ?? 0;
      // @ts-ignore
      const filter: any = await publicClient?.createEventFilter({
        fromBlock: BigInt(fromBlock),
        address: options?.address! as Address,
        event: events[options?.event],
        args: options?.args,
      });

      if (filter) {
        const logs = await publicClient?.getFilterLogs({ filter });
        if (address) options?.onLogs?.(logs as T);
        else options?.onLogs?.([]);
      }
    } catch (e) {
      if (options?.onError) options?.onError?.(e);
      else console.log(e);
    }
  };

  useEffect(() => {
    start();
  }, [address]);
};
