import { useAuthtoken } from '@app/stores/auth/store'
import { useQuery } from '@tanstack/react-query'
import { axios } from '@app/services/httpClient/axios'
import { SUMSUB_ONBOARDING } from '@app/constants'
import { ISumSubAccessResponse } from '@app/types'

export const useSumSub = () => {
  const token = useAuthtoken()
  const kycVerification = useQuery({
    queryKey: ['onboarding'],
    queryFn: () =>
      axios
        .get<ISumSubAccessResponse>(SUMSUB_ONBOARDING)
        .then((data) => data.data)
        .catch((e) => console.log(e)),
    staleTime: Infinity,
    enabled: typeof token === 'string',
  })

  return { ...kycVerification, isLoading: kycVerification.isLoading }
}
