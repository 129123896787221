import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: '#FBBE68',
            color: '#5a5a5a',
          },
          lineHeight: 1.5,
          textTransform: 'none',
          fontSize: '0.9rem',
          fontFamily: 'Playfair, sans-serif',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: '#fff',
        },
        colorSecondary: {
          color: '#4DABF5',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'Playfair, sans-serif',
        },
        h2: {
          fontFamily: 'Playfair, sans-serif',
        },
        h3: {
          fontFamily: 'Playfair, sans-serif',
        },
        h4: {
          fontFamily: 'Playfair, sans-serif',
        },
        h5: {
          fontFamily: 'Playfair, sans-serif',
        },
        h6: {
          fontFamily: 'Playfair, sans-serif',
        },
        subtitle1: {
          fontWeight: 800,
          fontSize: '24px',
          lineHeight: '30px',
          color: '#FBBE68',
        },
        subtitle2: {
          fontSize: '18px',
          // lineHeight: '30px',
          color: '#fbbe68',
        },
        root: {
          fontFamily: 'Playfair, sans-serif',
          color: '#fff',
        },
      },
    },

    MuiSlider: {
      styleOverrides: {
        root: {
          marginTop: '50px',
        },
        track: {
          height: '8px',
          backgroundColor: '#FBBE68',
          cursor: 'default',
        },
        valueLabel: {
          color: 'red',
          width: '100px',
          height: '30px',
          background: '#D9D9D9',
          borderRadius: '15px',
          marginTop: '30px',
          fontWeight: '800',
          padding: '0px 15px',
          cursor: 'default',
          position: 'relative',
          top: -12,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          '& .MuiSvgIcon-root': {
            color: 'white',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'rgba(77, 171, 245, 0.06)',
          borderRadius: '15px',
          padding: '22px 28px',
          boxShadow: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#FEAF3C',
    },
    secondary: {
      main: '#4DABF5',
    },
    background: {
      default: '#162345',
      paper: '#111b35',
    },
    text: {
      primary: 'rgba(255,255,255,0.9)',
      secondary: '#FEAF3C',
      disabled: '#dbe3e8',
    },
  },
});
