import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useModal } from '@app/hooks'
import { AuthForm } from '@app/components/sideBar/AuthForm/AuthForm'
import { useConnectAuthForm } from '@app/hooks/useConnectAuthForm';

export const AuthFormModal = () => {
  const { setIsClosable } = useModal()

  useEffect(() => {
    setIsClosable(false)
  }, [setIsClosable])
  const connectAuthForm =useConnectAuthForm()

  return (
    <div className={styles.modal}>
      <AuthForm connectAuthForm={connectAuthForm}/>
    </div>
  )
}
