import { AUTH } from '@app/constants';
import { isAxiosError } from '@app/helpers';
import { authService } from '@app/services';
import { axios } from '@app/services/httpClient/axios';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { disconnect } from '@wagmi/core';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  of,
  pairwise,
  retry,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { signMessage } from 'wagmi/actions';
import { queryClient } from '../queryClient';
import { APP_CONFIG } from '../../../pages/_app';
import { Config } from 'wagmi';
import { storageService } from '@app/services/storage/storage';

type Signature = {
  message: string;
  signature: string;
};

export const NEED_INVITER_ADDRESS = Symbol();

const [loadingAuthSignal, setLoadingAuthSignal] = createSignal<boolean>();

const address$ = new Observable<string>((subscriber) => {
  try {
    const config = APP_CONFIG as Config;
    if (!config) return subscriber.next(undefined);

    subscriber.next(config.getClient()?.account?.address);
    return (APP_CONFIG as Config).subscribe(
      (state) => {
        const connection = state.connections.get(state.current ?? '');
        const userAddress: string | undefined = connection?.accounts[0];
        subscriber.next(userAddress);
        return userAddress;
      },
      (userAddress) => {
        subscriber.next(userAddress);
        return userAddress;
      },
    );
  } catch (e) {
    // console.warn('address$ error ', e);
  }
}).pipe(distinctUntilChanged());

// delete cached signature if address changes except initial load
address$.pipe(pairwise()).subscribe(([prev, now]) => {
  if (prev && now !== prev) {
    storageService.removeItem('signature');
  }
});

async function login(
  address: string | undefined,
  signature: Signature,
  inviterAddress: string | undefined,
) {
  try {
    const res = await axios.put(AUTH, {
      address,
      ...signature,
      ...(inviterAddress ? { inviterAddress, isClientAgreementAccepted: true } : {}),
    });

    if (res.data?.NEED_INVITER_ADDRESS) {
      return NEED_INVITER_ADDRESS;
    }

    return res.data?.accessToken as string;
  } catch (err) {
    setLoadingAuthSignal(false);
    if (isAxiosError(err) && err.response?.status === 400) {
      return NEED_INVITER_ADDRESS;
    }
  }
}

async function signWelcomeToken() {
  const {
    data: { welcomeToken: message },
  } = await authService.getWelcomeToken();

  let signature = await signMessage(APP_CONFIG as Config, { message });

  if (!signature.startsWith('0x')) {
    signature = `0x${signature}`;
  }
  return {
    message,
    signature,
  };
}

const [referrerAddress$, setReferrerAddress] = createSignal<string>();

const signature$ = address$.pipe(
  filter((address): address is string => {
    return !!address;
  }),
  switchMap(async () => {
    const signature = storageService.getItem<Signature>('signature');
    if (signature) {
      return signature;
    }
    return await signWelcomeToken();
  }),

  catchError((err) => {
    if (err?.name === 'UserRejectedRequestError') {
      disconnect(APP_CONFIG as Config)
        .then((r) => console.log('disconnect cause UserRejectedRequestError', r))
        .catch((err) => console.log('disconnect catch cause UserRejectedRequestError', err));
    }
    return of(undefined);
  }),
  tap((value: any) => {
    if (value) storageService.setItem('signature', value);
    else storageService.removeItem('signature');
  }),
);

export const authtoken$ = address$.pipe(
  switchMap((address) => {
    if (!address) {
      return of(undefined);
    }
    setLoadingAuthSignal(true);
    const token = storageService.getItem<string>('token');
    if (token) {
      return of(token);
    }

    return combineLatest([
      signature$.pipe(
        filter((s): s is Signature => {
          return !!s;
        }),
      ),
      referrerAddress$.pipe(startWith(undefined)),
    ]).pipe(
      switchMap(async ([signature, inviterAddress]) => {
        return login(address, signature, inviterAddress);
      }),
      retry(),
    );
  }),
  tap((token) => {
    if (token === undefined) {
      storageService.removeItem('token');
      delete axios.defaults.headers.common.Authorization;
      storageService.removeItem('signature');
    } else if (token === NEED_INVITER_ADDRESS) {
    } else {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      storageService.setItem('token', token);
      storageService.removeItem('signature');
    }

    setLoadingAuthSignal(false);
    queryClient.resetQueries({
      predicate: () => true,
    });
  }),
);

const [useAuthtoken] = bind(authtoken$, undefined);

export { setReferrerAddress, useAuthtoken, loadingAuthSignal };
