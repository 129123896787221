import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider as AtomProvider } from 'jotai';
import { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import React, { createContext, Suspense, useContext, useEffect, useState } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { WagmiProvider, createConfig, http } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { bscTestnet, bsc, Chain } from 'wagmi/chains';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FundsAnalysisContextProvider } from '@app/stores/funds-analysis';
import { Preloader } from '@app/components/shared/Preloader';
import { AuthContext, ModalContextProvider } from '@app/stores';
import RouterGuard from '../src/HOC/RouterGuard';
import AppModals from '../src/components/Modal/app';
import '../src/styles/globals.scss';
import { theme } from '@app/theme';
import atomStore from '@app/stores/atom';
import { walletConnect } from '@wagmi/connectors';
import Web3AuthConnectorInstance from '@app/HOC/Web3AuthConnectorInstance';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { appWithTranslation } from 'next-i18next';
import { useClearConsole } from '@app/hooks/useClearConsole';

const queryClient = new QueryClient();

export const Web3AuthContext = createContext<{
  web3Auth: Web3AuthNoModal | null;
  setWeb3Auth: React.Dispatch<React.SetStateAction<Web3AuthNoModal | null>>;
} | null>(null);
export const useWeb3Auth = () => useContext(Web3AuthContext);

export const chain: Chain = process.env.DEFAULT_CHAIN === 'bsc' ? bsc : bscTestnet;

const web3AuthConnectorInstance = Web3AuthConnectorInstance([chain]);

export const APP_CONFIG = createConfig({
  chains: [chain],
  transports: {
    [chain.id]: http(process.env.EXECUTION_CLIENT_HTTPS_ENDPOINT!),
  },
  connectors: [
    walletConnect({
      projectId: process.env.WALLET_CONNECT_PROJECT_ID!,
      showQrModal: true,
    }),
    web3AuthConnectorInstance.connector,
  ],
});

const NoSSR: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => setMounted(true), []);

  if (!mounted) return null;
  return <>{children}</>;
};
const appId = process.env.INTERCOM_APP_ID || '';

const routeTitles = {
  '/settings': 'Personal Data',
  '/staking': 'Staking',
  '/funds-analysis': 'Staking',
};

function MyApp({ Component, pageProps }: AppProps) {
  const { route } = useRouter();
  const [web3Auth, setWeb3Auth] = useState<Web3AuthNoModal | null>(null);
  useClearConsole();

  useEffect(() => {
    setWeb3Auth(web3AuthConnectorInstance.web3AuthInstance);
  }, [setWeb3Auth, web3AuthConnectorInstance]);

  const getTitle = () => {
    if (route.trim() === '/') return 'SCA';
    const rawTitle = route.replace(/\//g, ' ').trim();
    const pageTitle =
      Object.entries(routeTitles).find(([key, value]) =>
        route.startsWith(key) ? value : undefined,
      )?.[1] || rawTitle.charAt(0).toUpperCase() + rawTitle.slice(1);
    return `${pageTitle} | USCA`;
  };

  return (
    <NoSSR>
      <Head>
        <title>{getTitle()}</title>
      </Head>
      <StyledEngineProvider injectFirst>
        <AtomProvider store={atomStore}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Web3AuthContext.Provider value={{ web3Auth, setWeb3Auth }}>
                <WagmiProvider config={APP_CONFIG}>
                  <QueryClientProvider client={queryClient}>
                    <Suspense fallback={<Preloader />}>
                      <FundsAnalysisContextProvider>
                        <ModalContextProvider>
                          <AuthContext>
                            <IntercomProvider appId={appId}>
                              <RouterGuard>
                                <Component {...pageProps} />
                                <AppModals />

                                <ToastContainer
                                  position="top-right"
                                  autoClose={5000}
                                  hideProgressBar
                                  newestOnTop={false}
                                  closeOnClick={false}
                                  rtl={false}
                                  pauseOnFocusLoss
                                  draggable
                                  pauseOnHover
                                  theme="colored"
                                />
                              </RouterGuard>
                            </IntercomProvider>
                          </AuthContext>
                        </ModalContextProvider>
                      </FundsAnalysisContextProvider>
                    </Suspense>
                  </QueryClientProvider>
                </WagmiProvider>
              </Web3AuthContext.Provider>
            </SnackbarProvider>
          </ThemeProvider>
        </AtomProvider>
      </StyledEngineProvider>
    </NoSSR>
  );
}

export default appWithTranslation(MyApp);
