import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

interface ICustomErrorData {
  message: string;
}

interface ICustomAxiosResponse extends AxiosResponse {
  data: ICustomErrorData;
}

interface ICustomAxiosError extends AxiosError {
  response?: ICustomAxiosResponse;
}

const onRequest = (config: AxiosRequestConfig): InternalAxiosRequestConfig => {
  return <InternalAxiosRequestConfig<any>>config;
};

const onRequestError = (error: ICustomAxiosError): Promise<AxiosError> => {
  enhanceAxiosError(error);

  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: ICustomAxiosError): Promise<AxiosError> => {
  enhanceAxiosError(error);

  return Promise.reject(error);
};

const enhanceAxiosError = (error: ICustomAxiosError): void => {
  if (error?.response?.data?.message) {
    error.message = error.response.data.message;
  }
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
