import { Connector } from 'wagmi';
import { type Address } from 'viem';
import { WalletNamesEnum } from '@app/utils/wallets';
import { InstallWalletModal } from '@app/components/Modal/auth/InstallWalletModal';
import { isMobileBrowser } from '@app/helpers';
import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';
import { CustomDialog } from '@app/components/shared/Dialog';
import wallet from '../../../assets/icons/wallet.svg';
import styles from './style.module.scss';
import { storageService } from '@app/services/storage/storage';
import { ICustomProvider } from '@app/types/help';
import { useTranslation } from 'next-i18next';
import { Preloader } from '@app/components/shared/Preloader';
import { IUseConnectAuthFormReturn } from '@app/types/auth-form';
import { toast } from 'react-toastify';

export const tryAddToken = async (acc: Address, connector: Connector) => {
  const tokenAdded = storageService.getItem<{ [key: string]: `0x${string}` }>('tokenAdded');
  const setTokenAdded = () => {
    storageService.setItem('tokenAdded', {
      ...tokenAdded,
      [acc]: true,
    });
  };

  if (tokenAdded && tokenAdded[acc]) {
    return 'Token USCA has been already shown on Your wallet balance!';
  }

  if (connector) {
    try {
      const provider = (await connector?.getProvider()) as ICustomProvider;
      await provider?.request?.({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: process.env.USCA_ADDRESS! as Address,
            decimals: 18,
            image: `https://${window.location.host}/SCAIcon.png`,
          },
        },
      });

      setTokenAdded();
      return 'Token USCA has been added on Your wallet balance!';
    } catch (error: any) {
      toast.error(error?.message);
    }
  }
};

export const AuthForm = ({ connectAuthForm }: { connectAuthForm: IUseConnectAuthFormReturn }) => {
  const {
    connectors,
    isPending,
    isLoading,
    isWeb3AuthConnected,
    walletNameToInstall,
    showInstallWalletModal,
    connectedWalletNames,
    handleWalletConnect,
    setShowInstallWalletModal,
  } = connectAuthForm;
  const { t } = useTranslation();

  const getConnectBtnName = (connector: Connector) => {
    if (connectedWalletNames.includes(connector.name as WalletNamesEnum)) {
      return `${t('auth_form.connect_to')} ${connector.name}`;
    } else if (isMobileBrowser()) {
      return t('auth_form.authorization_center');
    } else if (connector.id === 'com.trustwallet.app') {
      return t('auth_form.trust_wallet');
    } else if (connector.id === 'web3auth') {
      return t('auth_form.authorization_center');
    } else {
      return connector.name;
    }
  };

  return (
    <div className={classNames('block', 'block_variant_filled', styles.container)}>
      <div className={styles.container__header}>
        <div className={styles.icon}>
          <Image src={wallet} alt="wallet" fill objectFit="cover" />
        </div>
        <div className={styles.container__text}>
          <p className="text-bold-14 white">{t('auth_form.account')}</p>
          <p className="text-light-10 light">{t('auth_form.login_or_sign')}</p>
        </div>
      </div>
      <Grid container direction="column" gap={0} mb={1}>
        {(isPending || !isWeb3AuthConnected || isLoading) && (
          <Preloader
            style={{ height: '400px', top: '34px', padding: '20px' }}
            text={t('auth_form.being_processed')}
          />
        )}
        {connectors?.map((connector: any, index: any) => (
          <Button
            style={{
              background: connector.id === 'web3auth' ? '#FFE8A7' : '',
              marginBottom: connector.id === 'web3auth' ? '25px' : '',
              order: connector.id === 'web3auth' ? -1 : index,
            }}
            key={connector.id + connector.name}
            variant="contained"
            className={classNames(
              styles.btn,
              isPending || !isWeb3AuthConnected || isLoading? styles.btnDisabled : '',
            )}
            disabled={isPending || !isWeb3AuthConnected|| isLoading}
            onClick={() => handleWalletConnect(connector)}
          >
            {getConnectBtnName(connector)}
          </Button>
        ))}
      </Grid>
      {/*TODO make dialogs inside <AppModals />*/}
      <CustomDialog
        title=""
        open={showInstallWalletModal}
        handleClose={() => setShowInstallWalletModal(false)}
      >
        <InstallWalletModal walletName={walletNameToInstall} />
      </CustomDialog>
    </div>
  );
};
