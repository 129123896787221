import React from 'react';
import { Box, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const Preloader: React.FC<{ style?: React.CSSProperties; text?: string }> = ({
  style = {},
  text,
}) => {
  return (
    <LoadingLayout style={style}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress size={70} />
        {text ? (
          <Box sx={{ position: 'relative', zIndex: 1000, color: '#FEAF3C', marginTop: '40px' }}>
            {text}
          </Box>
        ) : (
          ''
        )}
      </Box>
    </LoadingLayout>
  );
};

const LoadingLayout = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  height: 100vh;
  background-color: #012e3b;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 11; //mobile infoBar has 10
  opacity: 0.9;
`;
