import { FC, ReactNode } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'next-i18next';

export interface SupportCenterErrorMessageProps {
  children?: ReactNode;
  linkClass?: string;
  retryMsg?: boolean;
}

export const SupportCenterErrorMessage: FC<SupportCenterErrorMessageProps> = (props) => {
  const { linkClass = '', children, retryMsg = false } = props;
  const { t } = useTranslation();

  return (
    <div>
      <span>
        {children}
        {retryMsg && t('support_center_error_msg.try_again_later')}
        <span className={linkClass || styles.helpLink}>
          {t('support_center_error_msg.support_center')}
        </span>
      </span>
    </div>
  );
};
