import { useEffect, useRef } from 'react';
import { useModal } from '@app/hooks/useModal';
import { ECurrentModal } from '@app/types';
import Image from 'next/image';
import classNames from 'classnames';
import closeWhiteIcon from '../../assets/icons/closeWhiteIcon.svg';
import styles from './style.module.scss';

type Props = {
  children: React.ReactNode;
};

export const Modal = ({ children }: Props) => {
  const { isOpen, close, isClosable, customContentClass, currentModal } = useModal();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleKeyDown(event: { keyCode: number }) {
      if (
        event.keyCode === 27 &&
        !(
          currentModal === ECurrentModal.REFERRAL_LINK_LOGIN ||
          currentModal === ECurrentModal.AUTH__NEED_INVITER_ADDRESS
        )
      ) {
        close();
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isClosable, currentModal]);

  useEffect(() => {
    if (isOpen) {
      const focusableElements = modalRef.current?.querySelectorAll(
        'a[href], button, textarea, input, select',
      ) as NodeListOf<HTMLElement> | null;
      if (focusableElements?.length) {
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        function trapFocus(event: KeyboardEvent) {
          if (event.key === 'Tab') {
            if (event.shiftKey) {
              // Shift + Tab
              if (document.activeElement === firstElement) {
                lastElement.focus();
                event.preventDefault();
              }
            } else {
              // Tab
              if (document.activeElement === lastElement) {
                firstElement.focus();
                event.preventDefault();
              }
            }
          }
        }

        firstElement.focus();
        modalRef.current?.addEventListener('keydown', trapFocus);
        return () => {
          modalRef.current?.removeEventListener('keydown', trapFocus);
        };
      }
    }
  }, [isOpen]);

  return (
    <div
      ref={modalRef}
      className={classNames(styles.modal, {
        [styles['closed']]: !isOpen,
      })}
      aria-hidden={!isOpen}
    >
      <div className={classNames(styles.content, customContentClass)}>
        {isClosable && (
          <div className={styles.closeBtn} onClick={close}>
            <Image src={closeWhiteIcon} alt="" fill objectFit="cover" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
