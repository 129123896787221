import styles from '../../../Agreements/style.module.scss';
import classnames from 'classnames';
import Link from 'next/link';

export const ServiceAgreement = () => {
  return (
    <div className={classnames(styles.wrapper, styles.wrapper_padding, 'with-scroll')}>
      <section className={styles.section}>
        <p>SCA SERVICES AGREEMENT</p>
        <p>
          #1.000-16.10/2023-SCA
          <br /> 16/10/2023
        </p>
        <p>United Kingdom</p>
        <p> </p>
        <p> </p>
        <p>
          <strong>GENERAL</strong>
        </p>
        <p> </p>
        <ol>
          <li>
            These terms apply to the Package purchasing and the staking protocols (including but not
            limited to the user interface and the underlying protocols) services (including all
            associated services, features, technologies and functionalities) available on or via
            the&nbsp;
            <Link href="https://sca-innovative.com/">https://sca-innovative.com/</Link> (the
            “Website” and “SCA App”), which allow you to purchase Supported Digital Tokens via
            buying Packages, upgrading Packages and via additional token purchasing and to stake
            your Supported Digital Tokens on various distributed ledger platforms, (such purchasing
            and staking protocols and services together with the Website and App collectively,
            “Purchasing and Staking Protocols &amp; Services”). The Purchasing and Staking Protocols
            &amp; Services are provided by SCA ALLIANCE LIMITED. and its affiliate entities
            (referred to as “SCA”, “we”, “us” and “our” in these Terms). In these Terms, “you” and
            “your” refers to any person and/or entity accessing or using the Purchasing and Staking
            Protocols &amp; Services.
            <p>For purposes of these Terms:</p>
            <p>
              “Affiliates” of an entity means (i) companies in which such entity has an interest in;
              (ii) companies which such entity is controlled by; or (iii) companies which such
              entity shares common control with, where “control” includes direct, indirect, or
              effective control; or (iv) the owners, directors, officers employees, advisors, agents
              of such entity and companies, and shall include SCA ALLIANCE LIMITED and its affiliate
              entities; and “Supported Digital Tokens” means digital assets (including digital
              tokens) supported by the Purchasing and Staking Protocols &amp; Services from time to
              time.
            </p>
          </li>
          <li>
            By accessing and/or using the Purchasing and Staking Protocols &amp; Services, you
            accept these Terms and agree to be bound by them. If you do not accept these Terms,
            please refrain from any further use of the Purchasing and Staking Protocols &amp;
            Services.
          </li>

          <li>
            These Terms will also apply to any updates or upgrades provided by us that replace
            and/or supplement the Purchasing and Staking Protocols &amp; Services, unless such
            updates or upgrades are accompanied by separate terms in which case those separate terms
            shall apply.
          </li>
          <li>
            We may amend these Terms from time to time. Amendments shall take effect from the date
            the amended terms are published at the Website and/or App. If you use or access the
            Purchasing and Staking Protocols &amp; Services after such amendments, you are deemed to
            have agreed to the amended terms and to be bound by them. It is your responsibility to
            check for the latest version of the terms prior to use of the Purchasing and Staking
            Protocols &amp; Services. Please, check the terms periodically for changes. If you do
            not agree to the revised Terms, then you should not continue to access or use Staking
            Protocol &amp; Services.
          </li>
          <li>
            We may from time to time publish additional guidelines, rules, and conditions applicable
            to your use of the Purchasing and Staking Protocols &amp; Services which are deemed
            incorporated into and form part of these Terms. By using the Purchasing and Staking
            Protocols &amp; Services, you shall be deemed to have accepted and to agree to comply
            with these additional guidelines, rules and conditions.
          </li>
          <li>
            We have no obligation to provide, or continue to provide the Purchasing and Staking
            Protocols &amp; Services, or any part thereof, now or in the future. We reserve the
            right, at any time, temporarily or permanently, in whole or in part, without prior
            notification and without incurring any liability to you, to: modify, suspend or
            discontinue the Purchasing and Staking Protocols &amp; Services; restrict or modify
            access to the Purchasing and Staking Protocols &amp; Services; and modify and/or waive
            any charges in connection with the Purchasing and Staking Protocols &amp; Services. You
            are deemed to agree to such modifications if you continue the use of the Purchasing and
            Staking Protocols &amp; Services.
          </li>
          <li>
            There may be fees (“Purchasing and Staking Protocols &amp; Services Fees”) payable to
            use the Purchasing and Staking Protocols &amp; Services as determined by us in our sole
            discretion or as determined by the relevant governance arrangement associated with the
            Purchasing and Staking Protocols &amp; Services. Such transaction fees will be payable
            in either the source digital asset or the destination digital asset and will be
            published at the Website or App and/or such other channels as determined by us. Any such
            charges and/or fees shall be borne by you.
          </li>
          <li>
            In addition to Purchasing and Staking Protocols &amp; Services Fees, there may be
            administrative and/or third party charges and/or other transaction fees (whether to
            miners, validators, nodes or otherwise) may be incurred arising out of or in connection
            with your use of the Purchasing and Staking Protocols &amp; Services, including but not
            limited to the transfer of Supported Digital Tokens or execution of smart contracts in
            relation to the Purchasing and Staking Protocols &amp; Services. Any such charges and/or
            fees shall be borne by you.
          </li>
          <li>
            These Terms only govern our provision of, and your use of, the Purchasing and Staking
            Protocols &amp; Services. Certain transactions and activities, including the provision
            of services or products may be performed or facilitated through the Purchasing and
            Staking Protocols &amp; Services may be subject to separate terms and conditions that
            you have to accept in order to enter into these transactions or perform these
            activities.
          </li>
          <li>
            The client irrevocably agrees that the use of all services is at his own risk. By
            agreeing to this Offer, the Client confirms that he is a legally capable adult using his
            own funds to work with the SCA Project, and who has not been misled in terms of the SCA
            Project, Companies associated with the Project and activities carried out on the
            Project. By agreeing to this Offer, the Client confirms that he has a clear
            understanding of the risks of working with the cryptocurrency market, the risks of
            investing and other risks associated with online earnings.
          </li>
        </ol>
        <h1>
          <strong>DISCLAIMERS</strong>
        </h1>
        <p>
          By using the Purchasing and Staking Protocols &amp; Services, you acknowledge and agree to
          the following:
        </p>
        <ol>
          <li>
            The Purchasing and Staking Protocols &amp; Services and any underlying smart contracts
            or protocols that are provided or used in connection with the provision of the
            Purchasing and Staking Protocols &amp; Services are provided or used “as is” and “as
            available” with no representations or warranties whatsoever. To the maximum extent
            permitted by law, we disclaim all express representations and warranties and implied
            representations and warranties, including implied representations or warranties of
            accuracy, merchantability, fitness for a particular purpose and non-infringement. We are
            not responsible or liable for any error, delay or interruption in or lack of access to
            the Purchasing and Staking Protocols &amp; Services or for any loss of Supported Digital
            Tokens occasioned by or attributable to the Purchasing and Staking Protocols &amp;
            Services.
          </li>
          <li>
            To the maximum extent permitted by law, we make no guarantee, representation or warranty
            and expressly disclaim liability (whether to you or any person): (a) regarding the
            timeliness, reliability, accuracy, completeness, accessibility, quality, operation or
            usefulness of any portion of the Purchasing and Staking Protocols &amp; Services; (b)
            regarding the availability or appropriateness of the Purchasing and Staking Protocols
            &amp; Services; (c) that the Purchasing and Staking Protocols &amp; Services will be
            error-free, continuously available or uninterrupted in operation, or free of viruses or
            other harmful components; (d) that the Purchasing and Staking Protocols &amp; Services
            will operate or function properly on your devices or operating systems or with any third
            party software; (e) that the Purchasing and Staking Protocols &amp; Services will not
            cause any damage to your devices or operating systems; (f) for the digital tokens
            including Supported Digital Tokens transferred or deposited in connection with the
            Purchasing and Staking Protocols &amp; Services being obtained through any acts in
            connection with money laundering, terrorism financing or any other acts in breach or
            contravention of any applicable law, regulation or rule; (g) for use of the Purchasing
            and Staking Protocols &amp; Services for any purpose in connection with money
            laundering, terrorism financing or any other acts in breach or contravention of any
            applicable law, regulation or rule; (h) for failure, malfunction or breakdown of, or
            disruption to, the operation of our platforms and operations (including but not limited
            to blockchain and blockchain-based software systems) or any technology (including but
            not limited to blockchain and smart contract technologies) on which the Purchasing and
            Staking Protocols &amp; Services relies on, for any reason whatsoever, including without
            limitation, due to occurrences of hacks, mining attacks (including but not limited to
            double-spend attacks, majority mining power attacks and “selfish-mining” attacks),
            cyber-attacks, distributed denials of service, errors, vulnerabilities, defects, flaws
            in programming or source code or otherwise, regardless of when such failure,
            malfunction, breakdown, or disruption occurs; (i) loss of possession of the credentials
            for accessing any digital tokens including Supported Digital Tokens in connection with
            the Purchasing and Staking Protocols &amp; Services in any manner and to any extent; (j)
            any prohibition, restriction or regulation by any government or regulatory authority in
            any jurisdiction of the operation, functionality, usage, storage, transmission
            mechanisms, transferability or tradability or other material characteristics of
            cryptocurrencies.
          </li>
          <li>
            Your use of the Purchasing and Staking Protocols & Services is voluntary and at your
            sole risk. You waive any and all claims against SCA ALLIANCE LIMITED, 247and Co. K/S,
            SCA K/S or IMPERIAL INSURANCE BROKER LLP in case of loss of funds invested by you in the
            SCA project or losses received as a result of transactions related to USCA tokens, or
            losses received in any way related to the activities of the SCA Project and related
            companies with him. You are aware of the risks associated with investment activities,
            cryptocurrencies and any activity related to online earnings.
          </li>
          <li>
            You agree that SCA ALLIANCE LIMITED will withhold 15% of the amount you spent on the
            purchase or upgrading of the Package for the needs of insurance and marketing coverage
            of the provision of Purchasing and Staking Protocols &amp; Services. You agree that the
            purchase of additional tokens within the purchased or upgraded Package is accompanied by
            the payment of an additional commission in accordance with the Package, and we do not
            withhold 15% from such a purchase.
          </li>
          <li>
            You are solely responsible for: (a) your reliance on the Purchasing and Staking
            Protocols &amp; Services; (b) any liability or damage that you may incur through use of
            the Purchasing and Staking Protocols &amp; Services; and (c) for all decisions or
            actions resulting from your use of the Purchasing and Staking Protocols &amp; Services.
          </li>
          <li>
            To the extent that the Purchasing and Staking Protocols &amp; Services facilitate
            deployment of your Supported Digital Tokens with third party protocols (“3rd Party
            Protocols”):
            <ul>
              <li>
                you acknowledge and agree to accept all risks associated with the 3rd Party
                Protocols; and
              </li>
              <li>
                you acknowledge and agree that we are not responsible or liable for any error, delay
                or interruption in or lack of access to the 3rd Party Protocols or for any loss of
                Supported Digital Tokens occasioned by or attributable to the 3rd Party Protocols,
                and we assume no responsibility or obligation to assist you to mitigate against any
                consequences arising out of or in connection with any 3rd Party Protocols.
              </li>
            </ul>
          </li>
          <li>
            Internet communications may be susceptible to interference or interception by third
            parties. Such interference or interception may include cyber-attacks, distributed
            denials of service or errors, malicious exploitation of defects or vulnerabilities
            associated with the source codes associated with the Purchasing and Staking Protocols
            &amp; Services. Where appropriate, we may use available technology to protect the
            security of the Purchasing and Staking Protocols &amp; Services and digital tokens
            including Supported Digital Tokens transferred or deposited in connection with the
            Purchasing and Staking Protocols &amp; Services. To the maximum extent permitted under
            applicable laws, we make no representations or warranties that the Purchasing and
            Staking Protocols &amp; Services are free of infection by computer viruses, any
            malicious software, interference or interception by third parties, or other unauthorized
            software, and disclaim all liability for the security, authenticity, integrity or
            confidentiality of any transactions made in connection with the Purchasing and Staking
            Protocols &amp; Services.
          </li>
          <li>
            In the event of any loss, hack or theft of digital tokens including Supported Digital
            Tokens in connection with the use of the Purchasing and Staking Protocols &amp;
            Services, you acknowledge and confirm that you shall have no right(s), claim(s) or
            causes of action in any way whatsoever against us.
          </li>
          <li>
            Like all software, the Purchasing and Staking Protocols &amp; Services may be subject to
            exploits. Given that the Purchasing and Staking Protocols &amp; Services and
            technologies related to them are new and experimental, we are not responsible for
            exploits from malicious actors. While we have taken a number of precautions to ensure
            the security of the Purchasing and Staking Protocols &amp; Services, is not possible to
            guarantee that the code is completely free from bugs or errors. You accept all risks
            that arise from using Purchasing and Staking Protocols &amp; Services, including but not
            limited to the risk of any funds being lost due to a failure or exploit of the
            Purchasing and Staking Protocols &amp; Services. Before using any of the Purchasing and
            Staking Protocols &amp; Services, it is your responsibility to review the underlying
            code of the Purchasing and Staking Protocols &amp; Services or if you do not have
            expertise to conduct such code review, it is your responsibility to engage professionals
            to advise you on the same.
          </li>
          <li>
            You are solely responsible for securing your login credentials and any device you use to
            access the Purchasing and Staking Protocols &amp; Services. You understand that anyone
            who obtains your login credentials and access to your device may access your wallet
            address with or without your authorization and may transfer any digital assets
            accessible through your wallet.
          </li>
          <li>
            We may modify or discontinue support for the Purchasing and Staking Protocols &amp;
            Services at any time. We reserve the right, at any time, in our sole discretion, to
            modify the Purchasing and Staking Protocols &amp; Services.
          </li>
          <li>
            In the event of a change to the underlying blockchain network in relation to a Supported
            Digital Token, or other network disruption in relation thereto, resulting in a fork of
            the existing blockchain into one (or more) additional blockchains associated with such
            network, the Purchasing and Staking Protocols &amp; Services may not support and we
            offer no assurance that the Purchasing and Staking Protocols &amp; Services will support
            such change related to any new digital assets created as a result of the fork. In
            addition, in the event of a fork, transactions on the network may be disrupted,
            including transactions involving the Purchasing and Staking Protocols &amp; Services.
          </li>
        </ol>
        <h1>
          <strong>NO OBLIGATORY SUPPORT</strong>
        </h1>
        <ol>
          <li>
            We have no obligation to provide any maintenance, support or other services in relation
            to the Purchasing and Staking Protocols &amp; Services, including providing any
            telephone assistance, documentation, error corrections, updates, upgrades, bug fixes,
            patches, and/or enhancements.
          </li>
          <li>
            Your access to and/or use of the Purchasing and Staking Protocols &amp; Services may
            also be interrupted, suspended or restricted where we provide any maintenance, support
            or other services in relation to the Purchasing and Staking Protocols &amp; Services.
          </li>
        </ol>
        <p>
          <strong>LIMITED USE AND USAGE RESTRICTIONS</strong>
        </p>
        <p> </p>
        <ol>
          <li>
            <p>The Purchasing and Staking Protocols &amp; Services:</p>

            <ul>
              <li>
                allow you to purchase Supported Digital Tokens via buying Packages, upgrading
                Packages and via additional token purchasing;
              </li>
              <li>
                allow you to purchase additional Supported Digital Tokens in accordance with the
                purchased or upgraded Package;
              </li>
              <li>allow you to stake your Supported Digital Tokens and earn stacking profits;</li>
              <li>
                allow you to exchange and withdraw Supported Digital Tokens to designated
                cryptocurrency ledger after our preliminary check.
              </li>
            </ul>
            <p>
              We will from time to time keep you updated with details of other Supported Digital
              Tokens, integrations, protocols, services.
            </p>
          </li>
          <li>
            <p>The Purchasing and Staking Protocols &amp; Services:</p>
            <ul>
              <li>
                may not support all digital tokens or 3rd Party Protocols and associated platforms
                but on the Supported Digital Tokens and Supported 3rd Party Protocols, accordingly
                you shall only use the Purchasing and Staking Protocols &amp; Services in relation
                to digital tokens which are Supported Digital Tokens and Supported 3rd Party
                Protocols;
              </li>
              <li>
                will contain the instructions (“FAQs”) to use Purchasing and Staking Protocols &amp;
                Services, and your compliance with these instructions and provision of all
                information requested through these instructions are essential for your use of the
                Purchasing and Staking Protocols &amp; Services;
              </li>
              <li>
                note that if you transfer, deposit or deploy digital tokens which are not Supported
                Digital Tokens for use with the Purchasing and Staking Protocols &amp; Services, or
                fail to comply with the FAQs, you risk loss of all or part of your digital tokens
                subject of such transfer, deposit or deployment, and
              </li>
              <li>
                you acknowledge and agree to accept all risks associated with such transfer,
                deposit, deployment or non-compliance;
              </li>
              <li>
                you acknowledge and agree that we are not responsible or liable for any error, delay
                or interruption in or lack of access to the Purchasing and Staking Protocols &amp;
                Services or for any loss of Supported Digital Tokens occasioned by or attributable
                to such transfer, deposit, deployment or non-compliance.
              </li>
            </ul>
          </li>
        </ol>
        <h1>
          <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
        </h1>
        <ol>
          <li>
            Unless otherwise permitted by us (or our Affiliates) pursuant to applicable open-source
            licensing protocols which permission may be revoked by us (or our Affiliates) at any
            time, the Purchasing and Staking Protocols &amp; Services (including, for the avoidance
            of doubt: all materials located therein; logos, trademarks, service marks, domain names,
            trade names; designs, contents made available through the Purchasing and Staking
            Protocols &amp; Services (including pages, documents and online graphics, audio and
            video); the source and object codes; and the format, directories, queries, algorithms,
            structure and organisation of the Purchasing and Staking Protocols &amp; Services), are
            proprietary to us (or our Affiliates), and all intellectual property rights associated
            therewith, whether registered or not, are protected by law and owned by or licensed to
            us. You acknowledge that you have no right, title, interest in and to the Purchasing and
            Staking Protocols &amp; Services and you agree not to challenge the validity of our
            ownership of or rights to them.
          </li>
          <li>
            You shall not reproduce, republish, post, transmit, communicate, distribute, use or
            otherwise exploit any content of the Purchasing and Staking Protocols &amp; Services in
            any way without our prior express written permission, which may be withheld for any or
            no reason.
          </li>
        </ol>
        <h1>
          <strong>TERMINATION &amp; WAIVERS</strong>
        </h1>
        <ol>
          <li>
            We may, in our sole and absolute discretion, at any time and for any reason, with or
            without prior notice, suspend or terminate your access and/or use of the Purchasing and
            Staking Protocols &amp; Services without liability to you, if (a) you fail to comply
            with these Terms; (b) you have used or are using the Purchasing and Staking Protocols
            &amp; Services for any illegal or impermissible purpose or activity including any
            activity which may expose or potentially expose us to civil or criminal liability; (c)
            we deem fit to do so for security reasons or to protect our interests or the interests
            of other users of the Purchasing and Staking Protocols &amp; Services; or (d) we are
            required to do so in compliance with any applicable law or regulation, or we are ordered
            to do so by any enforcement, regulatory or other governmental authority.
          </li>
          <li>
            Upon the suspension or termination of your access and/or use of the Purchasing and
            Staking Protocols &amp; Services, you shall immediately cease the use of the Purchasing
            and Staking Protocols &amp; Services. All clauses of Present Agreement so intended to
            survive after termination of these Terms. Termination shall be without prejudice to any
            accrued rights of either party.
          </li>
          <li>
            You agree and acknowledge that we and our Affiliates shall not be liable for any direct,
            indirect, special, incidental, consequential or other losses of any kind, in tort,
            contract or otherwise (including but not limited to loss of revenue, income or profits,
            and loss of use or data), arising out of or in connection with your use of the
            Purchasing and Staking Protocols &amp; Services.
          </li>
          <li>
            You undertake not to initiate or participate, and waive the right to participate in, any
            class action lawsuit or a class wide arbitration against us and/or our Affiliates.
          </li>
        </ol>
        <h1>
          <strong>USER ACKNOWLEDGEMENTS</strong>
        </h1>
        <ol>
          <li>
            <p>You represent and warrant to us that:</p>
            <ul>
              <li>
                you have sufficient knowledge and understanding of the operation, functionality,
                usage, storage, transmission mechanisms and other material characteristics of
                cryptographic tokens, blockchain technologies (including those relating to wallets,
                addresses, keys and storage mechanisms), smart contract technologies to appreciate
                the risks and implications associated with the Purchasing and Staking Protocols
                &amp; Services;
              </li>
              <li>
                you are not a resident, citizen, national or agent of, or an entity organized,
                incorporated or doing business in, Belarus, Burundi, Crimea and Sevastopol, Cuba,
                Democratic Republic of Congo, Iran, Iraq, Libya, North Korea, Somalia, Sudan, Syria,
                Venezuela, Zimbabwe or any other country to which the United States, the United
                Kingdom, the Cayman Islands, the European Union or any of its member states or the
                United Nations or any of its member states (collectively, the “Major Jurisdictions”)
                embargoes goods or imposes similar sanctions (such embargoed or sanctioned
                territories, collectively, the “Restricted Territories”);
              </li>
              <li>
                you do not intend to transact in or with any Restricted Territories or Sanctions
                List Persons;
              </li>
              <li>
                you have the legal authority to deal with any digital tokens including Supported
                Digital Tokens that may be used in connection with your use of the Purchasing and
                Staking Protocols &amp; Services and that such digital tokens including Supported
                Digital Tokens are not subject to any interests, claims or liens of whatsoever
                nature;
              </li>
              <li>
                you acknowledge and agree that no regulatory authority has examined or approved of
                these Terms, no such action has been or will be taken under the laws, regulatory
                requirements or rules of any jurisdiction, and the provision of these Terms to you
                does not imply that the applicable laws, regulatory requirements or rules have been
                complied with;
              </li>
            </ul>
            <ul>
              <li>
                the provision of these Terms, any part thereof or any copy thereof, or acceptance of
                the same by you, is not prohibited or restricted by the applicable laws, regulations
                or rules in your jurisdiction, and where any restrictions in relation to possession
                are applicable, you have observed and complied with all such restrictions at your
                own expense and without liability to us;
              </li>
              <li>
                these Terms constitute legal, valid, and binding obligations on you, enforceable in
                accordance with its terms;
              </li>
              <li>
                you will comply with all applicable laws and regulations with respect to your
                activities in connection with the Purchasing and Staking Protocols &amp; Services,
                and will not use the Purchasing and Staking Protocols &amp; Services to engage in
                fraudulent, illegal or suspicious activities and/or transactions, including fraud,
                money laundering and financing terrorism; and
              </li>
              <li>
                all of the above representations and warranties are true, complete, accurate and
                non-misleading from the time of your acceptance of these Terms, and such
                representations and warranties are deemed repeated by you each time you use the
                Purchasing and Staking Protocols &amp; Services.
              </li>
            </ul>
          </li>
          <li>
            You acknowledge and agree that to use the Purchasing and Staking Protocols &amp;
            Services, you may require a compatible device (including a mobile or computing device),
            appropriate third party software (such as browsers), and also connectivity to the
            internet. You are solely responsible for obtaining such device(s), software, and the
            necessary connectivity services to access and use the Purchasing and Staking Protocols
            &amp; Services. We assume no responsibility for such devices, software and services, or
            for any functionality of the Purchasing and Staking Protocols &amp; Services which is
            dependent on them to operate and you acknowledge and agree to accept all risks
            associated with for such devices, software and services;
          </li>
          <li>
            You will provide all support, information and assistance necessary for us or our
            authorized representatives to conduct investigations, audits or inspections for the
            purpose of ensuring proper compliance with these Terms or our legal or regulatory
            obligations, without any cost to us.
          </li>
          <li>
            You agree that SCA ALLIANCE LIMITED will withhold 15% of the amount you spent on the
            purchase or upgrading of the Package for the needs of insurance and marketing coverage
            of the provision of Purchasing and Staking Protocols &amp; Services. You agree that the
            purchase of additional tokens within the purchased or upgraded Package is accompanied by
            the payment of an additional commission in accordance with the Package, and we do not
            withhold 15% from such a purchase.
          </li>
          <li>
            You agree that in the event of any dispute or claim between us of whatever nature
            arising out of or in connection with any transaction conducted using the Purchasing and
            Staking Protocols &amp; Services, the records of such transactions as recorded by us
            shall prevail. You shall fully reimburse us for all reasonable costs and expenses
            incurred to investigate and collate the relevant records and information in connection
            with such disputes or claims.
          </li>
          <li>
            We may from time to time track and capture non-personal information such as the number
            of users and their frequency of use, profiles of users and their online preferences,
            using cookies on the Purchasing and Staking Protocols &amp; Services. We may also
            collect and use technical and associated information of the device(s) that you use to
            access the Purchasing and Staking Protocols &amp; Services. You consent to our use of
            cookies and collection of such information, for our business purposes.
          </li>
          <li>
            Your personal data may be collected by us when you use the Purchasing and Staking
            Protocols &amp; Services. You consent to our collection, use, disclosure and processing
            of your personal data in accordance with our Privacy Policy.
          </li>
          <li>
            You agree that any of your actions discrediting the reputation of the company and any of
            its divisions, for example, spreading false rumors, unverified information and any kind
            of speculation that harms the reputation of the company can be investigated by the SCA
            ALLIANCE LIMITED personnel and, if your involvement is proven, your account on the
            Website and USCA tokens will be blocked without the possibility of restoring access to
            them.
          </li>
          <li>
            You agree and undertake not to engage in similar activities in competitive Companies or
            projects, to refrain from advertising, providing consultations and other activities in
            the interests of competitive Companies and projects.
          </li>
        </ol>
        <h1>
          <strong>INDEMNIFICATION</strong>
        </h1>
        <p>
          You will indemnify and at all times keep us and/or our Affiliates indemnified against any
          and all losses, damages, actions, proceedings, costs, expenses, claims, demands,
          liabilities (including full legal costs) which may be suffered or incurred by us or
          asserted against us by any person, party or entity whatsoever, in respect of (a) any
          matter or event whatsoever arising out of or in connection with your breach of any
          provision in the terms; (b) your use and access of the Purchasing and Staking Protocols
          &amp; Services; (c) our reliance on information, data or records provided by you; (d) your
          violation of any applicable law or the rights of any third party; and/or (e) the
          occurrence of any event due to your act, omission or default which compromises the
          security or integrity of the Purchasing and Staking Protocols &amp; Services.
        </p>
        <h1>
          <strong>LIMITATIONS</strong>
        </h1>
        <ol>
          <li>
            <p>
              To the maximum extent permitted by law, we shall not be liable for any damage or loss
              of any kind, or any direct, consequential, incidental, special or indirect damages,
              costs (including but not limited to tax costs) or claims of any nature whatsoever
              including loss of any Supported Digital Tokens, arising out of or relating to:
            </p>

            <ul>
              <li>
                your use, access or reliance on the Purchasing and Staking Protocols &amp; Services;
              </li>
              <li>
                the furnishing or performance of the Purchasing and Staking Protocols &amp; Services
                or any delay or failure thereof;
              </li>
              <li>
                regardless of the form of action whether in contract, tort (including negligence),
                product liability or otherwise, even if we have been advised of the possibility of
                such damages.
              </li>
            </ul>
          </li>
          <li>
            Without prejudice to the above, our cumulative aggregate liability to you in connection
            with these Terms and the provision of the Purchasing and Staking Protocols &amp;
            Services shall not for any reason, exceed US$100. This limitation applies to all causes
            of action in the aggregate including, without limitation, breach of contract, breach of
            warranty, negligence, misrepresentations, strict liability, and other torts. In any
            jurisdiction in which these limitations of liability are restricted, our liability is
            limited to the greatest extent permitted by law.
          </li>
        </ol>
        <h1>
          <strong>MISCELLANEOUS</strong>
        </h1>
        <ol>
          <li>
            If any provision of these Terms is found to be unlawful, void or for any reason
            unenforceable, that provision will be deemed severable from these Terms and will not
            affect the validity and enforceability of any remaining provision. These Terms
            constitute the entire agreement between you and Persistence relating to the subject
            matter herein and supersede any and all prior or contemporaneous written or oral
            agreements or representations. Our failure to insist upon or enforce strict performance
            of any provision of these Terms shall not be construed as a waiver of any provision or
            right. Save for our Affiliates who shall have rights and benefits to the extent accorded
            thereto under these Terms, a person who is not a party to these Terms shall have no
            right under the Contracts (Rights of Third Parties) Act (Cap. 53B) to enforce these
            Terms.
          </li>
          <li>
            We may assign our rights and duties hereunder to any third party at any time without
            consent or notice to you. You shall not assign these Terms or any rights or obligations
            herein without our prior written consent.
          </li>
          <li>
            Subject always to clauses 6.3 to 6.5 of these Terms, you agree that these Terms shall be
            construed in accordance with the laws of United Kingdom. In the event of any dispute
            arising out of or in connection with this Agreement, including any question regarding
            its existence, validity or termination, the parties shall first seek settlement of that
            dispute. In the event that such dispute is not resolved within a period of 30 days from
            the commencement of such settlement process, such dispute shall be referred to and
            finally be resolved by arbitration in United Kingdom in accordance with the rules for
            the time being in force, which rules are deemed to be incorporated by reference in this
            clause. The tribunal shall consist of a sole arbitrator to be appointed. The language of
            the arbitration shall be English.
          </li>
          <li>
            You agree not to engage in any categories of below prohibited activities in relation to
            your access or use of the Staking Protocol &amp; Services:
            <ul>
              <li>
                Activity that transmits, exchanges, or is otherwise supported by the direct or
                indirect proceeds of criminal or fraudulent activity.
              </li>
              <li>Activity that breaches the Terms.</li>
              <li>
                Activity that infringes on or violates any copyright, trademark, service mark,
                patent, right of publicity, right of privacy, or other proprietary or intellectual
                property rights under the law.
              </li>
              <li>
                Activity that seeks to interfere with or compromise the integrity, security, or
                proper functioning of any computer, server, network, personal device, or other
                information technology system, including, but not limited to, the deployment of
                viruses and denial of service attacks.
              </li>
              <li>
                Activity that seeks to defraud us or any other person or entity, including, but not
                limited to, providing any false, inaccurate, or misleading information in order to
                unlawfully obtain the property of another.
              </li>
              <li>
                Activity that violates any applicable law, rule, or regulation concerning the
                integrity of trading markets, including, but not limited to, the manipulative
                tactics commonly known as spoofing and wash trading.
              </li>
              <li>
                Activity that violates any applicable law, rule, or regulation of any Major
                Jurisdiction.
              </li>
              <li>
                Activity that disguises or interferes in any way with the IP address of the computer
                you are using to access or use the Interface or that otherwise prevents us from
                correctly identifying the IP address of the computer you are using to access the
                Interface.
              </li>
              <li>Activity that contributes to or facilitates any of the foregoing activities.</li>
            </ul>
          </li>
          <li>
            Any cause of action or claim you may have with respect to the Purchasing and Staking
            Protocols &amp; Services must be commenced within one (1) year after the cause of action
            or claim arises.
          </li>
        </ol>
      </section>
    </div>
  );
};
