export enum StatusCodes {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  MULTI_STATUS = 207,
  MULTIPLE_CHOICES = 300,
  MOVED_PERMANENTLY = 301,
  MOVED_TEMPORARILY = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  REQUEST_TOO_LONG = 413,
  REQUEST_URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  IM_A_TEAPOT = 418,
  INSUFFICIENT_SPACE_ON_RESOURCE = 419,
  MISDIRECTED_REQUEST = 421,
  UNPROCESSABLE_ENTITY = 422,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  INSUFFICIENT_STORAGE = 507,
  NETWORK_AUTHENTICATION_REQUIRED = 511
}

export const ServerErrors: number[] = [
  StatusCodes.INTERNAL_SERVER_ERROR,
  StatusCodes.INTERNAL_SERVER_ERROR,
  StatusCodes.NOT_IMPLEMENTED,
  StatusCodes.BAD_GATEWAY,
  StatusCodes.SERVICE_UNAVAILABLE,
  StatusCodes.GATEWAY_TIMEOUT,
  StatusCodes.HTTP_VERSION_NOT_SUPPORTED,
  StatusCodes.INSUFFICIENT_STORAGE,
  StatusCodes.NETWORK_AUTHENTICATION_REQUIRED,
];

export const SuccessCodes: number[] = [
  StatusCodes.OK,
  StatusCodes.CREATED,
  StatusCodes.ACCEPTED,
  StatusCodes.NON_AUTHORITATIVE_INFORMATION,
  StatusCodes.NO_CONTENT,
  StatusCodes.RESET_CONTENT,
  StatusCodes.PARTIAL_CONTENT,
  StatusCodes.MULTI_STATUS,
];

export const ClientErrors: number[] = [
  StatusCodes.BAD_REQUEST,
  StatusCodes.UNAUTHORIZED,
  StatusCodes.PAYMENT_REQUIRED,
  StatusCodes.FORBIDDEN,
  StatusCodes.NOT_FOUND,
  StatusCodes.METHOD_NOT_ALLOWED,
  StatusCodes.NOT_ACCEPTABLE,
  StatusCodes.PROXY_AUTHENTICATION_REQUIRED,
  StatusCodes.REQUEST_TIMEOUT,
  StatusCodes.CONFLICT,
  StatusCodes.GONE,
  StatusCodes.LENGTH_REQUIRED,
  StatusCodes.PRECONDITION_FAILED,
  StatusCodes.REQUEST_TOO_LONG,
  StatusCodes.REQUEST_URI_TOO_LONG,
  StatusCodes.UNSUPPORTED_MEDIA_TYPE,
  StatusCodes.REQUESTED_RANGE_NOT_SATISFIABLE,
  StatusCodes.EXPECTATION_FAILED,
  StatusCodes.IM_A_TEAPOT,
  StatusCodes.INSUFFICIENT_SPACE_ON_RESOURCE,
  StatusCodes.MISDIRECTED_REQUEST,
  StatusCodes.UNPROCESSABLE_ENTITY,
  StatusCodes.LOCKED,
  StatusCodes.FAILED_DEPENDENCY,
  StatusCodes.PRECONDITION_REQUIRED,
  StatusCodes.TOO_MANY_REQUESTS,
  StatusCodes.REQUEST_HEADER_FIELDS_TOO_LARGE,
  StatusCodes.UNAVAILABLE_FOR_LEGAL_REASONS,
];