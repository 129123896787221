import { EErrorType } from './errors'

export type TSnackbarCallback = (msg: string | JSX.Element) => void
export type TTXStatusCallback = (data: ITxStatus) => void

export enum ECurrentModal {
  TX_STATUS = 'Tx_status',
  COMFIRM_EMAIL_PHONE = 'Confirm_email_phone',
  BONUS_PACKAGES__GIVE = 'Bonus_packages__give',
  BONUS_PACKAGES__GIVE_AND_ACTIVATE = 'Bonus_packages__give_and_activate',
  STAKING__UNSTAKE = 'Staking__unstake',
  STAKE__BUY_TOKENS = 'Stake__buy_tokens',
  STAKE__STAKE_CONFIRM = 'Stake__stake_confirm',
  AUTH__NEED_MM = 'Auth__need_MM',
  AUTH__NEED_INVITER_ADDRESS = 'Auth_need_inviter_address',
  PERMISSION__REQUESTS = 'Permission__requests',
  PERMISSION_REQUESTS__STAKE_CONFIRM = 'Permission_requests_stake_confirm',
  PERMISSION_REQUESTS__EXCHANGE_CONFIRM = 'Permission_requests_exchange_confirm',
  SWAP__CONFIRM = 'Swap__confirm',
  SETTINGS__DELETE_ACCOUNT_CONFIRM = 'Settings__delete_account_confirm',
  CHANGE_NETWORK = 'Change_network',
  CHANGE_NETWORK__TX_DATA = 'Change_network__tx_data',
  PACKAGES__AGREEMENT = 'Packages_agreement',
  USER_BLOCK = 'User_block',
  FILL_PROFILE = 'Fill_profile',
  SUMSUB_VERIFICATION = 'SumSum_verification',
  STAKING_AGREEMENT = 'Staking_agreement',
  WITHDRAWAL__REQUESTS = 'Withdrawal__requests',
  CONVERT_TO_USCA = 'Convert_to_USCA',
  REFERRAL_LINK_LOGIN = 'Referral_link_login',
  QRCode = 'QRCode',
  IMAGE_PREVIEW = 'IMAGE_PREVIEW',
  NOTIFICATION = 'NOTIFICATION',
}

export interface ITxStatus {
  status?: ETxStatus
  description?: string | JSX.Element
  errorType?: EErrorType
}

export enum ETxStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}
