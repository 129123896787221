import { axios } from '@app/services/httpClient/axios';
import { useQuery, useQueryClient, MutationOptions, useMutation } from '@tanstack/react-query';
import { REQUEST_WITHDRAW, REQUEST_WITHDRAW_REJECTE, WITHDRAWAL_REQUESTS } from '@app/constants';
import {
  IAcceptRequestWithdrawalResponse,
  ISendRequestWithdrawal,
  IWithdrawData,
} from '@app/types';
import {AxiosError, AxiosResponse} from 'axios';
import { useProfile } from './useProfile';
import { httpClient } from '@app/services';
import { isAxiosError } from '@app/helpers';
import { toast } from 'react-toastify';
import {updateRequestedWithdrawals} from "@app/stores/atom/infoBar";

const CACHE_KEY = 'withdrawals';

export const useWithdrawals = (page: number, take = 25) => {
  const { data: profile } = useProfile();
  return useQuery({
    queryKey: [CACHE_KEY, { page }],
    queryFn: () =>
      axios.get<IWithdrawData>(WITHDRAWAL_REQUESTS, {
        params: { page, take },
      }),
    select: (data) => data.data,
    // keepPreviousData: true,
    staleTime: Infinity,
    enabled: !!profile?.user,
  });
};

export const fetchRequestedWithdrawals = (): Promise<IWithdrawData> => {
  return new Promise<IWithdrawData>((resolve, reject) => {
    httpClient.get<IWithdrawData>(WITHDRAWAL_REQUESTS, {
      params: {
        page: 1,
        take: 1000,
        status: 'Requested',
      },
    })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
  });
};
// const requestedWithdrawals = useQuery('requestedWithdrawals', fetchRequestedWithdrawals); //to use this

export const requestedWithdrawals = async (): Promise<IWithdrawData> => {
  try {
    const { data } = await httpClient.get<IWithdrawData>(WITHDRAWAL_REQUESTS, {
      params: {
        page: 1,
        take: 1000,
        status: 'Requested',
      },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useRejectWithdrawal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => axios.put(`${REQUEST_WITHDRAW_REJECTE}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CACHE_KEY] })
      updateRequestedWithdrawals();
    },
    onError: (error: AxiosError) => {
      if (isAxiosError(error) && error.response && typeof error.response.data === 'string') {
        toast.error(error.response.data);
      } else {
        toast.error('Something went wrong, please try again later');
      }
    },
  });
};

export const useCreateWithdrawal = (
  options?: MutationOptions<
    AxiosResponse<IAcceptRequestWithdrawalResponse, any>,
    unknown,
    ISendRequestWithdrawal
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ISendRequestWithdrawal) =>
      axios.post<IAcceptRequestWithdrawalResponse>(REQUEST_WITHDRAW, data),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CACHE_KEY] });
      options?.onSuccess?.(...args);
    },
  });
};
