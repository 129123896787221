import React, { useMemo, useState } from 'react';
import { useAccount, useBalance, useSwitchChain } from 'wagmi';
import { type Address } from 'viem';
import { useUser } from '@app/hooks/useUser';
import {
  EButtonType,
  ECurrentModal,
  EStatusKyc,
  EWeb3Auth,
  MigrationOperationResult,
} from '@app/types';
import Image from 'next/image';
import Link from 'next/link';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useModal, useProfile } from '@app/hooks';
import { getWalletString } from '@app/helpers/utils';
import { localStorageHelper } from '@app/helpers';
import { BuyTokens } from '@app/components/packageComponents/BuyTokens/BuyTokens';
import { CurrentPackage } from '@app/components/packageComponents/CurrentPackage';
import { CustomDialog } from '@app/components/shared/Dialog';
import { AuthForm } from '@app/components/sideBar/AuthForm';
import { TokensBalances } from '../TokensBalances';
import verifiedIcon from '../../../assets/icons/verifiedIcon.svg';
import attentionMarkR from '../../../assets/icons/attentionMarkR.svg';
import attentionMarkY from '../../../assets/icons/attentionMarkY.svg';
import { Button } from '../../shared/Button';
import styles from './style.module.scss';
import { ReferralLinkBlock } from '@app/components/sideBar/ReferralLinkBlock';
import { Notice } from '../Notice';
import { AddTokenDialog } from '@app/components/AddTokenDialog';
import { useReadTokenConverterGetMigrationStatus } from '@app/contracts';
import process from 'process';
import { IUseConnectAuthFormReturn } from '@app/types/auth-form';
import { LanguageSwitcher } from '@app/components/LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'next-i18next';
import { useClipboard } from '@app/hooks/useClipboard';
import { useWebhookUpdateUserData } from '@app/hooks/useWebhookUpdateUserData';
import { useWeb3Auth } from '../../../../pages/_app';
import { storageService } from '@app/services/storage/storage';

interface InfoBarPropsType {
  isOpen?: () => void;
  setShowPrivacyPolicyDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowClientAgreementDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPartnershipAgreementDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowStakingAgreementDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showStakingAgreementDialog: boolean;
  showPartnershipAgreementDialog: boolean;
  showClientAgreementDialog: boolean;
  showPrivacyPolicyDialog: boolean;
  connectAuthForm: IUseConnectAuthFormReturn;
}

export const InfoBar: React.FC<InfoBarPropsType> = ({
  isOpen,
  setShowPrivacyPolicyDialog,
  setShowClientAgreementDialog,
  setShowPartnershipAgreementDialog,
  setShowStakingAgreementDialog,
  showStakingAgreementDialog,
  showPartnershipAgreementDialog,
  showClientAgreementDialog,
  showPrivacyPolicyDialog,
  connectAuthForm,
}) => {
  const { setCurrentModal } = useModal();
  const { t } = useTranslation();
  const [showBuyTokens, setShowBuyTokens] = useState(false);
  const [showAddTokenDialog, setShowAddTokenDialog] = useState(false);
  const { chains, isPending, switchChain } = useSwitchChain();
  const { data: profile, isLoggedIn, refetch } = useProfile();
  const { user } = useUser();
  const { address, isConnected, chain: activeChain } = useAccount();
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const userData = profile?.user;
  const isKycConfirmed = profile?.reviewStatus;
  const version = process.env.REACT_APP_VERSION!;
  const { copyToClipboard, readFromClipboard, clipboardValue } = useClipboard();
  // @ts-ignore
  const { web3Auth } = useWeb3Auth();

  useWebhookUpdateUserData(() => {
    refetch();
  }, profile);

  const { data: migrationStatus } = useReadTokenConverterGetMigrationStatus({
    address: process.env.TOKEN_CONVERTER_ADDRESS! as Address,
    args: [address!],
    query: {
      enabled: !!address,
    },
  });

  const {
    data: oldSCA,
    error,
    isError,
  } = useBalance({
    token: process.env.OLD_SCA_ADDRESS as Address,
    address,
  });

  if (isError) {
    console.warn('Balance not available', error);
  }

  const isAvailableOldSCABalance = useMemo(() => {
    return oldSCA?.formatted && Number(oldSCA?.formatted) > 0.1;
  }, [oldSCA?.formatted]);

  const isShowBtnTokenAdded = () => {
    const tokenAdded = storageService.getItem<any>('tokenAdded');
    return (
      !(tokenAdded && address && tokenAdded[address]) &&
      web3Auth?.connectedAdapterName === EWeb3Auth.METAMASK
    );
  };

  const getUserName = () => (userData ? userData.firstName + ' ' + userData.lastName : '***');

  const handlePrivacyPolicyLinkClick = () => {
    setShowPrivacyPolicyDialog(!showPrivacyPolicyDialog);
  };

  const handleClientAgreementLinkClick = () => {
    setShowClientAgreementDialog(!showClientAgreementDialog);
  };

  const handlePartnershipAgreementLinkClick = () => {
    setShowPartnershipAgreementDialog(!showPartnershipAgreementDialog);
  };

  const { onDisconnect } = connectAuthForm;

  const verifyViewStatus = () => {
    if (userData && isKycConfirmed === EStatusKyc.COMPLETED) {
      return (
        <Box className={styles.profileVerify}>
          <Image src={verifiedIcon} alt="" />
          <Typography className={styles.typographyG}>{t('info_bar.kyc_verified')}</Typography>
        </Box>
      );
    } else if (userData && isKycConfirmed === EStatusKyc.PENDING) {
      // toast.error('In order to buy package and invest in SCA ALLIANCE Mutual Funds, please complete KYC procedure.');
      return (
        <Box className={styles.profileVerify}>
          <Image src={attentionMarkY} alt="" />
          <Typography className={styles.typographyY}>{t('info_bar.email_verified')}</Typography>
        </Box>
      );
    } else if (userData && isKycConfirmed === EStatusKyc.NOT_STARTED) {
      // toast.error('In order to buy package and invest in SCA ALLIANCE Mutual Funds, please complete e-mail verification and KYC.');
      return (
        <Box className={styles.profileVerify}>
          <Image src={attentionMarkR} alt="" />
          <Typography className={styles.typographyR}>{t('info_bar.non_verified')}</Typography>
        </Box>
      );
    } else if (userData && isKycConfirmed === EStatusKyc.INIT) {
      return (
        <Box className={styles.profileVerify}>
          <Image src={attentionMarkR} alt="" />
          <Typography className={styles.typographyR}>{t('info_bar.kyc_init')}</Typography>
        </Box>
      );
    } else return;
  };

  const handleOpen = () => {
    setCurrentModal(ECurrentModal.CONVERT_TO_USCA);
  };

  const isMigrationNeeded = migrationStatus === MigrationOperationResult.MIGRATION_NEEDED;

  return (
    <Box className={styles.componentWrapper}>
      <Box className={styles.languageButton}>
        <LanguageSwitcher />
      </Box>
      {isConnected && isLoggedIn ? (
        <Box className={styles.container}>
          <Grid container>
            <Box className={styles.profileInfo} mt={1}>
              <Typography className={styles.profileName}>{getUserName()}</Typography>
              <Box mt={1}>{verifyViewStatus()}</Box>
            </Box>
            <Link href="/settings">
              <IconButton color="primary">
                <AccountCircleIcon />
              </IconButton>
            </Link>
          </Grid>
          <Button
            customClass={styles.disconnectButton}
            type={EButtonType.OUTLINED}
            onClick={onDisconnect}
          >
            {t('info_bar.disconnect')}
          </Button>

          {activeChain?.name !== chains[0]?.name && (
            <Button
              customClass={styles.disconnectButton}
              type={EButtonType.FILLED}
              disabled={!switchChain || chains[0].id === activeChain?.id}
              onClick={() => switchChain?.({ chainId: chains[0].id })}
            >
              {t('info_bar.switch_to')} {chains[0]?.name}
              {isPending && ` (${t('info_bar.switching')})`}
            </Button>
          )}

          <Box className={styles.wrapper}>
            <Tooltip
              title={
                clipboardValue === address ? t('info_bar.copied') : t('info_bar.copy_clipboard')
              }
            >
              <Box
                className={styles.select}
                onClick={() => copyToClipboard(address)}
                onMouseEnter={readFromClipboard}
              >
                <Box className={styles.selectContent}>
                  <Typography className={styles.selectTitle}>
                    {getWalletString(address || '')}
                  </Typography>

                  <Typography className={styles.selectSubTitle}>{activeChain?.name}</Typography>
                </Box>
              </Box>
            </Tooltip>
            {isAvailableOldSCABalance && isMigrationNeeded && (
              <>
                <Notice color={'orange'}>
                  <Typography className={styles.noticeTitle}>{t('info_bar.old_notice')}</Typography>
                  <Typography className={styles.noticeSubTitle}>
                    {t('info_bar.old_description')}
                  </Typography>
                  <Typography className={styles.noticeSubTitle}>
                    1{t('info_bar.description_1')}
                  </Typography>{' '}
                  <Typography className={styles.noticeSubTitle}>
                    {t('info_bar.description_2')}
                  </Typography>
                </Notice>

                <Button
                  customClass="btn"
                  type={EButtonType.FILLED}
                  disabled={!isAvailableOldSCABalance}
                  onClick={handleOpen}
                >
                  {t('info_bar.convert_button')}
                </Button>
              </>
            )}

            {!!profile?.partnerAgreementAcceptedAt && <ReferralLinkBlock />}

            <TokensBalances isMigrationNeeded={isMigrationNeeded} />

            {/*{user.package && isKycConfirmed === 'completed' && (*/}
            {user.package && (
              <Button
                customClass="btn"
                type={EButtonType.FILLED}
                onClick={() =>
                  (user.tokensAvailable && user.tokensAvailable > BigInt(0)) ||
                  user.package?.unlimitedBuying
                    ? setShowBuyTokens(true)
                    : setShowAlertDialog(true)
                }
              >
                {t('info_bar.buy_token_button')}
              </Button>
            )}

            {/*<Button*/}
            {/*  customClass="btn"*/}
            {/*  type={EButtonType.FILLED}*/}
            {/*  onClick={() => window.open('https://crypto.com')}*/}
            {/*>*/}
            {/*  Buy crypto with credit card*/}
            {/*</Button>*/}

            {isShowBtnTokenAdded() && (
              <Button
                customClass="btn"
                type={EButtonType.FILLED}
                onClick={() => setShowAddTokenDialog(true)}
              >
                {t('info_bar.add_token_button')}
              </Button>
            )}

            {user.tokensAvailable === BigInt(0) && !user.package?.unlimitedBuying && (
              <Typography className={styles.selectSubTitle}>
                {t('info_bar.upgrade_package_to_buy')}
              </Typography>
            )}

            {user.package && <CurrentPackage package={user.package} isOpen={isOpen} />}
          </Box>

          {showBuyTokens && (
            <BuyTokens user={user} open={showBuyTokens} onClose={() => setShowBuyTokens(false)} />
          )}

          <CustomDialog
            open={showAlertDialog}
            title={t('info_bar.buy_token_button')}
            handleClose={() => setShowAlertDialog(false)}
          >
            <Typography>{t('info_bar.dear_client')}</Typography>
            <Typography>{t('info_bar.you_reached')}</Typography>
            <Typography component={'div'}>
              {t('info_bar.please')}{' '}
              <Link
                href="/packages"
                passHref
                onClick={() => {
                  setShowAlertDialog(false);
                }}
              >
                {t('info_bar.upgrade_package')}
              </Link>{' '}
              {t('info_bar.to_buy_tokens')}
            </Typography>
          </CustomDialog>

          {showAddTokenDialog && <AddTokenDialog onClose={() => setShowAddTokenDialog(false)} />}
        </Box>
      ) : (
        <AuthForm connectAuthForm={connectAuthForm} />
      )}
      <Box
        style={{
          marginTop: '20px',
          marginBottom: 'auto',
          marginLeft: '5px',
          paddingBottom: '15px',
          order: 1,
        }}
      >
        <Typography variant="subtitle2" className={styles.copyright}>
          <p className={styles.link} onClick={handlePrivacyPolicyLinkClick}>
            <a className="primary">{t('info_bar.sca_privacy_policy')}</a>
          </p>
          <p className={styles.link} onClick={handleClientAgreementLinkClick}>
            <a className="primary">{t('info_bar.sca_client_agreement')}</a>
          </p>
          <p className={styles.link} onClick={handlePartnershipAgreementLinkClick}>
            <a className="primary">{t('info_bar.partnership_agreement')}</a>
          </p>
          {/*<p className={styles.link} onClick={handleStakingAgreementLinkClick}>*/}
          {/*  <a className="primary">{t('info_bar.staking_agreement')}</a>*/}
          {/*</p>*/}
          <p>{t('info_bar.copyright')}</p>
          <p>{t('info_bar.all_rights_reserved')}</p>
          <p style={{ opacity: 0.5 }}>v{version} © 2024 SCA ALLIANCE</p>
        </Typography>
      </Box>
    </Box>
  );
};
