import { axios } from './axios';

export const httpClient = {
  setAxiosToken(token: string) {
    // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  deleteAxiosToken() {
    // axios.defaults.headers.common.Authorization = '';
  },

  async get<T>(url: string, customData?: any) {
    return axios.get<T>(url, customData);
  },

  async post<T>(url: string, customData?: any) {
    return axios.post<T>(url, customData);
  },

  put(url: string, data?: any) {
    return axios.put(url, data);
  },

  async patch<T>(url: string, customData?: any) {
    return axios.patch<T>(url, customData);
  },

  async delete(url: string, data?: any) {
    return axios.delete(url, data);
  },
};