import { httpClient } from '../httpClient';
import {
  FUNDS_UPCOMING,
  FUNDS_MUTUAL,
  FUNDS_UNSTAKED,
  FUNDS_BY_ID,
} from '@app/constants';
import {
  IFundsById, IFundsByIdMetadataTickerFunds,
  IMutualFundsParticipationData,
  IUnstakedNonCapturedProfitData,
  IUpcomingFundsData,
} from '@app/types/fundsAnalysis';

export const fundsAnalysisService = {
  getQueryParamsString(queryParams: any) {
    return new URLSearchParams(queryParams).toString();
  },
  async getUpcoming(page: number, take: number, isAuth: boolean) {
    const { data } = await httpClient.get<IUpcomingFundsData>(
      `${FUNDS_UPCOMING}${
        !isAuth ? '/without-auth' : ''
      }?${this.getQueryParamsString({ page, take })}`
    );
    return data;
  },
  async getUnstaked(page: number, take: number) {
    const { data } = await httpClient.get<IUnstakedNonCapturedProfitData>(
      `${FUNDS_UNSTAKED}?${this.getQueryParamsString({ page, take })}`
    );
    return data;
  },
  async getMutual(page: number, take: number) {
    const { data } = await httpClient.get<IMutualFundsParticipationData>(
      `${FUNDS_MUTUAL}?${this.getQueryParamsString({ page, take })}`
    );
    return data;
  },
  async getFundById(id: string) {
    const { data } = await httpClient.get<IFundsByIdMetadataTickerFunds>(`${FUNDS_BY_ID}/${id}`);
    return {
      ...data,
      metadataTickers: data?.metadataTickerFunds.map((tickerFund: any) => {
        const {metadataTicker, id, ...rest} = tickerFund;
        return {...metadataTicker, ...rest};
      }),
    };
  },
};
