import { useEffect, useMemo, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { InfoBar } from '@app/components/sideBar/InfoBar';
import { MainSidebar } from '@app/components/sideBar/MainSidebar';
import { Notice } from '@app/components/sideBar/Notice';
import { CustomDialog } from '@app/components/shared/Dialog';
import { CountdownTimer } from '@app/components/Modal/countDownTimer';
import { Box, Grow, Typography } from '@mui/material';
import { isIOSBrowser } from '@app/helpers';
import classNames from 'classnames';
import styles from './style.module.scss';
import axios from 'axios';
import { useConnectAuthForm } from '@app/hooks/useConnectAuthForm';

export const whiteList: string[] = [
  '194.183.171.247',
  '212.252.141.205',
  '37.248.225.214',
  '109.207.244.247',
  '188.163.82.33',
];

type Props = {
  children: React.ReactNode;
};

export function MainLayout({ children }: Props) {
  const [isOpenWallet, setIsOpenWallet] = useState(false);
  const handlerIsOpenWallet = (isWallet: boolean) => {
    setIsOpenWallet(isWallet);
  };
  const [showPrivacyPolicyDialog, setShowPrivacyPolicyDialog] = useState(false);
  const [showPartnershipAgreementDialog, setShowPartnershipAgreementDialog] = useState(false);
  const [showStakingAgreementDialog, setShowStakingAgreementDialog] = useState(false);
  const [showClientAgreementDialog, setShowClientAgreementDialog] = useState(false);

  const [showTechnicalWorks] = useState<boolean>(
    process.env.REACT_APP_TECHNICAL_WORKS_TIMER === 'true',
  );
  const [messageTechnicalWorks] = useState<string>(
    process.env.REACT_APP_TECHNICAL_WORKS_MESSAGE_SUPPORT_DATE ?? '',
  );
  const [ip, setIP] = useState<string>();
  const [daysInMS] = useState<number>(Number(process.env.REACT_APP_COUNTDOWN_TIMER) ?? 0);
  const [startTimeTimer] = useState<number>(Number(process.env.REACT_APP_START_TIME_TIMER) ?? 0);
  const dateTimeAfterDays = startTimeTimer + daysInMS;
  const nowTime = new Date();

  const collapseWalletTypes = isIOSBrowser()
    ? classNames(styles.collapseWallet, styles.collapseWalletIOS)
    : styles.collapseWallet;

  const { boot } = useIntercom();

  useEffect(() => {
    if (boot) boot();
  }, [boot]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await axios.get('https://api.ipify.org/?format=json');
    setIP(res.data.ip);
  };

  const accessLogin = useMemo(() => {
    return !(ip && whiteList.includes(ip));
  }, [ip, whiteList]);

  const connectAuthForm = useConnectAuthForm();

  return (
    <>
      <Box className={styles.container}>
        <MainSidebar
          handlerIsOpenWallet={handlerIsOpenWallet}
          isOpenWallet={isOpenWallet}
          setShowPrivacyPolicyDialog={setShowPrivacyPolicyDialog}
          setShowPartnershipAgreementDialog={setShowPartnershipAgreementDialog}
          setShowStakingAgreementDialog={setShowStakingAgreementDialog}
          setShowClientAgreementDialog={setShowClientAgreementDialog}
          showStakingAgreementDialog={showStakingAgreementDialog}
          showPartnershipAgreementDialog={showPartnershipAgreementDialog}
          showClientAgreementDialog={showClientAgreementDialog}
          showPrivacyPolicyDialog={showPrivacyPolicyDialog}
        />
        <Box className={classNames(styles.content, 'with-scroll')}>
          {showTechnicalWorks && (
            <Box mb={3}>
              <Notice color={'orange'}>
                <Typography className={styles.noticeTitle} mb={1}>
                  Dear Client,
                </Typography>
                <Typography className={styles.noticeTitle}>{messageTechnicalWorks}</Typography>{' '}
              </Notice>
            </Box>
          )}

          {children}
        </Box>

        <Box className={classNames(styles.infoBar, 'with-scroll')}>
          <InfoBar
            setShowPrivacyPolicyDialog={setShowPrivacyPolicyDialog}
            setShowPartnershipAgreementDialog={setShowPartnershipAgreementDialog}
            setShowStakingAgreementDialog={setShowStakingAgreementDialog}
            setShowClientAgreementDialog={setShowClientAgreementDialog}
            showPartnershipAgreementDialog={showPartnershipAgreementDialog}
            showStakingAgreementDialog={showStakingAgreementDialog}
            showClientAgreementDialog={showClientAgreementDialog}
            showPrivacyPolicyDialog={showPrivacyPolicyDialog}
            connectAuthForm={connectAuthForm}
          />
        </Box>
      </Box>

      <Box className={styles.containerMobile}>
        {isOpenWallet && (
          <Grow in={isOpenWallet}>
            <Box className={collapseWalletTypes}>
              <Box className={classNames(styles.infoBarMobile)}>
                <InfoBar
                  isOpen={() => handlerIsOpenWallet(!isOpenWallet)}
                  setShowPrivacyPolicyDialog={setShowPrivacyPolicyDialog}
                  setShowPartnershipAgreementDialog={setShowPartnershipAgreementDialog}
                  setShowStakingAgreementDialog={setShowStakingAgreementDialog}
                  setShowClientAgreementDialog={setShowClientAgreementDialog}
                  showPartnershipAgreementDialog={showPartnershipAgreementDialog}
                  showStakingAgreementDialog={showStakingAgreementDialog}
                  showClientAgreementDialog={showClientAgreementDialog}
                  showPrivacyPolicyDialog={showPrivacyPolicyDialog}
                  connectAuthForm={connectAuthForm}
                />
              </Box>
            </Box>
          </Grow>
        )}
      </Box>

      {accessLogin && (
        <CustomDialog
          title="Technical Works"
          open={startTimeTimer < nowTime.getTime() && daysInMS > 0}
        >
          <Typography className={styles.title} variant="h4">
            <CountdownTimer targetDate={dateTimeAfterDays} />
          </Typography>
        </CustomDialog>
      )}
    </>
  );
}
