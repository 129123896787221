import { EErrorType } from '@app/types/errors';
import { SupportCenterErrorMessage } from '../SupportCenterErrorMessage';
import styles from './style.module.scss';
import { useTranslation } from 'next-i18next';

interface IProps {
  type?: EErrorType;
  linkClass?: string;
}

export const DefaultFetchDataErrorMsg = ({ type, linkClass }: IProps) => {
  const { t } = useTranslation();

  switch (type) {
    case EErrorType.SERVER:
      return (
        <SupportCenterErrorMessage linkClass={linkClass || styles.helpLink} retryMsg={true}>
          <b>{t('fetch_data_error_msg.server_error')}</b>
        </SupportCenterErrorMessage>
      );
    default:
      return (
        <SupportCenterErrorMessage linkClass={linkClass || styles.helpLink} retryMsg={true}>
          {t('fetch_data_error_msg.went_wrong')}
        </SupportCenterErrorMessage>
      );
  }
};
