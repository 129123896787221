export function packageNameConverter(size: number) {
  switch (size) {
    case 100:
      return 'Basic'
    case 250:
      return 'Bronze'
    case 500:
      return 'Silver'
    case 1000:
      return 'Gold'
    case 2500:
      return 'Platinum'
    case 5000:
      return 'Diamond'
    case 10000:
      return 'Ruby'
    default:
      return undefined;
  }
}

export function packageIdConverter(size: number | undefined) {
  switch (size) {
    case 1:
      return 'Basic'
    case 2:
      return 'Bronze'
    case 3:
      return 'Silver'
    case 4:
      return 'Gold'
    case 5:
      return 'Platinum'
    case 6:
      return 'Diamond'
    case 7:
      return 'Ruby'
    default:
      return undefined;
  }
}
