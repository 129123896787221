import { COUNTRIES, GEO } from '@app/constants';
import { axios } from '@app/services/httpClient/axios';
import { IСountryList } from '@app/types';
import { useQuery } from '@tanstack/react-query';

export const useCountries = () => {
  return useQuery({
    queryKey: ['countries'],
    queryFn: () =>
      axios
        .get<IСountryList>(`${GEO}${COUNTRIES}`, {
          params: {
            'sort-ascii-name': 'desc',
            take: 500,
          },
        })
        .then((res) => res.data.data),
    staleTime: Infinity,
  });
};
