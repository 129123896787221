import React, { BaseSyntheticEvent, ReactNode, useEffect } from 'react';
import styles from './style.module.scss';
import { ErrorMessage } from '../ErrorMessage';
import { Button } from '../Button';
import { EButtonType } from '@app/types';
import { Box } from '@mui/material';
import { useTranslation } from 'next-i18next';

export const LargeInput: React.FC<{
  isNumberOnly?: boolean;
  value?: number | string;
  availableClassName?: string;
  availableText?: string;
  tokenName: string;
  placeholder?: string;
  buttonText?: string;
  name: string;
  maxValue?: number | string;
  minValue?: number;
  maxErrorMsg?: string;
  minErrorMsg?: string;
  isDisabled?: boolean;
  handleChangeValue?: (event: BaseSyntheticEvent, value: string) => void;
  handleBtn?: (event: BaseSyntheticEvent) => void;
  isAutoComplete?: boolean;
  alreadyRequested?: number;
  alreadyRequestedText?: string;
  maxRequestAmount?: number;
  maxRequestAmountText?: string;
  subtitlesBalance?: Array<{ title: string; value: number; currency?: string }>;
  children?: ReactNode;
  setValidateMsg: React.Dispatch<React.SetStateAction<string>>;
  validateMsg: string;
}> = ({
  isNumberOnly = false,
  value,
  availableClassName = '',
  tokenName = 'MTOK',
  placeholder = '0.00',
  buttonText,
  name,
  maxValue,
  minValue = 0,
  maxErrorMsg,
  minErrorMsg,
  handleChangeValue,
  handleBtn,
  isDisabled,
  isAutoComplete = true,
  subtitlesBalance,
  children,
  setValidateMsg,
  validateMsg,
}) => {
  const { t } = useTranslation();
  //* maxValue обычно приходит с бэка с задержкой. Поэтому нужно еще раз провалидировать при получении новых данных
  useEffect(() => {
    if (maxValue || maxValue === 0) validateInput(value?.toString() || '');
  }, [value, maxValue]);

  const validateInput = (value: string) => {
    setValidateMsg('');

    if (value === '0' || value === '') return;

    if (maxValue !== undefined && Number(value) > Number(maxValue)) {
      const text =
        maxErrorMsg || `${t('large_input.insufficient')} ${tokenName} ${t('large_input.balance')}`;
      setValidateMsg(text);
    }

    if (minValue !== undefined && parseFloat(value) < minValue) {
      const text = minErrorMsg || `${t('large_input.minimum')} ${minValue} ${tokenName}`;
      setValidateMsg(text);
    }
  };

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (!isNumberOnly) return;
    const value = event.clipboardData.getData('text/plain');
    if (!Number(value) || Number(value) < 0) {
      if (event.preventDefault) event.preventDefault();
      return;
    }
    validateInput(value);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isNumberOnly) return;
    const numberAndDots = /[0-9]|\./;
    const key = event.key;

    if (!numberAndDots.test(key)) if (event.preventDefault) event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.trim();

    if (isNumberOnly) {
      const dotIndex = value.indexOf('.');
      if (dotIndex !== -1 && dotIndex < value.length - 3) {
        value = parseFloat(value).toFixed(2);
      }

      const oneDotRegex = /[.]{1}(?!\d*$)/;

      if (value === '.') {
        value = '';
        event.target.value = value;
      }

      if (oneDotRegex.test(value)) {
        value = event.target.value.replace(oneDotRegex, '');
      }

      if ((value[0] === '0' && value[1] !== '.') || value[0] === '.') {
        value = parseFloat(value).toString();
      }
    }
    handleChangeValue && handleChangeValue(event, value);
  };

  return (
    <Box className={styles.inputBlock}>
      <Box className={styles.inputWrapper}>
        <input
          size={1}
          className={styles.input}
          value={typeof value === 'string' ? value?.substring(0, 20) : value}
          placeholder={placeholder}
          name={name}
          maxLength={isNumberOnly ? 10 : 100}
          onKeyPress={onKeyPress}
          onPaste={onPaste}
          onChange={handleChange}
          disabled={isDisabled}
          autoComplete={isAutoComplete ? 'on' : 'off'}
        />
        <span className={styles.tokenName}>{tokenName}</span>
        {!!buttonText && (
          <Button
            type={EButtonType.FILLED}
            customClass={styles.button}
            disabled={isDisabled}
            onClick={handleBtn}
          >
            {buttonText}
          </Button>
        )}
        <ErrorMessage message={validateMsg} />
      </Box>
      {children}
      {subtitlesBalance?.map((item) => (
        <Box key={item?.title} className={`${styles.available} ${availableClassName}`}>
          <span className={`${styles.available__title} light`}>{item?.title}</span>
          <span>{`${(item.value || 0).toFixed(2)} ${
            item?.currency ? item?.currency : tokenName
          }`}</span>
        </Box>
      ))}
    </Box>
  );
};
