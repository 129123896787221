import { exchangeAbi, useWatchExchangeEvent } from '@app/contracts';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { usePackages } from './usePackages';
import { type Address, Log } from 'viem';
import { ethers } from 'ethers';
import { addEventIfNotExists, getBlockByBlockHash } from '@app/utils/event';
import { useWatchPreviousEvents } from '@app/hooks/useWatchPreviousEvents';
import { packageNameConverter } from '@app/utils/packages';
import { formatEther } from 'viem';

export type Event = {
  time: number;
  eventType: string;
  package: string | undefined;
  tokens: BigInt;
  transactionHash: string;
};

export const usePurchaseHistory = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const { packages, isLoading } = usePackages();
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const { address } = useAccount();
  const [logsBuyPackage, setLogsBuyPackage] = useState<Log[]>();
  // const [logsUpgradePackage, setLogsUpgradePackage] = useState<Log[]>();
  const [logsBuyTokens, setLogsBuyTokens] = useState<Log[]>();

  useEffect(() => {
    if (!address) return;
  });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.EXCHANGE_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.EXCHANGE_ADDRESS! as Address,
    event: 'BuyPackage',
    args: {
      user: address,
    },
    onLogs(logs) {
      setLogsBuyPackage(logs);
    },
  });

  // useWatchPreviousEvents<Log[]>({
  //   fromBlock: process.env.EXCHANGE_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
  //   address: process.env.EXCHANGE_ADDRESS! as Address,
  //   event: 'UpgradePackage',
  //   args: {
  //     user: address,
  //   },
  //   onLogs(logs) {
  //     setLogsUpgradePackage(logs);
  //   },
  // });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.EXCHANGE_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.EXCHANGE_ADDRESS! as Address,
    event: 'BuyTokens',
    args: {
      user: address,
    },
    onLogs(logs) {
      setLogsBuyTokens(logs);
    },
  });

  useWatchExchangeEvent({
    address: process.env.EXCHANGE_ADDRESS! as Address,
    eventName: 'BuyPackage',
    args: {
      user: address,
    },
    poll: true,
    pollingInterval: 1000,
    onLogs(logs: Log[]) {
      setLogsBuyPackage(logs);
    },
    onError(error) {
      setIsHistoryLoading(false);
      console.warn('BuyPackage Error', error);
    },
  });

  // useWatchExchangeEvent({
  //   address: process.env.EXCHANGE_ADDRESS! as Address,
  //   eventName: 'UpgradePackage',
  //   args: {
  //     user: address,
  //   },
  //   poll: true,
  //   pollingInterval: 1000,
  //   onLogs(logs: Log[]) {
  //     setLogsUpgradePackage(logs);
  //   },
  //   onError(error) {
  //     setIsHistoryLoading(false);
  //     console.warn('UpgradePackage Error', error);
  //   },
  // });

  useWatchExchangeEvent({
    address: process.env.EXCHANGE_ADDRESS! as Address,
    eventName: 'BuyTokens',
    args: {
      user: address,
    },
    poll: true,
    pollingInterval: 1000,
    onLogs(logs: Log[]) {
      setLogsBuyTokens(logs);
    },
    onError(error) {
      setIsHistoryLoading(false);
      console.warn('BuyTokens Error', error);
    },
  });

  useEffect(() => {
    setIsHistoryLoading(true);

    if (logsBuyPackage && logsBuyPackage.length > 0) {
      logsBuyPackage.forEach(async function (event: Log) {
        const decodedData = new ethers.Interface(exchangeAbi).parseLog({
          data: event.data,
          topics: [...event?.topics],
        });

        const buyPackagePackageId = decodedData && decodedData.args[2] ? decodedData.args[2].toString() : '0';
        const buyPackagePrevPackageId = decodedData && decodedData.args[3] ? decodedData.args[3].toString() : '0';
        const buyPackageScaValue = decodedData && decodedData.args[7] ? decodedData.args[7].toString() : '0';

        if (decodedData && buyPackagePackageId && buyPackageScaValue) {
          const packageItem = packages.find((p) => Number(buyPackagePackageId) === Number(p.id));
          const block = await getBlockByBlockHash(event.blockHash);

          const newEvent = {
            time: Number(block.timestamp),
            eventType: !!buyPackagePrevPackageId && Number(buyPackagePrevPackageId) > 0 ? 'Upgrade Package' : 'Buy Package',
            package: packageItem ? packageNameConverter(+formatEther(packageItem.size)) : undefined,
            tokens: BigInt(buyPackageScaValue),
            transactionHash: event?.transactionHash as string,
          };
          setEvents((currentEvents) => addEventIfNotExists<Event>(currentEvents, newEvent));
        }
      });
    }

    // if (logsUpgradePackage && logsUpgradePackage.length > 0) {
    //   logsUpgradePackage.forEach(async function (event: Log) {
    //     const decodedData = new ethers.Interface(exchangeAbi).parseLog({
    //       data: event.data,
    //       topics: [...event?.topics],
    //     });
    //
    //     if (decodedData && decodedData?.args[2] && decodedData?.args[6]) {
    //       const packageItem = packages.find((p) => decodedData?.args[2] === p.id);
    //       const block = await getBlockByBlockHash(event.blockHash);
    //
    //       const newEvent = {
    //         time: Number(block.timestamp),
    //         eventType: 'Upgrade Package',
    //         package: packageItem ? packageNameConverter(+formatEther(packageItem.size)) : undefined,
    //         tokens: decodedData?.args[6],
    //         transactionHash: event?.transactionHash as string,
    //       };
    //       setEvents((currentEvents) => addEventIfNotExists<Event>(currentEvents, newEvent));
    //     }
    //   });
    // }

    if (logsBuyTokens && logsBuyTokens.length > 0) {
      logsBuyTokens.forEach(async function (event: Log) {
        const decodedData = new ethers.Interface(exchangeAbi)?.parseLog({
          data: event.data,
          topics: [...event?.topics],
        });

        if (decodedData && decodedData?.args[1]) {
          const block = await getBlockByBlockHash(event.blockHash);

          const newEvent = {
            time: Number(block.timestamp),
            eventType: 'Buy Tokens',
            package: undefined,
            tokens: decodedData?.args[1],
            transactionHash: event?.transactionHash as string,
          };
          setEvents((currentEvents) => addEventIfNotExists<Event>(currentEvents, newEvent));
        }
      });
    }
    setIsHistoryLoading(false);
    // }, [logsBuyPackage, logsUpgradePackage, logsBuyTokens]);
  }, [logsBuyPackage, logsBuyTokens]);

  return { events, isLoading: isLoading || isHistoryLoading };
};
