import { useCallback, useState } from 'react';
import styles from './style.module.scss';
import { Button } from '../../../shared/Button';
import { AgreementLayout } from '../AgreementLayout/AgreementLayout';
import { PartnerAgreementContent } from './PartnerAgreementContent';
import { EButtonType } from '@app/types';
import { useAcceptPartnerAgreement } from '@app/hooks';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

interface IAgreement {
  callback?: () => void;
}

export const PartnerAgreement = ({ callback }: IAgreement) => {
  const { mutate: acceptPartnerAgreement } = useAcceptPartnerAgreement();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);
  const [showPartnerAgre, setShowPartnerAgre] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const handleChangeCheck = useCallback(() => {
    setChecked(!checked);
    setShowError(true);
  }, [checked]);

  const handleOpenPartnerAgreement = useCallback(() => {
    setShowPartnerAgre(true);
  }, []);

  const handleAccept = useCallback(() => {
    acceptPartnerAgreement(checked, {
      onSuccess: () => {
        callback?.();
      },
    });
  }, [acceptPartnerAgreement, checked, callback]);

  return (
    <div className={styles.main}>
      {showPartnerAgre && (
        <AgreementLayout setIsOpen={setShowPartnerAgre}>
          <PartnerAgreementContent />
        </AgreementLayout>
      )}
      {!showPartnerAgre && (
        <>
          <Box mb={2}>
            <Typography variant="body1" mb={2}>
              {t('join_affiliate_programme.dear_client')}
            </Typography>
            <Typography component={'div'} variant="body1" mb={2}>
              <p>{t('join_affiliate_programme.description_1')}</p>
              <p>{t('join_affiliate_programme.description_2')}</p>
              <p>{t('join_affiliate_programme.description_3')}</p>
            </Typography>
            <Typography variant="body1" mb={2}>
              {t('join_affiliate_programme.description_4')}
            </Typography>
            <Typography component={'div'} variant="body1" mb={2}>
              <a href="https://247and.co" target="_blank" rel="noreferrer noopener">
                {t('join_affiliate_programme.link_247')}
              </a>
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography variant="body1" mb={2}>
              {t('join_affiliate_programme.link_partner')}
            </Typography>
            {/*{!isKycConfirmed && (*/}
            {/*  <p>*/}
            {/*    <Link href="/settings">Fill in and verify personal data</Link>*/}
            {/*  </p>*/}
            {/*)}*/}
            <div className={styles.checkBoxBlock}>
              <input
                className={styles.checkBox}
                type={'checkbox'}
                checked={checked}
                onChange={handleChangeCheck}
              />
              <p className={styles.partnerAgreementText} onClick={handleOpenPartnerAgreement}>
                <a className="primary">{t('join_affiliate_programme.accept_partnership')}</a>
              </p>
            </div>
          </Box>

          <Box mb={2}>
            <Typography variant="body1" mb={2}>
              {t('join_affiliate_programme.after_accepting')}
            </Typography>
          </Box>

          <Box width={'100%'} display={'flex'} flexDirection={'column'}>
            <Button
              type={EButtonType.FILLED}
              // disabled={!checked || !isKycConfirmed}
              disabled={!checked}
              customClass={styles.button}
              onClick={handleAccept}
            >
              {t('join_affiliate_programme.accept_proceed')}
            </Button>
            <Box className={styles.boxError}>
              {/*{showError && !isKycConfirmed && (*/}
              {/*  <p className={styles.errorInfo}>Fill in and verify personal data</p>*/}
              {/*)}*/}
              {showError && !checked && (
                <p className={styles.errorInfo}>
                  {t('join_affiliate_programme.accept_partnership')}
                </p>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};
