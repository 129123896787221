import { IJsonRpcError, IMMError, IServerError, IUnclearMMError, IUnknownError } from '../../types/errors';
import axios, { AxiosError, AxiosResponse } from 'axios';

const isErrorObject = (error: unknown): error is object => !!(
  error &&
  typeof error === 'object'
);

export const isServerError = (error: unknown): error is IServerError => (
  isErrorObject(error) &&
  'status' in error
);

export const isMMError = (error: unknown): error is IMMError => (
  isErrorObject(error) &&
  'code' in error
);

export const isJsonRpcError = (error: unknown): error is IJsonRpcError => (
  isErrorObject(error) &&
  'code' in error &&
  'data' in error &&
  'message' in error
);

// todo - проверить работу на практике
// * Источник идеи - https://forum.moralis.io/t/solved-catching-error-from-metamask-to-display/17521/5
export const isUnclearMMError = (error: unknown): error is IUnclearMMError => (
  isErrorObject(error) &&
  'error' in error
);

export const isAxiosError = (error: unknown): error is AxiosError => !!axios.isAxiosError(error);

export const isAxiosResponse = (error: unknown): error is AxiosResponse => (
  isErrorObject(error) &&
  'status' in error &&
  'statusText' in error
);
/**
 *  Обычно ошибки от кошельков
 * @param error
 */
export const hasOnlyMessage = (error: unknown): error is IUnknownError => (
  !isAxiosError(error) &&
  !isMMError(error) &&
  !isJsonRpcError(error) &&
  isErrorObject(error) &&
  'message' in error
);
