import { useEffect, useState } from 'react';
import { type Address, formatEther } from 'viem';
import { useUser } from '@app/hooks/useUser';
import { useReadBonusEvaluatorGetInvestorBonusInsuranceRate } from '@app/contracts';

export const useSpecialDiscount = (fundType: number = 1) => {
  const [packageSize, setPackageSize] = useState<number>(0);
  const { user } = useUser();

  const { data, refetch } = useReadBonusEvaluatorGetInvestorBonusInsuranceRate({
    address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
    args: [user?.package?.id ?? BigInt(0), fundType],
    query: {
      enabled: !!fundType && user,
    },
  });

  useEffect(() => {
    if (!user) return;
    const packageSize = +formatEther(user?.package?.value?.(undefined) ?? BigInt(0));
    setPackageSize(packageSize);
  }, [user]);

  useEffect(() => {
    refetch();
  }, [fundType,user]);

  return { packageSize, data, refetch };
};
