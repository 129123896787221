import { StatusCodes } from '@app/constants';
import { IError, IServerError } from '@app/types/errors';
import { hasOnlyMessage, isMMError, isServerError, isJsonRpcError } from '@app/helpers';

interface IParseErrorParams {
  error: IError,
  /**
   * customize server error depending on status
   * 1) take original message and change for user
   * 2) return original message without change
   */
  parseServerError?: (error: IServerError) => string | JSX.Element;
}

export const parseError = ({ error, parseServerError } : IParseErrorParams): string | JSX.Element => {
  if (!error) {
    console.error('Error in parseError - no error provided');
    return '';
  }

  if (isServerError(error)) {
    const { status, message } = error.data;
    switch (status) {
    case StatusCodes.UNAUTHORIZED:
      return 'Unauthorized users are not allowed to perform this operation';
    case StatusCodes.INTERNAL_SERVER_ERROR:
      return 'Internal server error';
    default:
      return parseServerError ? parseServerError(error) : message;
    }
  }

  if (hasOnlyMessage(error)) {
    return error.message;
  }

  return '';
};

export default parseError;
