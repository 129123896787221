export enum ENativeBtnType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

export enum EButtonType {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  DISABLED = 'disabled',
}

export enum ELinkTarget {
  BLANK = '_blank',
  SELF = '_self',
  PARENT = '_parent',
  TOP = '_top',
}
