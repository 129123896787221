import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { MainLayout } from '../layouts/MainLayout'
import { HELP_PAGE, MAIN_PAGE, NOT_FOUND_PAGE } from '../../routesConfig'
import { useProfile } from '@app/hooks'
import { DocLayout } from '@app/layouts/DocLayout'

type Props = {
  children: React.ReactNode
}

export default function RouterGuard({ children }: Props) {
  const router = useRouter()
  const { isLoggedIn } = useProfile()

  const url = router.asPath
  const currentPath = url.split('?')[0]
  const [history] = useState([MAIN_PAGE])

  useEffect(() => {
    if (isLoggedIn && history.length > 1) {
      router.push(history[history.length - 1])
      history.pop()
    }
    // if /404 page was the first page - redirect to main page
    if (history.length === 1 && currentPath === NOT_FOUND_PAGE && !isLoggedIn) {
      router.push(MAIN_PAGE)
    }
  }, [currentPath, isLoggedIn, history, router])

  if (router.pathname === HELP_PAGE) {
    return <DocLayout>{children}</DocLayout>
  }

  return <MainLayout>{children}</MainLayout>
}
