import Link from 'next/link'
import React from 'react'
import styles from './style.module.scss'
import { EButtonType, ELinkTarget, ENativeBtnType } from '@app/types'
import { LoadingButton } from '@mui/lab'

type Props = {
  disabled?: boolean
  nativeBtnType?: ENativeBtnType
  type: EButtonType
  linkTarget?: ELinkTarget
  href?: string | object
  customStyles?: React.CSSProperties
  customClass?: string
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  loadingPosition?: 'start' | 'end' | 'center'
  loading?: boolean
  loadingIndicator?: React.ReactNode
}

export const Button = ({
  disabled = false,
  nativeBtnType = ENativeBtnType.BUTTON,
  type,
  linkTarget = ELinkTarget.SELF,
  href = '',
  customStyles,
  customClass,
  children,
  onClick,
  loadingPosition,
  loading,
  loadingIndicator,
}: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (type === EButtonType.DISABLED || disabled) {
      return
    }
    onClick && onClick(event)
  }

  if (!!href) {
    return (
      <Link href={href} className={`${styles.button} ${styles[type]} ${customClass} ${
          disabled ? styles.disabled : ''
      }`}
            style={customStyles}
            target={linkTarget}
            onClick={handleClick}>{children}
      </Link>
    )
  }

  if (loadingPosition || loading || loadingIndicator) {
    return (
      <LoadingButton
        loadingPosition={loadingPosition}
        loading={loading}
        loadingIndicator={loadingIndicator}
        type={nativeBtnType}
        className={`${styles.button} ${styles[type]} ${customClass}`}
        style={customStyles}
        disabled={disabled}
        onClick={handleClick}
      >
        {children}
      </LoadingButton>
    )
  }

  return (
    <button
      type={nativeBtnType}
      className={`${styles.button} ${styles[type]} ${customClass}`}
      style={customStyles}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}
