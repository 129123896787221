import styles from './style.module.scss';

interface IProps {
  message: string | undefined,
  className?: string,
  small?: boolean,
}

export const ErrorMessage = ({ message, className, small = false }: IProps) => {
  return (
    <div className={`${styles.errorWrapper} ${className || ''}`}>
      <div className={`${styles.errorBlock} ${message && styles.visible} ${small && styles.small}`}>{message}</div>
    </div>
  );
};
