import React, { useEffect } from 'react';
import { useModal } from '@app/hooks';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './style.module.scss';
import { WalletNamesEnum } from '@app/utils/wallets';
import { useTranslation } from 'next-i18next';

export const InstallWalletModal = ({
  walletName = WalletNamesEnum.MetaMask,
}: {
  walletName?: WalletNamesEnum;
}) => {
  const { setIsClosable } = useModal();
  const { t } = useTranslation();

  useEffect(() => {
    setIsClosable(true);
  }, [setIsClosable]);

  const generateLinkToInstall = () => {
    switch (walletName) {
      case WalletNamesEnum.TrustWallet:
        return 'https://trustwallet.com/';
      case WalletNamesEnum.MetaMask:
        return 'https://metamask.io/download.html';
      default:
        return 'https://metamask.io/download.html';
    }
  };

  return (
    <div className={styles.modal}>
      <h2 className="white">
        {walletName} {t('install_wallet_modal.browser_extension')}
      </h2>
      <p className={classNames(styles.modalText, 'light')}>
        {t('install_wallet_modal.see_installation')}&nbsp;
        <Link href={generateLinkToInstall()} target={'_blank'}>
          {t('install_wallet_modal.official_website')}
        </Link>
      </p>
    </div>
  );
};
