import React, { createContext } from 'react';
import { IFundsAnalysisContext } from './fundsAnalysisContextTypes';
import { fundsAnalysisService } from '@app/services/api/fundsAnalysis';
import { useProfile } from '@app/hooks';

export const FundsAnalysisContext = createContext<IFundsAnalysisContext>(
  {} as IFundsAnalysisContext
);

type Props = {
  children: React.ReactNode;
};

export const FundsAnalysisContextProvider = ({ children }: Props) => {
  const { isLoggedIn } = useProfile();

  const getUpcomingFunds = async (page: number, take: number) => {
    try {
      const response = await fundsAnalysisService.getUpcoming(
        page,
        take,
        isLoggedIn
      );
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  const getMutualFundsParticipation = async (page: number, take: number) => {
    try {
      const response = await fundsAnalysisService.getMutual(page, take);
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  const getUnstakedNonCapturedProfit = async (page: number, take: number) => {
    try {
      const response = await fundsAnalysisService.getUnstaked(page, take);
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  const getFundById = async (id: string) => {
    try {
      const response = await fundsAnalysisService.getFundById(id);
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FundsAnalysisContext.Provider
      value={{
        getUpcomingFunds,
        getMutualFundsParticipation,
        getUnstakedNonCapturedProfit,
        getFundById,
      }}
    >
      {children}
    </FundsAnalysisContext.Provider>
  );
};

export default FundsAnalysisContext;
