/* eslint-disable react/no-unescaped-entities */
import styles from '../../../Agreements/style.module.scss';
import classnames from 'classnames';

export const PartnerAgreementContent = () => {
  return (
    <div className={classnames(styles.wrapper, 'with-scroll')}>
      <section className={styles.section}>
        <p>
          PARTNERSHIP AGREEMENT <br /> for consulting and marketing services within the Company
          247and Co. K/S
        </p>
        <p>
          #1.000.12.10/2023-247ANDCO <br /> 12/10/2023
        </p>
        <p>Denmark</p>
        <p> </p>
        <p>
          <strong>Preamble</strong>
        </p>
        <p>
          This Partnership Agreement defines the terms of cooperation between the Company 247and Co.
          K/S registered in the Kingdom of Denmark (hereinafter referred to as the "Company"), on
          the one hand, and any individual or legal entity (with the exception of persons under 18
          full years of age, and persons without citizenship) accepting the terms of this Agreement
          (hereinafter referred to as the "Partner"), on the other hand, jointly the "Parties" and
          separately the "party".
        </p>
        <p>
          This Agreement has the legal form of a Firm Offer, that is, a formal offer by the Company
          to an unlimited number of persons to accept the services provided by the Company,
          regulated by this and other agreements and contracts provided by the Company. This means
          that the Company fully and unconditionally assumes all obligations arising from this
          Agreement. On the other hand, a person who decides to use any of the services specified in
          this Agreement is recognized as an acceptor, which means that he assumes all obligations
          relating to the Partner. The Agreement comes into force from the moment the Partner
          actually starts using any service of the Company. For example, from the moment of
          registration on the Company's website , or from the moment of opening an account in an
          electronic system owned by the Company.
        </p>
        <p>
          <strong>Glossary:</strong>
        </p>
        <p>
          <em>User</em> — any person registering  or the charge registered on the Company's
          corporate website (<a href="https://247and.co">https://247and.co</a>);
        </p>
        <p>
          <em>Client </em>- user who made purchase of an investment package on the corporate website
          of the Company (<a href="https://247and.co">https://247and.co</a>) and/or on the official
          websites of the partners of the Company (
          <a href="https://sca-innovative.com">https://sca-innovative.com</a>), but who has not
          accepted this Agreement, which expresses the unwillingness of the Client of the Company
          participate in the partnership structure and the Marketing Plan of the Company (become a
          Partner ;
        </p>
        <p>
          <em>Partner </em> — user who made purchasing{' '}
          <a href="https://247and.co">https://247and.co</a>) and / or on the{' '}
          <a href="https://247and.co">official </a>
          <a href="https://247and.co">websites </a>
          <a href="https://247and.co">of </a>
          <a href="https://247and.co">the </a>Company 's partners (
          <a href="https://sca-innovative.com">https://sca-innovative.com</a>) and agreeing to this
          Agreement ;
        </p>
        <p>
          <em>Package </em>- a product of the Company that provides access to the services of the
          Company and its official partners;
        </p>
        <p>
          <em>Marketing plan of the Company (</em>
          <em>Marketing</em> <em>plan </em>
          <em>) </em>- an official document on the Company's corporate website , which sets out the
          principles and details of the work of the Company's marketing and partnership structure;
        </p>
        <p>
          <em>Partner Qualification</em> <em>or Partner Status-level</em>
          <em> (</em>
          <em>Partner</em>
          <em>Qualification</em>
          <em>) </em>is the position of a Partner in the general hierarchy of the Company's
          Partners, awarded automatically by the Company for fulfilling the qualification
          requirements in accordance with the Company's Marketing Plan. Information about the
          Partner 's Qualification is displayed in the Partner 's personal account on the Company's
          corporate website (<a href="https://247and.co">https://247and.co</a>);
        </p>
        <p>
          <em>Partner's personal account </em>- the Partner's account on the Company's corporate
          website (<a href="https://247and.co">https://247and.co</a>). Access to the personal
          account is carried out through the Partner's personal account on the Company's corporate
          website.
        </p>
        <p>
          <em>Commission or Bonuses</em> <em>remuneration </em>
          <em>(Commission)</em> — the Partner's income received as a result of the fulfillment of
          the qualification and key indicators of the Company's Marketing Plan;
        </p>
        <p>
          <em>Marketing structure (</em>
          <em>Marketing</em> <em>Structure</em>
          <em>) </em>- the structure of new Clients and Partners who have registered on the
          corporate website of the Company and purchased packages of the Company and / or official
          partners of the Company using the Partner's referral link;
        </p>
        <p>
          <em>Request</em> <em>on</em> <em>conclusion</em> <em>funds from the personal account </em>
          <em>(Withdrawal request)</em> — the Partner's order to withdraw funds from an account in
          the system to an external account (in an electronic payment system or a cryptocurrency
          payment system);
        </p>
        <p>
          <em>User </em>
          <em>'s personal data verification</em> — the procedure for identifying and establishing
          the correctness of personal data provided by the user.
        </p>
        <p> </p>
        <ol>
          <li>
            <p>
              <strong> General Provisions</strong>
            </p>
            <ul>
              <li>
                This agreement is a Firm Offer. A firm offer (not to be confused with a public
                offer) is an offer addressed to a strictly defined (limited) circle of persons who
                are the Company's Clients in accordance with the Client Agreement, valid only for
                the period that such persons remain Clients and Partners of the Company, and
                associated with the acceptance additional rights and obligations related to
                partnership activities.
              </li>
              <li>
                This Agreement shall enter into force immediately upon acceptance by the Partner of
                the terms of the Affiliate Program on the Company's corporate website .
              </li>
              <li>
                The Agreement is concluded by the Parties for an indefinite period as long as the
                Parties carry out activities related to the fulfillment of the terms of this
                Agreement.
              </li>
              <li>
                From the moment of acceptance (acceptance) of this Agreement, any person who has
                made such an acceptance is the Acceptor of this offer, from which the following
                directly and clearly follows:
              </li>
              <li>
                Acceptor legally becomes Partner Companies , and at this , by relation To corporate
                the Company 's website (<a href="https://247and.co">https://247and.co</a>
                <a href="https://sca-innovative.com">
                  ) , to the corporate website of the Company's official partners
                  (https://sca-innovative.com), products
                </a>{' '}
                And services Companies - remains the User (Client);
              </li>
              <li>
                Agreement submitted V form of this Firm Offer , is considered prisoners . jointly
                Partner And Company are called Parties agreements And accept on myself All
                obligations of this Agreement ;
              </li>
              <li>
                Acceptor directly and explicitly confirms that he is familiar with this Agreement
                and unconditionally accepts all clauses of the Agreement;
              </li>
              <li>
                Acceptor counts notified , and directly confirms what For maintaining Partner
                Qualification , he is obliged to maintain investment activity (that is, at any given
                moment to have active investments in the amount that corresponds to his current
                level of Partner Qualification);
              </li>
              <li>
                Partner confirms that is V compliance with the local legislation of the country of
                permanent residence by a capable and adult person, and enters into this agreement
                voluntarily, at his own meaningful desire, having received financial advice;
              </li>
              <li>
                Partner notified O possible risks , and Also about responsibility behind default and
                / or improper performance provisions And conditions of this Agreement ;
              </li>
              <li>Parties agree that this agreement comes into full legal force;</li>
              <li>
                Partner accepts on myself obligations By conscientious , honest And open
                administered business and comply with all provisions of this Agreement. In
                particular, the Partner undertakes not to build fictitious structures (“staking”)
                and not to carry out actions to distribute investment funds to lower levels of the
                structure (“distribution”) and not to conduct other activities that are detrimental
                to the financial performance of the Company;
              </li>
              <li>
                Parties agree count electronic And paper ( "hard" ) copies present agreements equal
                By significance .
              </li>
              <li>
                In accordance with this Agreement, the Company, for a fee, authorizes the Partner to
                perform marketing actions to promote the Company's services on the market and
                provide consulting services to the Company's Clients through various communication
                channels, using the Company's brand and the brands of its official partners.
              </li>
              <li>For a fee and on its own behalf Partner:</li>
              <li>
                carries out marketing activities to attract new Clients and Partners to the Company
                (specify the difference between a Client and a Partner);
              </li>
              <li>
                interacts with Clients and Partners and organizes a communication channel for prompt
                feedback from Clients and Partners;
              </li>
              <li>advises Clients and Partners attracted to the Company;</li>
              <li>
                trains and assists in every possible way during registration and in the further work
                of Clients and Partners with the Company as an independent freelance consultant.
              </li>
              <li>
                The Partner agrees and confirms that the Clients attracted by him can become
                Partners of the Company.
              </li>
              <li>
                The exclusive rights of the Company in accordance with this Agreement include:
              </li>
              <li>
                All copyright And related rights on advertising And promotional materials (
                including texts , graphics And video ) provided to the Partner as part of marketing
                support;
              </li>
              <li>
                right definitions key directions advertising And marketing politics , and Also ways
                implementation activities related With promotion products And services Companies V
                framework partnership of their relationship.
              </li>
              <li>
                The exclusive rights of the Partner in accordance with this Agreement include:
              </li>
              <li>
                right protrude V roles official partner Companies at carrying out negotiations and /
                or public speeches , and also , in all others cases directly arising from
                partnerships;
              </li>
              <li>
                right on one's own decide tactical And operational questions related With
                partnership activities ;
              </li>
              <li>
                right nominate offers V address Companies associated With promotion products And
                services Companies and / or organization partnership interactions . Proposals should
                be sent to the Company's official corporate mail:{' '}
                <a href="mailto:direct@247and.co">direct</a>
                <a href="mailto:direct@247and.co">@247</a>
                <a href="mailto:direct@247and.co">and</a>
                <a href="mailto:direct@247and.co">.</a>
                <a href="mailto:direct@247and.co">co</a>.
              </li>
              <li>
                The legal status of the Partner in accordance with this Agreement is determined by
                the following main provisions:
              </li>
              <li>Partner valid from his faces And V their interests ;</li>
              <li>
                Partner Not is full-time employee Company and/or official partners of the Company
                and/or Affiliated legal entities of the Company And interacts With her only V okay
                And under the terms of this Agreement;
              </li>
              <li>
                The Partner does not have the right to make official statements on behalf of the
                Company;
              </li>
              <li>
                Partner bears responsibility only behind their actions , and Not answers behind
                actions Companies ;
              </li>
              <li>
                The Partner has the right to act as an independent freelance consultant for the
                purposes of information support for Clients and other Partners included V
                composition of its structure . At the same time, he is obliged to refer to official
                sources of information through which the Company publishes important information,
                and does not have the right to change or distort this information on his own;
              </li>
              <li>
                IN in order to comply with the requirements of international legislation aimed at
                combating the legalization (“laundering”) of funds and the financing of terrorism,
                the Partner does not accept funds in the form of cash (neither in his favor, nor in
                favor of the Company and / or official partners of the Company and / or Affiliated
                Legal persons of the Company ), but has the right to provide consulting and
                information support for Clients and Partners who wish to transfer funds to their own
                accounts (in their own interests and at their expense).
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong> Services of the Company</strong>
            </p>
            <ul>
              <li>
                The Company specializes in providing Clients and Partners with services for
                investing in financial products, including insurance, consulting support and
                training for Clients and Partners, but not limited to them.
              </li>
              <li>
                Access to the Company's services is carried out as part of the purchase of the
                Company's packages.
              </li>
              <li>
                The purchase of packages may also mean access to additional services of the Company,
                which may be stated on the Company's website.
              </li>
              <li>
                As a result of its activities, the Partner can obtain the Partner's Qualification in
                the Company's partner structure in accordance with the Company's Marketing Plan.
              </li>
            </ul>
          </li>

          <li>
            <p>
              <strong> Rights and obligations of the Partner</strong>
            </p>

            <ul>
              <li>
                During the entire term of this Agreement, the Partner undertakes to comply with the
                requirements of the laws of the jurisdiction in which he is located and within the
                framework of which he carries out activities related to the subject of this
                Agreement.
              </li>
              <li>
                The Partner is entitled to receive remuneration from the Company in accordance with
                the terms of this Agreement and in accordance with the Company's Marketing Plan.
              </li>
              <li>
                A User, Partner or Client who, for any reason, does not have a single purchased
                package provided by the Company on their balance sheet, temporarily loses the right
                to receive remuneration from the Company until the package is purchased, after which
                the accrual of remuneration is resumed in full mode. The Partner is obliged from the
                moment of signing this agreement and from the moment of the last personal activity
                on attraction of clients to the first line within two calendar months to demonstrate
                personal activity on attraction of new clients. Non-fulfillment or ignoring of this
                standard by the Partner entails imposition of restrictions on the part of the
                company on withdrawal of commissions and possible withdrawal of funds in favor of
                the company until the partner is not active within a certain period of time in those
                minimum volumes that the company will set.
              </li>
              <li>
                Carrying out activities related to the subject of the Agreement, the Partner has the
                right to receive consulting and information support from the Company's specialists.
                Consulting and information support is carried out in text form in the form of a chat
                with support service employees , as well as in the form of an e-mail exchange with
                the participation of the Company's official corporate mail in the domain zone “@247
                and . co ".
              </li>
              <li>
                The Partner undertakes to carry out actions aimed at attracting new Clients and
                Partners to the Company.
              </li>
              <li>
                The Partner undertakes, in the form of independent financial advice, to explain to
                attracted Clients and Partners the nature of any risks that may arise from the
                moment of actual acceptance of the terms of this Agreement and other agreements and
                contracts signed with the Company and / or official partners of the Company and / or
                Affiliated legal entities of the Company .
              </li>
              <li>
                The Partner undertakes on his own and at his own expense to bear all the costs
                associated with the implementation of marketing, consulting, training and other
                activities aimed at attracting new Clients and Partners to the Company, with the
                exception of official events, seminars, corporate training conducted by the forces
                and means of the Company and /or official partners of the Company and/or Affiliated
                legal entities of the Company .
              </li>
              <li>
                The Partner does not have the right to carry out any actions in the Personal Account
                of another Partner or Client and/or on behalf of another Partner or Client.
              </li>
              <li>
                Partner of 247andCo. K/S agrees and undertakes not to engage in similar activities
                in competitive Companies or projects, to refrain from advertising, providing
                consultations and other activities in the interests of competitive Companies and
                projects.
              </li>
            </ul>
          </li>

          <li>
            <p>
              <strong> Rights and obligations of the Company.</strong>
            </p>

            <ul>
              <li>
                The Company exercises control over the activities of the Partner or the Client
                within the framework of the obligations assigned to them by this Agreement. The
                Company has the right to temporarily restrict the access of the Partner or the
                Client to the options of the personal account if the Partner or the Client has
                violated this Agreement in any of its clauses.
              </li>
              <li>
                The Company is obliged, in accordance with the terms of this Agreement and the
                Marketing Plan, to pay a commission to the Partner.
              </li>
              <li>
                The Company has the right to make changes to this Agreement for objective reasons at
                any time when such changes are deemed necessary. The Company undertakes to notify
                Clients and Partners within at least 5 (five) business days of planned changes to
                this Agreement. Partners of the Company receive such notification to the e-mail
                address specified during registration or by means of an information message on the
                corporate website of the Company.
              </li>
              <li>
                The official source of information about the Company is the Company's corporate
                website located at <a href="https://247and.co">https://247 </a>
                <a href="https://247and.co">and </a>
                <a href="https://247and.co">.co</a>. In all cases related to information support,
                this source should be considered as a priority. The Company is not responsible for
                the content and accuracy of information published in other sources, including, but
                not limited to, the mass media on the Internet. It should be borne in mind that
                publications (of any kind) that are not official releases on the Company's corporate
                website reflect only the private (personal) point of view of the authors of such
                publications, and may be unreliable.
              </li>
              <li>
                This Agreement may be terminated by the Company unilaterally with the application of
                restrictions on the payment of remuneration in relation to Partners who allow the
                publication (or publicly express) false information that discredits the image and
                business reputation of the Company and / or official partners of the Company and /
                or Affiliated legal entities of the Company .
              </li>
            </ul>
          </li>

          <li>
            <p>
              <strong> Marketing plan.</strong>
            </p>

            <ul>
              <li>
                The Company's marketing plan is a plan of rewards accrued to the Partner on his
                partner account for attracting new Partners to use the Company's products and
                services. Rewards are accrued in the form of affiliate commissions based on the
                actual use of products and services by invited Clients and Partners. The
                compensation plan is based on the principles of multi-level marketing. The Company
                publishes the Marketing Plan on the corporate website{' '}
                <a href="https://247and.co">https://247and.co</a> .
              </li>
              <li>
                The Partner's qualification must not be lower than (that is, higher or equal to) the
                qualification of any of the Partners who are in the first (nearest) line of the
                Partner's marketing structure.
              </li>
            </ul>
          </li>

          <li>
            <p>
              <strong> Order of payments.</strong>
            </p>

            <ul>
              <li>
                All payments are made only during business hours. Depositing and debiting funds from
                the balance of the Client and the Partner is carried out during working hours by the
                Company's payments department, namely: from 09:00 to 18:00 UTC +1 on working days
                from Monday to Friday.
              </li>
              <li>
                The funds on the Partner's Personal Account can be withdrawn by him at any time
                without any restrictions, but within the framework of the withdrawal rules
                established by the Company and valid for all Clients and Partners of the Company.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong> Privacy</strong>
            </p>

            <ul>
              <li>
                In accordance with the terms of this Agreement, the Parties undertake not to
                disclose confidential information that becomes known to the Parties in the course of
                the implementation of the relevant relations. Such information, in particular,
                includes the personal (personal) data of the Partner, the Trade Secret of the
                Partner and / or the Company and / or official partners of the Company and / or
                Affiliated legal entities of the Company, as well as other specific information that
                should not become at the moment are not publicly available information.
              </li>
            </ul>
          </li>

          <li>
            <p>
              <strong> Additional provisions</strong>
            </p>

            <ul>
              <li>
                The Partner acquires the right to represent the Company at negotiations and public
                speaking, including negotiations and public speaking carried out by the Partner
                personally, as well as through the media, social networks, blogs, print media,
                conferences, etc. At the same time, the Partner is aware of and is ready to comply
                with high standards of business and personal ethics, taking into account that one of
                the key priorities for the Partner is to maintain the image and business reputation
                of the Company and/or official partners of the Company and/or Affiliated legal
                entities of the Company .
              </li>
              <li>
                In his speeches and statements (both oral and written), the Partner undertakes to
                adhere to a businesslike, correct style, to avoid discrimination on any grounds,
                political appeals, calls for violence, extremism or other actions unacceptable from
                the point of view of public and business ethics.
              </li>
              <li>
                In cases of describing the activities of the Company, the Partner undertakes to
                refer to the official sources of the Company and / or official partners of the
                Company and / or Affiliated legal entities of the Company . Reference to other
                unconfirmed sources is prohibited. The Partner must operate only with real facts and
                present information accurately, completely, truthfully, taking into account both his
                personal business interests and the interests of the Company and / or official
                partners of the Company and / or Affiliated legal entities of the Company .
              </li>
              <li>
                The Partner undertakes to respect the interests of other Partners of the Company,
                taking into account that the competition between Partners must be fair, and each
                Partner must be able to act on an equal footing.
              </li>
              <li>
                The Partner undertakes to do everything possible to create a positive image and
                maintain a high business reputation of the Company and/or official partners of the
                Company and/or Affiliated legal entities of the Company .
              </li>
              <li>
                The activities of the Partner in relation to the Company must be honest, open and
                transparent. Any actions that may cause direct or indirect damage (or lead to
                financial losses) of the Company and / or official partners of the Company and / or
                Affiliated legal entities of the Company are prohibited .
              </li>
              <li>
                The Company highly appreciates the activities of Partners and makes every effort to
                provide comprehensive assistance and support in the course of partnership activities
                (including, but not limited to, advertising, marketing, methodological and
                methodological, informational and technical support). The Company's Marketing Plan
                is designed in such a way as to provide the highest rewards for Partners, however,
                the Partner understands and accepts that the Company's Marketing Plan is based on
                honest and open interaction between the Company and the Partner.
              </li>
              <li>
                The Partner is prohibited from any actions aimed at misleading the Company about the
                real state of affairs, or causing harm (damage) to the Company and / or its
                software, including, but not limited to the following actions (or chains of
                actions):
              </li>
              <li>
                construction "fictitious" structures ( organizations ) consisting from real persons
                , straight existing in the interests of the Partner, and not in their own interests,
                or from non-existent persons using the falsification of personal data of such
                persons;
              </li>
              <li>
                "distribution" monetary funds Partner between Partners and / or lower-level Clients
                for the purpose of directly obtaining fraudulent benefits through artificially
                inflated amounts of affiliate commissions. With regard to Partner accounts, this
                applies to absolutely all Partners. In relation to client accounts, each Partner is
                obliged to notify all Clients he refers about the restrictions related to the
                "distribution" and the creation of fictitious financial flows;
              </li>
              <li>
                any artificial manipulation ( including "stacking " but Not limited to this ) with
                partner organization, leading to an unreasonable change in the amount (volume) of
                commissions paid to the Partner;
              </li>
              <li>
                any actions that can make it difficult or do impossible stable work hardware And
                program tools used by the Company to carry out its activities, as well as provided
                to Clients and Partners as products and services (including the corporate website of
                the company, software modules of automated investment programs, etc.) ;
              </li>
              <li>
                l any actions that can hinder or make it impossible for the stable operation of
                hardware and software of third-party organizations that the Company uses as a client
                (including, but not limited to, accounts in paid and free Internet services);
              </li>
              <li>
                attempts unauthorized access To data related To categories commercial secrets
                companies , and Also other data And information characterized by How confidential V
                framework present And Client agreements including but Not limited to information
                about the Company's financial flows, personal data of the Company's Clients and
                Partners, etc.;
              </li>
              <li>
                disclosure confidential information that may become known Partner V progress
                implementation partnership activities ( to number such information includes personal
                data of Clients and Partners who are members of the Partner’s organization, about
                which he (Partner) can receive such information, as well as information about any
                financial transactions of the Partner himself and any of the Clients and / or
                Partners who are members of his structure (organization );
              </li>
              <li>
                spreading information , unreasonable discrediting image And business reputation
                Company and/or official partners of the Company and/or Affiliated legal entities of
                the Company or any materials , not relevant universally recognized norms business
                ethics , and Also information capable of undermine , hinder or make impossible the
                work of other Partners of the Company.
              </li>
              <li>
                engaging in similar activities in competitive Companies or projects, advertising,
                providing consultations and other activities in the interests of competitive
                Companies and projects.
              </li>
              <li>
                In case of violation of the terms and conditions of this section of the Agreement,
                the Company has the right to unilaterally suspend and / or completely terminate this
                Agreement, of which the Partner will be directly and explicitly notified. If the
                actions of the Partner caused damage or led to direct financial losses of the
                Company, then such Partner may be subject to sanctions aimed at compensating for
                such damage and / or losses. In particular, the accrual and payment of partner
                commissions may be suspended (or completely terminated), and part of the
                remunerations accrued earlier may also be written off if it is established that they
                were accrued as a result of the Partner's manipulations.
              </li>
              <li>
                In order to monitor compliance with the terms and conditions of this section of the
                Agreement, the Company reserves the right to carry out inspections and conduct
                internal investigations designed to establish the facts. For the period of relevant
                checks and / or internal investigations, the payment of partner rewards may be
                suspended.
              </li>
              <li>
                The Partner's operations carried out in violation of the terms and conditions of
                this section of the Agreement may be canceled, and the profit received through
                manipulations written off without the right of recovery.
              </li>
              <li>
                The Partner accepts the fact that by his actions he can cause damage (or create
                circumstances leading to direct financial losses) for the immediate superior
                Partner. The Partner, by signing this Agreement, undertakes not to take actions that
                lead to financial damage or damage to the performance indicators for the immediate
                superior Partner.
              </li>
              <li>
                The Company strives to build honest, open, transparent and long-term partnerships,
                but is obliged to protect its interests if the actions of a Partner can lead to
                damage or loss for the Company or higher Partners.
              </li>
              <li>
                In the event that the funds available at the time of detection of violations on the
                Partner's accounts are not enough to pay off the damage caused to the Company (or
                compensate for direct financial losses), the Company reserves the right to seek
                repayment of the damage (or compensation for losses) by all legal means available,
                including the transfer cases (submission of relevant applications) to law
                enforcement and judicial authorities.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Settlement of disputes</strong>
            </p>
            <ul>
              <li>
                All disputes or claims arising from the provisions of this Agreement, the Parties
                will try to resolve through correspondence or negotiations. The Client's claim may
                be accepted by the Company for consideration only in writing and no later than three
                calendar days from the moment the dispute arises.
              </li>
              <li>
                The term for consideration of a claim by the Company is no more than 15 (fifteen)
                business days. If the claim is recognized as justified, the dispute is settled
                solely in the form of a compensation payment accrued to the Partner's Personal
                Account no later than 3 (three) business days following the day the decision was
                made.
              </li>
              <li>
                If the Partner had the intention to perform some action, but did not do it for any
                reason, the Company does not compensate the Partner for the profit lost as a result
                of this or the losses incurred as a result of this and does not compensate the
                Partner for moral damage.
              </li>
              <li>
                In the event of a dispute not expressly stipulated in this Agreement, the final
                decision on the claim is made by the Company, based on generally accepted market
                practice and ideas about the fair settlement of the dispute.
              </li>
              <li>
                If the Parties are unable to resolve the disputed situation through negotiations,
                disputes are resolved in court, established by the legislation at the place of
                registration of the Company.
              </li>
              <li>
                When contacting the support service via live chat or e-mail, obscene language,
                statements containing deliberately false information and / or discrediting the
                business reputation and image of the company are prohibited. If this prohibition is
                violated by the Client and/or the Partner, the Company has the right to unilaterally
                terminate all contractual relations without paying interest and/or commissions with
                compensation for moral damage.
              </li>
            </ul>
          </li>
        </ol>
      </section>
    </div>
  );
};
