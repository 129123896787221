import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Socket } from 'socket.io-client';

export const useSocket = (): { socket: Socket | null } => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const socketInitializer = async () => {
      await fetch('/api/socket');
      const newSocket = io();
      setSocket(newSocket);

      newSocket.on('connect', () => {
        console.log('connected');
      });

      return () => {
        if (newSocket) {
          newSocket.disconnect();
        }
      };
    };

    socketInitializer();
  }, []);

  return { socket };
};
