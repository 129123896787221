import { useAuthtoken } from '@app/stores/auth/store';
import { QueryFilters, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { axios } from '@app/services/httpClient/axios';
import { isAxiosError } from '@app/helpers';
import { IAcceptPartnership, IUserData, IUserSettings } from '@app/stores/auth/authTypes';
import { ACCEPT_PARTNERSHIP, EMAIL_VERIFICATION, GET_ME, USER } from '@app/constants';
import { useEffect } from 'react';

export const useProfile = () => {
  const token = useAuthtoken?.();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!token) {
      queryClient.setQueryData(['me'], undefined);
      queryClient.removeQueries(['me'] as QueryFilters);
    }
  }, [token, queryClient]);

  const query = useQuery({
    queryKey: ['me'],
    queryFn: () => axios.get<IUserData>(GET_ME),
    select: (data) => data.data,
    staleTime: Infinity,
    enabled: typeof token === 'string',
  });

  return { ...query, isLoggedIn: !!token };
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: profile } = useProfile();

  return useMutation({
    mutationFn: (data: IUserSettings) =>
      profile?.user
        ? axios.patch(USER, { ...data, id: undefined })
        : axios.post(USER, { ...data, id: undefined }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me'] });
      enqueueSnackbar('Profile successfully updated', { variant: 'success' });
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Something went wrong, please try again later', {
          variant: 'error',
        });
      }
    },
  });
};

export const useAcceptPartnerAgreement = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (accept: boolean) => {
      if (!accept) {
        throw new Error('You must accept the partnership agreement');
      }

      return axios.post<IAcceptPartnership>(ACCEPT_PARTNERSHIP);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me'] });
      enqueueSnackbar('Partnership agreement approved!', { variant: 'info' });
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      } else {
        enqueueSnackbar(
          error instanceof Error ? error.message : 'Something went wrong, please try again later',
          {
            variant: 'error',
          },
        );
      }
    },
  });
};

export const useVerifyEmail = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (code: string) => {
      const urlParams = new URLSearchParams({ code }).toString();
      return axios.post(`${EMAIL_VERIFICATION}?${urlParams}`);
    },
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries({ queryKey: ['me'] });
    },
    onError: (e) => {
      if (isAxiosError(e)) {
        onError?.(e?.message);
      } else {
        onError?.('Something went wrong');
      }
    },
  });
};

export const useUserBlocked = () => {
  const { data } = useProfile();
  return data?.block;
};
