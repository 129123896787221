import styles from './style.module.scss';

interface Props{
  children: React.ReactNode
}

export const DocLayout = ({ children }: Props) => {
  return <div className={styles.main}>
    {children}
  </div>;
};