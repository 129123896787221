import { useReadStakingGetFund } from '@app/contracts';
import { type Address } from 'viem';
import { BigNumberish } from 'ethers';

export const useFund = (fundId: BigNumberish) => {
  const { data: fund, isLoading } = useReadStakingGetFund({
    address: process.env.STAKING_ADDRESS as Address,
    args: [BigInt(fundId)],
  });

  return { fund, isLoading };
};

export type Fund = NonNullable<ReturnType<typeof useFund>['fund']>;
