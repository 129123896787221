import React from 'react';
import { RequestWithdrawal } from './RequestWithdrawal';
import { ConvertToUSCA } from "@app/components/Modal/transactions/ConvertToUSCA";
import { IModalContext } from '@app/stores/modal/modalContextTypes';
import { ECurrentModal } from '@app/types';

type IRequestWithdrawalModals = {
  currentModal: IModalContext['currentModal'];
};

const RequestWithdrawalModals = ({
  currentModal,
}: IRequestWithdrawalModals) => {
  return (
    <>
      {currentModal === ECurrentModal.WITHDRAWAL__REQUESTS && (
        <RequestWithdrawal />
      )}
      {currentModal === ECurrentModal.CONVERT_TO_USCA && (
        <ConvertToUSCA />
      )}
    </>
  );
};

export default RequestWithdrawalModals;
