// auth
export const AUTH = '/auth';
export const WELCOME_TOKEN = `${AUTH}/token`;
// user
export const USER = '/user';
export const KYC = '/kyc';
export const GET_ME = `${USER}/me`;
export const SUMSUB_ONBOARDING = `${KYC}/onboarding`;
export const PACKAGE_PURCHASE = `${USER}/package/purchase`;
export const SIGN = `${USER}/sign`;
export const STAKING_AVAILABLE = `${USER}/staking/available`;
export const STABLE_UTILITY_RATE = `${USER}/rate/stable/utility`;
export const UTILITY_DOLLAR_RATE = `${USER}/rate/utility/usdt`;
export const SIGN_REQ = `${USER}/sign-request`;
export const PACKAGES__AVAILABLE = `${USER}/packages/available`;
export const USER_STATISTIC = `${USER}/statistic/my`;
export const EMAIL_VERIFICATION = `${USER}/email/verification`;
export const PHONE_VERIFICATION = `${USER}/phone/verification`;
export const USER_HISTORY = `${USER}/history`;
export const ACCEPT_PARTNERSHIP = `${USER}/accept-partnership`;
export const USER_SIGN_PERMISSION = `${USER}/sign-requests/my`;
export const BONUS_PACKAGES = `${USER}/package/bonus/my`;
export const ACCEPT_BONUS_PACKAGE = `${USER}/package/bonus/accept`;
export const GIVE_BONUS_PACKAGE = `${USER}/package/bonus/give`;
export const GIVE_AND_ACTIVATE_BONUS_PACKAGE = `${USER}/package/bonus/accept/invited`;
export const INVITED_USERS = `${USER}/users/invited`;
export const ADDITIONAL_TOKENS_PRICE = `${USER}/package/additional-tokens/price`;
export const ADDITIONAL_TOKENS_AVAILABLE = `${USER}/package/additional-tokens/available`;
export const GENERATE_LINK = `${USER}/auth/link`;
// packages
export const PACKAGE = '/packages';
// transactions
export const BLOCKCHAIN_HISTORY = `${USER}/history/tokens`;
export const WITHDRAWAL_REQUESTS = `${USER}/withdrawal/my`;
export const REQUEST_WITHDRAW = `${USER}/withdrawal`;
export const REQUEST_WITHDRAW_REJECTE = `${USER}/withdrawal/reject`;
// contracts
export const ADMIN_ADDRESS = '/app-blockchain-address';
export const CONTRACTS = '/contract/contracts-addresses';
export const CONTRACTS_ABI = '/contract/abi/';
export const STAKING_ORDERS = `${USER}/staking-orders`;
export const PROVIDER_FEE = '/contract/provider-fee';
// geo
export const GEO = '/geo';
export const COUNTRIES = '/countries';
// statistic
export const STAT_RATE = '/statistic/rate';
export const STAT_PERFORMANCE = '/statistic/performance-indicators';
// funds-analysis
export const FUNDS_UPCOMING = '/user/funds/upcoming';
export const FUNDS_MUTUAL = '/user/funds/mutual';
export const FUNDS_UNSTAKED = '/user/funds/unstaked';
export const FUNDS_BY_ID = '/user/funds';
