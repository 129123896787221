export const storageService = {
  getItem<T>(key: string): T | null {
    const result = localStorage.getItem(key);

    if (!result) {
      return null;
    }

    try {
      return JSON.parse(result) as T;
    } catch (error) {
      return null;
    }
  },
  setItem(key: string, value: any) {
    const newValue = typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, newValue);
  },
  removeItem(key: string) {
    localStorage.removeItem(key);
  },
};
