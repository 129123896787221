import { STAT_PERFORMANCE, STAT_RATE } from '../../constants/routes';
import { httpClient } from '../httpClient';

interface IRateStatistic {
  createdAt: string,
  aliveUntil: Date,
  rate: string
}

export interface IPerformanceIndicator{
  rateMin: number,
  rateMinPercent: number,
  rateMax: number,
  rateMaxPercent: number,
  circulatingSupply: number,
  dayTradingVolume: number,
  totalSupply: number,
  maxSupply: number
}

export enum EPeriods{
  'DAY' = 'day',
  'WEEK' = 'week',
  'MONTH' = 'month',
  'HALF_YEAR' = 'half-year',
  'YEAR' = 'year'
}

export const StatisticServices = {
  async getRateByPeriod(period: EPeriods): Promise<IRateStatistic[]>{
    const { data } = await httpClient.get<IRateStatistic[]>(STAT_RATE+'/'+period);
    return data;
  },

  async getPerformanceIndicator(): Promise<IPerformanceIndicator>{
    const { data } = await httpClient.get<IPerformanceIndicator>(STAT_PERFORMANCE);
    return data;
  }
};
