import { Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import USDT from '@app/assets/icons/usdt-logo.svg';
import USDC from '@app/assets/icons/usdc-logo.svg';
import uscaTokenLogo from '@app/assets/icons/scaTokenLogo.png';
import vectorLeft from '@app/assets/icons/vectorLeft.svg';
import vectorRight from '@app/assets/icons/vectorRight.svg';
import Image from 'next/image';
import styles from '../style.module.scss';
import { formatEther, parseEther } from 'viem';
import { User } from '@app/hooks/useUser';
import { ICurrency } from '@app/components/CurrenciesSelect/CurrenciesSelect';
import { SPECIAL_RATE } from '@app/types';
import { useTranslation } from 'next-i18next';

type Action =
  | {
      action: 'currency';
      value: string;
    }
  | {
      action: 'usca';
      value: string;
    };

const nf = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 4,
});

export const BalanceDisplay: React.FC<{
  rate: BigInt | undefined;
  user: User;
  currencyObject: ICurrency | undefined;
}> = ({ rate, user, currencyObject }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState({ sumCurrency: '', usca: '' });
  const icons: any = { USDT, USDC };

  const calculateCurrency = (action: Action) => {
    try {
      if (action.action === 'currency') {
        const amount: bigint = parseEther(action?.value || '0');
        const uscaPrice =
          rate && user?.package
            ? // ? amount.mul(10_000).div(rate).div(user.package.commission.add(10_000)).mul(10_000)
              ((amount * SPECIAL_RATE.valueOf()) /
                rate.valueOf() /
                (BigInt(user.package.commission) + SPECIAL_RATE)) *
              SPECIAL_RATE
            : undefined;
        const formattedValue = nf.format(+formatEther(BigInt(uscaPrice ?? '0').valueOf()));
        setValue({ sumCurrency: action.value, usca: formattedValue });
      } else if (action.action === 'usca') {
        const amount = parseEther(action?.value || '0');
        const usdtPrice =
          rate && user?.package
            ? // ? amount.mul(rate).mul(user.package.commission.add(10000)).div(10000).div(10000)
              (amount * rate.valueOf() * (BigInt(user.package.commission) + SPECIAL_RATE)) /
              SPECIAL_RATE /
              SPECIAL_RATE
            : undefined;
        const formattedValue = nf.format(+formatEther(BigInt(usdtPrice ?? '0').valueOf()));
        setValue({ sumCurrency: formattedValue, usca: action.value });
      }
    } catch (e) {}
  };

  useEffect(() => {
    setValue({ sumCurrency: '', usca: '' });
  }, [currencyObject?.currency]);

  return (
    <Grid className={styles.boxBalance} container gap={2}>
      <Typography className={styles.boxBalance_title}>{t('balance_display.title')}</Typography>
      <Grid container justifyContent="space-between">
        <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={5} ml={2}>
          {currencyObject?.currency && (
            <Image
              style={{ width: '36px', height: '32px' }}
              src={icons[currencyObject?.currency!]}
              alt="usdtExchangeIcon"
            />
          )}
          <Typography ml={2} component="span" className={styles.boxBalance_span}>
            {currencyObject?.currency}
          </Typography>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={5} ml={2}>
          <Image src={uscaTokenLogo} width={36} height={36} alt="uscaTokenLogo" />
          <Typography ml={2} component="span" className={styles.boxBalance_span}>
            USCA
          </Typography>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={5} mt={3}>
          <TextField
            className={styles.boxBalance_TextField}
            InputProps={{
              sx: { height: '47px' },
            }}
            value={value?.sumCurrency}
            onChange={(event) =>
              calculateCurrency({
                action: 'currency',
                value: event.target.value,
              })
            }
          />
        </Grid>
        <Grid
          item
          xs={1}
          mt={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Image src={vectorRight} alt="vectorRight" />
          <Image src={vectorLeft} alt="vectorLeft" />
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={5} mt={3}>
          <TextField
            className={styles.boxBalance_TextField}
            InputProps={{
              sx: { height: '47px' },
            }}
            value={value?.usca}
            onChange={(event) =>
              calculateCurrency({
                action: 'usca',
                value: event.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
