import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

export const OnlyBonusPackageContent: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography mb={1}>{t('only_bonus_package.description_1')}</Typography>
      <Typography mb={1}>{t('only_bonus_package.description_2')}</Typography>
      <Typography mb={5}>{t('only_bonus_package.description_3')}</Typography>
      <Typography>{t('only_bonus_package.description_4')}</Typography>
    </Box>
  );
};
