import React, { FC, useState } from 'react'
import { Box, Tooltip, TooltipProps } from '@mui/material'
import { getReferralLink } from '@app/utils/url'
import styles from '@app/components/Modal/QRCode/style.module.scss'
import { Button } from '@app/components/shared/Button'
import { EButtonType } from '@app/types'

type AdditionalProps = {
  fundId?: string
  referralAddress?: string
  buttonType: EButtonType
  buttonText: string
  buttonWidth?: string | number
}
export const CopiedToClipboardButtonTooltip: FC<
  Omit<TooltipProps & AdditionalProps, 'children'>
> = ({
  fundId,
  referralAddress,
  title,
  arrow = false,
  buttonType,
  buttonText,
  buttonWidth = '100%',
  ...rest
}) => {
  const [isClipboardValue, setIsClipboardValue] = useState<boolean>(false)

  const copyReferralLink = () => {
    navigator.clipboard.writeText(getReferralLink({ fundId, referralAddress })).then(() => {
      setIsClipboardValue(true)
      const timeout = setTimeout(() => {
        setIsClipboardValue(false)
        clearTimeout(timeout)
      }, 3000)
    })
  }

  return (
    <Tooltip open={isClipboardValue} title={title} arrow={arrow} {...rest}>
      <Box className={styles.btn}>
        <Button customStyles={{ width: buttonWidth }} type={buttonType} onClick={copyReferralLink}>
          {buttonText}
        </Button>
      </Box>
    </Tooltip>
  )
}
