import { Box, Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useAccount, useBalance } from 'wagmi';
import React, { useEffect, useMemo, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import { useSetAtom, useAtomValue } from 'jotai';
import { formatEther, type Address } from 'viem';
import {  useReadBonusEvaluatorIsPromotionAlive,  useReadStakingGetStakes} from '@app/contracts';
import { useRate } from '@app/hooks/useRate';
import styles from './style.module.scss';
import { FundType } from '@app/types/fundsAnalysis';
import { useProfile } from '@app/hooks';
import { alreadyRequestedAtom, profileAtom, scaFormattedBalance } from '@app/stores/atom/infoBar';
import { SPECIAL_RATE } from '@app/types';
import { useTriggerRefresh } from '@app/hooks/useTriggerRefresh';
import { useSpecialDiscount } from '@app/hooks/useSpecialDiscount';
import { useTranslation } from 'next-i18next';

const nf = new Intl.NumberFormat();
const revisorList = [
  '0x7bC74e1DfaAA5aBe83eecabB66f2544538F324D3',
  '0xAdb08ef0Ed2FbBa33FEc8fD51070e84567F0a2A9',
];

export const TokensBalances = ({ isMigrationNeeded }: { isMigrationNeeded?: boolean }) => {
  const { push } = useRouter();
  const { address } = useAccount();
  const { t } = useTranslation();

  // REVISOR DATA -----------------------------------------------------------------------------------------------------
  const [investedRevisor] = useState(96020);
  const [insuranceValueRevisor] = useState(78338);
  // ------------------------------------------------------------------------------------------------------------------

  const { data: sca, refetch: refetchBalanceUSCA } = useBalance({
    token: process.env.USCA_ADDRESS as Address,
    address,
  });

  const {
    data: oldSCA,
    isError: balanceIsError,
    error: balanceError,
    refetch: refetchBalanceOldSca,
  } = useBalance({
    token: process.env.OLD_SCA_ADDRESS as Address,
    address,
  });

  if (balanceIsError) {
    // TODO: NEED TO CLARIFY THIS ONE
    console.warn('Balance not available', balanceError.message);
  }

  const { data: profile } = useProfile();

  const { rate } = useRate();

  const { data: stakes, refetch: refetchStakingGetStakes } = useReadStakingGetStakes({
    address: process.env.STAKING_ADDRESS as Address,
    args: address ? [address] : undefined,
  });

  const { data: investorBonusInsuranceRate, packageSize } = useSpecialDiscount();

  const { data: isPromotionAlive } = useReadBonusEvaluatorIsPromotionAlive({
    address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
  });

  const staked = stakes?.reduce((acc, val) => acc + val.amount, BigInt(0)) ?? BigInt(0);

  const setProfile = useSetAtom(profileAtom);
  const setScaFormattedBalance = useSetAtom(scaFormattedBalance);

  const alreadyRequested = useAtomValue(alreadyRequestedAtom);

  const getDuration = (end: number) => {
    return intervalToDuration({
      start: 0,
      end: end,
    });
  };

  const stakesWithFundByType = (fundTypes: FundType) => {
    return stakes?.filter(({ fundType }) => fundType === fundTypes);
  };

  const stakedOrdinary = useMemo(() => {
    const stakesWithFundOrdinary = stakesWithFundByType(FundType.RegularFunds);
    if (!stakesWithFundOrdinary || stakesWithFundOrdinary.length === 0) {
      return BigInt(0);
    }
    return (
      stakesWithFundOrdinary?.reduce((acc, val) => {
        // if (duration.days === 1) return acc
        return acc + val.amount;
      }, BigInt(0)) ?? BigInt(0)
    );
  }, [stakes, stakesWithFundByType]);

  const stakedModerate = useMemo(() => {
    const stakesWithFundModerate = stakesWithFundByType(FundType.ModerateFunds);
    if (!stakesWithFundModerate || stakesWithFundModerate.length === 0) {
      return BigInt(0);
    }
    return (
      stakesWithFundModerate?.reduce((acc, val) => {
        // if (duration.days === 1) return acc
        return acc + val.amount;
      }, BigInt(0)) ?? BigInt(0)
    );
  }, [stakes, stakesWithFundByType]);

  const stakedSpecial = useMemo(() => {
    const stakesWithFundSpecial = stakesWithFundByType(FundType.SpecialFunds);
    if (!stakesWithFundSpecial || stakesWithFundSpecial.length === 0) {
      return BigInt(0);
    }
    return (
      stakesWithFundSpecial?.reduce((acc, val) => {
        // if (duration.days !== 1) return acc
        return acc + val.amount;
      }, BigInt(0)) ?? BigInt(0)
    );
  }, [stakes, stakesWithFundByType]);

  const availbleToWithdrawOrdinary = useMemo(() => {
    const stakesWithFundOrdinary = stakesWithFundByType(FundType.RegularFunds);
    if (!stakesWithFundOrdinary || stakesWithFundOrdinary.length === 0) {
      return BigInt(0);
    }
    return (
      stakesWithFundOrdinary?.reduce((acc, val) => {
        return acc + val.availbleToWithdraw;
      }, BigInt(0)) ?? BigInt(0)
    );
  }, [stakes, stakesWithFundByType]);

  const availbleToWithdrawModerate = useMemo(() => {
    const stakesWithFundModerate = stakesWithFundByType(FundType.ModerateFunds);
    if (!stakesWithFundModerate || stakesWithFundModerate.length === 0) {
      return BigInt(0);
    }
    return (
      stakesWithFundModerate?.reduce((acc, val) => {
        return acc + val.availbleToWithdraw;
      }, BigInt(0)) ?? BigInt(0)
    );
  }, [stakes, stakesWithFundByType]);

  const availbleToWithdrawSpecial = useMemo(() => {
    const stakesWithFundSpecial = stakesWithFundByType(FundType.SpecialFunds);
    if (!stakesWithFundSpecial || stakesWithFundSpecial.length === 0) {
      return BigInt(0);
    }
    return (
      stakesWithFundSpecial?.reduce((acc, val) => {
        return acc + val.availbleToWithdraw;
      }, BigInt(0)) ?? BigInt(0)
    );
  }, [stakes, stakesWithFundByType]);

  const stakedBalance = useMemo(() => {
    if (!(rate && sca)) return;
    return {
      equivalent: +formatEther(BigInt(rate * (sca.value + staked)) / SPECIAL_RATE),
      ordinary: !(stakedOrdinary === BigInt(0)) ? +formatEther(stakedOrdinary) : BigInt(0),
      moderate: !(stakedModerate === BigInt(0)) ? +formatEther(stakedModerate) : BigInt(0),
      special: !(stakedSpecial === BigInt(0)) ? +formatEther(stakedSpecial) : BigInt(0),
    };
  }, [stakedOrdinary, stakedModerate, stakedSpecial, rate, sca, staked]);

  const withdrawOrdinary = useMemo(() => {
    if (!rate) return;
    return {
      availbleToWithdraw: !(availbleToWithdrawOrdinary === BigInt(0))
        ? +formatEther((rate * availbleToWithdrawOrdinary) / SPECIAL_RATE)
        : BigInt(0),
    };
  }, [rate, availbleToWithdrawOrdinary]);

  const withdrawModerate = useMemo(() => {
    if (!rate) return;
    return {
      availbleToWithdraw: !(availbleToWithdrawModerate === BigInt(0))
        ? +formatEther((rate * availbleToWithdrawModerate) / SPECIAL_RATE)
        : BigInt(0),
    };
  }, [rate, availbleToWithdrawModerate]);

  const withdrawSpecial = useMemo(() => {
    if (!rate) return;
    return {
      availbleToWithdraw: !(availbleToWithdrawSpecial === BigInt(0))
        ? +formatEther((rate * availbleToWithdrawSpecial) / SPECIAL_RATE)
        : BigInt(0),
    };
  }, [rate, availbleToWithdrawSpecial]);

  const dataInfo = [
    {
      title: t('tokens_balance.amount_insured'),
      value: 0,
      isChild: false,
    },
    {
      title: t('tokens_balance.in_regular_funds'),
      value: stakedBalance?.ordinary ?? 0,
      isChild: false,
    },
    {
      title: t('tokens_balance.ready_for_unstaking'),
      value: withdrawOrdinary?.availbleToWithdraw ?? 0,
      isChild: true,
    },
    {
      title: t('tokens_balance.in_moderate_funds'),
      value: stakedBalance?.moderate ?? 0,
      isChild: false,
    },
    {
      title: t('tokens_balance.ready_for_unstaking'),
      value: withdrawModerate?.availbleToWithdraw ?? 0,
      isChild: true,
    },
    {
      title: t('tokens_balance.in_special_funds'),
      value: stakedBalance?.special ?? 0,
      isChild: false,
    },
    {
      title: t('tokens_balance.ready_for_unstaking'),
      value: withdrawSpecial?.availbleToWithdraw ?? 0,
      isChild: true,
    },
  ];

  useEffect(() => {
    setProfile(profile);
  }, [profile, setProfile]);

  useEffect(() => {
    setScaFormattedBalance(sca?.formatted);
  }, [sca?.formatted, setScaFormattedBalance]);

  useTriggerRefresh(() => {
    refetchBalanceUSCA();
    refetchBalanceOldSca();
    refetchStakingGetStakes();
  });

  return (
    <Box className={styles.infoBlock}>
      {/*TODO*/}
      {address && revisorList.includes(address) ? (
        <>
          <Box className={styles.infoContentItem}>
            <Typography className={styles.infoContentTitle}>USCA</Typography>
            <Grid container className={styles.infoSubAvailable}>
              <Grid item xs={4}>
                <Typography
                  className={styles.infoSubAvailable_title}
                  onClick={() => push('/packages')}
                >
                  {t('tokens_balance.available')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={styles.infoSubtitle} onClick={() => push('/packages')}>
                  0
                </Typography>
              </Grid>
            </Grid>

            {alreadyRequested > 0 && (
              <Grid container className={styles.infoSubInvested} sx={{ paddingLeft: '10px' }}>
                <Grid item xs={4}>
                  <Typography
                    component={'div'}
                    className={styles.infoSubInvested_title}
                    onClick={() => push('/transactions')}
                  >
                    {t('tokens_balance.to_withdraw')}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    component={'div'}
                    className={styles.infoSubInvested_value}
                    onClick={() => push('/transactions')}
                  >
                    {nf.format(alreadyRequested)}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid container className={styles.infoSubInvested}>
              <Grid item xs={4}>
                <Typography
                  component={'div'}
                  className={styles.infoSubInvested_title}
                  onClick={() => push('/funds-analysis')}
                >
                  {t('tokens_balance.invested')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  component={'div'}
                  className={styles.infoSubInvested_value}
                  onClick={() => push('/funds-analysis')}
                >
                  {nf.format(investedRevisor + +formatEther(staked))}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container className={styles.usdtEquivalent}>
              <Grid item xs={9}>
                <Typography>{t('tokens_balance.usd_equivalent')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={styles.usdtEquivalent_value}>
                  {nf.format(investedRevisor + +formatEther(staked))}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container className={styles.usdtEquivalent}>
              <Grid item xs={9}>
                <Typography>{t('tokens_balance.insurance_value')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={styles.usdtEquivalent_value}>
                  {nf.format(insuranceValueRevisor + +formatEther(staked))} USD
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container className={styles.usdtEquivalent}>
              <Grid item xs={9}>
                <Typography>{t('tokens_balance.in_moderate_funds')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={styles.usdtEquivalent_value}>
                  {nf.format(investedRevisor + +formatEther(staked))}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Box className={styles.infoContentItem} mb={1}>
            <Typography className={styles.infoContentTitle}>USCA</Typography>
            <Grid container className={styles.infoSubAvailable}>
              <Grid item xs={4}>
                <Typography
                  className={styles.infoSubAvailable_title}
                  onClick={() => push('/packages')}
                >
                  {t('tokens_balance.available')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={styles.infoSubtitle} onClick={() => push('/packages')}>
                  {nf.format(+(sca?.formatted ?? 0) - alreadyRequested)}
                </Typography>
              </Grid>
            </Grid>
            {alreadyRequested > 0 && (
              <Grid container className={styles.infoSubInvested} sx={{ paddingLeft: '10px' }}>
                <Grid item xs={5}>
                  <Typography
                    component={'div'}
                    className={styles.infoSubInvested_title}
                    onClick={() => push('/transactions')}
                  >
                    {t('tokens_balance.to_withdraw')}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    component={'div'}
                    className={styles.infoSubInvested_value}
                    onClick={() => push('/transactions')}
                  >
                    {nf.format(alreadyRequested)}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {Number(oldSCA?.formatted) > 0 && isMigrationNeeded && (
              <Grid container className={styles.infoSubInvested}>
                <Grid item xs={4}>
                  <Typography
                    component={'div'}
                    className={styles.infoSubInvested_title}
                    onClick={() => push('/funds-analysis')}
                  >
                    {t('tokens_balance.sca_token_balance')}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    component={'div'}
                    className={styles.infoSubInvested_value}
                    onClick={() => push('/funds-analysis')}
                  >
                    {nf.format(+(oldSCA?.formatted ?? '0'))}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid container className={styles.infoSubInvested}>
              <Grid item xs={4}>
                <Typography
                  component={'div'}
                  className={styles.infoSubInvested_title}
                  onClick={() => push('/funds-analysis')}
                >
                  {t('tokens_balance.invested')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  component={'div'}
                  className={styles.infoSubInvested_value}
                  onClick={() => push('/funds-analysis')}
                >
                  {nf.format(+formatEther(staked))}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {staked > BigInt(0) && <hr className={styles.divider} />}

          {dataInfo.map((item, index) => (
            <Box key={item.title + index}>
              {item.value ? (
                <Grid container className={styles.usdtEquivalent}>
                  <Grid item xs={9} sx={{ paddingLeft: item?.isChild ? '10px' : '' }}>
                    <Typography>{item.title}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={styles.usdtEquivalent_value}>
                      {nf.format(item.value)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Box>
          ))}
          {isPromotionAlive && investorBonusInsuranceRate?.promotionInsuranceDiscount && packageSize ? (
            <Box>
              <Grid container className={styles.usdtEquivalent}>
                <Typography mr={1}>{t('tokens_balance.your_insurance_discount')}</Typography>
                <Typography className={styles.usdtEquivalent_value}>
                  {Number(investorBonusInsuranceRate?.promotionInsuranceDiscount ?? '0') / 100}%
                </Typography>
              </Grid>
              {packageSize ? (
                <Grid item sx={{ paddingLeft: '10px' }}>
                  <Typography className={styles.infoSubAvailable_title}>
                    ({t('tokens_balance.for_package')} {packageSize})
                  </Typography>
                </Grid>
              ) : (
                ''
              )}
            </Box>
          ) : (
            ''
          )}
        </>
      )}
    </Box>
  );
};
