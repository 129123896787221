import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { type Address, formatEther } from 'viem';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useProfile, useUser } from '@app/hooks';
import {
  useReadBonusEvaluatorGetEnabled,
  useReadBonusEvaluatorGetReferralBonusValue,
} from '@app/contracts';
// import { isMobile } from 'react-device-detect';
import { CustomDialog } from '@app/components/shared/Dialog';
import { PartnerAgreement } from '@app/components/Modal/auth/PartnerAgreement/PartnerAgreement';
import { PartnerAgreementContent } from '@app/components/Modal/auth/PartnerAgreement/PartnerAgreementContent';
import { ServiceAgreement } from '@app/components/Modal/auth/NeedInviterAddressAndAgreement/ServiceAgreement';
import { PrivacyPolicy } from '@app/components/Modal/auth/PrivacyPolicy/PrivacyPolicy';
import { GetWelcomeBonusDialog } from '@app/components/GetWelcomeBonusDialog/GetWelcomeBonusDialog';
import classNames from 'classnames';
import styles from './style.module.scss';
import { Box, Grow, Typography } from '@mui/material';
// import TelegramIcon from '@mui/icons-material/Telegram';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import logo from 'public/SCA_Alliance_1.png';
import partnerProgram from '@app/assets/icons/partnerProgram.svg';
import starIcon from '@app/assets/icons/star.svg';
import orangeStarIcon from '@app/assets/icons/star-orange.svg';
// import selectArrowIcon from '@app/assets/icons/selectArrowIcon.svg';
// import deselectArrowIcon from '@app/assets/icons/deselectArrowIcon.svg';
import menuIcon from 'src/assets/icons/menu.png';
import Question from '@app/assets/icons/question.png';
import { authService } from '@app/services';
import { Preloader } from '@app/components/shared/Preloader';
import { useSnackbar } from 'notistack';
import { isAxiosError } from '@app/helpers';
import { RedeemSpecialBonus } from '@app/components/RedeemSpecialBonus/RedeemSpecialBonus';
import { useAccount } from 'wagmi';
import { useTranslation } from 'next-i18next';
import { StakingAgreement } from '@app/components/Agreements/StakingAgreement';
import { useTriggerRefresh } from '@app/hooks/useTriggerRefresh';

export type NavItemType = {
  title: string;
  href: string;
  icon: any;
  requireAuth: boolean;
};

type Props = {
  handlerIsOpenWallet: (isWallet: boolean) => void;
  isOpenWallet: boolean;
  setShowPrivacyPolicyDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowClientAgreementDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPartnershipAgreementDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowStakingAgreementDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showStakingAgreementDialog: boolean;
  showPartnershipAgreementDialog: boolean;
  showClientAgreementDialog: boolean;
  showPrivacyPolicyDialog: boolean;
};

export const MainSidebar = ({
  handlerIsOpenWallet,
  isOpenWallet,
  setShowPrivacyPolicyDialog,
  setShowClientAgreementDialog,
  setShowPartnershipAgreementDialog,
  setShowStakingAgreementDialog,
  showStakingAgreementDialog,
  showPartnershipAgreementDialog,
  showClientAgreementDialog,
  showPrivacyPolicyDialog,
}: Props) => {
  const { isLoggedIn, data: profile } = useProfile();
  const router = useRouter();
  const { asPath, pathname } = router;
  const { user } = useUser();
  const { t } = useTranslation();
  const hasPackage = !!user.package;
  const prartnerAgrrementAccepted = !!profile?.partnerAgreementAcceptedAt;
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [showPurchasePackage, setShowPurchasePackage] = useState(false);
  const [showJoinPartnerDialog, setShowJoinPartnerDialog] = useState(false);
  const [hideTitles, setHideTitles] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [showWelcomeBonusDialog, setShowWelcomeBonusDialog] = useState(false);
  const [showRedeemSpecialBonuDialog, setShowRedeemSpecialBonuDialogDialog] = useState(false);
  const isKycConfirmed = profile?.reviewStatus === 'completed';
  const { address } = useAccount();
  const [link, setLink] = useState<string | null>(null);

  const { data: referralBonusValue, refetch: refetchReferralBonusValue } =
    useReadBonusEvaluatorGetReferralBonusValue({
      address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
      account: address! as Address,
      args: [address as Address],
    });

  const { data: enabled } = useReadBonusEvaluatorGetEnabled({
    address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
  });

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const bonusValue = useMemo(() => {
    return +formatEther(referralBonusValue?.bonusValue ?? BigInt(0));
  }, [referralBonusValue]);

  useTriggerRefresh(refetchReferralBonusValue);

  const toggleMenu = () => {
    if (!isOpenMenu) {
      handlerIsOpenWallet(false);
    }
    setIsOpenMenu(!isOpenMenu);
  };

  const handleClick = () => {
    setOpenCollapse(!openCollapse);
    setHideTitles(false);
  };

  const toggleWallet = () => {
    if (!isOpenWallet) {
      setIsOpenMenu(false);
    }
    handlerIsOpenWallet(!isOpenWallet);
  };

  const redirectToRouter = (path: string) => {
    setIsOpenMenu(false);
    handlerIsOpenWallet(false);
    router.push(path);
  };

  useEffect(() => {
    if (asPath.startsWith('/staking') && !hasPackage && isLoggedIn && !!profile) {
      setShowPurchasePackage(true);
    }
  }, [setShowPurchasePackage, asPath, hasPackage, isLoggedIn, profile]);

  const getClassByRoute = (paths: string[]) => {
    if (pathname === '/stake' && paths[0] === '/staking') return styles.navItemActive;
    if (paths.includes(pathname)) return styles.navItemActive;
    return '';
  };

  const toggleTitles = () => {
    setHideTitles((prevState) => !prevState);
    setOpenCollapse(false);
  };

  const accessViewItem = useCallback(
    (requireAuth: boolean, title: string) => {
      // if ((title === 'Staking' || title === 'Transactions') && !isKycConfirmed) return false
      if (isLoggedIn && title === 'Personal Data') return true;
      return !requireAuth || isLoggedIn;
    },
    // [isLoggedIn, isKycConfirmed],
    [isLoggedIn],
  );

  const getLinkTo247andPersonalAccount = async () => {
    try {
      const { data } = await authService.generateLink();
      if (!data?.link) return;

      setLink(data.link);
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    if (prartnerAgrrementAccepted && hasPackage) {
      setTimeout(() => getLinkTo247andPersonalAccount(), 300);
    }
  }, [prartnerAgrrementAccepted, hasPackage]);

  const navItems = [
    {
      title: t('sidebar_menu.overview_stats'),
      href: '/',
      requireAuth: false,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.0001 5V7H15.0001V5H19.0001ZM9.00006 5V11H5.00006V5H9.00006ZM19.0001 13V19H15.0001V13H19.0001ZM9.00006 17V19H5.00006V17H9.00006ZM21.0001 3H13.0001V9H21.0001V3ZM11.0001 3H3.00006V13H11.0001V3ZM21.0001 11H13.0001V21H21.0001V11ZM11.0001 15H3.00006V21H11.0001V15Z"
            fill="url(#paint0_linear_1187_44)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1187_44"
              x1="3.00006"
              y1="21"
              x2="0.0661804"
              y2="7.57479"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DABF5" />
              <stop offset="1" stopColor="#5FEDCF" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: t('sidebar_menu.personal_data'),
      href: '/settings',
      requireAuth: true,
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 0C10.8 0 0 10.8 0 24C0 37.2 10.8 48 24 48C37.2 48 48 37.2 48 24C48 10.8 37.2 0 24 0ZM24 8C28.4 8 32 12.4 32 18C32 23.6 28.4 28 24 28C19.6 28 16 23.6 16 18C16 12.4 19.6 8 24 8ZM37.2 39C33.8 42 29 44 24 44C19 44 14.2 42 10.8 39C10 38.2 9.8 37 10.6 36.2C12.8 33.6 15.8 31.8 19 30.8C20.6 31.6 22.2 32 24 32C25.8 32 27.4 31.6 29 30.8C32.4 31.8 35.2 33.6 37.4 36.2C38.2 37 38.2 38.2 37.2 39Z"
            fill="url(#paint0_linear_996_19)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_996_19"
              x1="20"
              y1="1"
              x2="7.80463"
              y2="-0.938268"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DABF5" />
              <stop offset="1" stopColor="#5FEDCF" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: t('sidebar_menu.packages'),
      href: '/packages',
      requireAuth: false,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 19L6 19C4.9 19 4 18.1 4 17L4 3C4 1.9 4.9 1 6 1L14 1C15.1 1 16 1.9 16 3L16 17C16 18.1 15.1 19 14 19ZM14 17L14 3L6 3L6 17L14 17ZM7 23L18 23C19.1 23 20 22.1 20 21L20 4C19 4 19.1 4 18 4L18 21L7 21C7 22.1 7 22 7 23Z"
            fill="url(#paint0_linear_996_19)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_996_19"
              x1="20"
              y1="1"
              x2="7.80463"
              y2="-0.938268"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DABF5" />
              <stop offset="1" stopColor="#5FEDCF" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: t('sidebar_menu.staking'),
      href: '/funds-analysis',
      requireAuth: false,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 4C10.58 4 7 7.58 7 12C7 16.42 10.58 20 15 20C19.42 20 23 16.42 23 12C23 7.58 19.42 4 15 4ZM15 18C11.69 18 9 15.31 9 12C9 8.69 11.69 6 15 6C18.31 6 21 8.69 21 12C21 15.31 18.31 18 15 18ZM3 12C3 9.39 4.67 7.17 7 6.35V4.26C3.55 5.15 1 8.27 1 12C1 15.73 3.55 18.85 7 19.74V17.65C4.67 16.83 3 14.61 3 12Z"
            fill="url(#paint0_linear_919_154)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_919_154"
              x1="1"
              y1="20"
              x2="-0.938267"
              y2="7.80463"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DABF5" />
              <stop offset="1" stopColor="#5FEDCF" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: t('sidebar_menu.transactions'),
      href: '/transactions',
      requireAuth: true,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM4.5 7L8 3.5L11.5 7H9V11H7V7H4.5ZM15.5 13L12 16.5L8.5 13H11V9H13V13H15.5Z"
            fill="url(#paint0_linear_919_161)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_919_161"
              x1="2.48593e-06"
              y1="20"
              x2="-3.25987"
              y2="5.08311"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DABF5" />
              <stop offset="1" stopColor="#5FEDCF" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
  ];

  const navItemsList = useMemo(() => {
    return (
      <Box className={styles.navbar} display="flex" flexDirection="column" flex="1" mb={3}>
        {navItems
          .filter((navItem) => navItem.title !== t('sidebar_menu.transactions') || isLoggedIn)
          .map((navItem: NavItemType) => (
            <Box
              key={navItem.href}
              // style={
              //   navItem.title === 'Personal Data' && !isKycConfirmed && isLoggedIn
              //     ? {
              //         background: 'linear-gradient(347.67deg, #EAD06A 17.93%, #D98B4C 82.07%)',
              //         borderRadius: '5px',
              //       }
              //     : {}
              // }
              className={
                accessViewItem(navItem.requireAuth, navItem.title)
                  ? classNames(styles.navItem, getClassByRoute([navItem.href]))
                  : classNames(styles.navItem, styles.navItemDisabled)
              }
              onClick={() =>
                accessViewItem(navItem.requireAuth, navItem.title)
                  ? redirectToRouter(navItem.href)
                  : ''
              }
            >
              <Box className={styles.link}>
                <Box sx={{ color: '#013342' }} className={styles.linkIcon}>
                  {navItem.icon}
                </Box>
                <p className={styles.linksTitle}>{navItem.title}</p>
              </Box>
            </Box>
          ))}
        {enabled && (
          <Box>
            <Box
              className={classNames(styles.navItem, styles.navItemPartner, styles.partnerLink)}
              onClick={() => setShowWelcomeBonusDialog(!showWelcomeBonusDialog)}
              sx={{ cursor: 'pointer' }}
            >
              <Box className={styles.linkIcon}>
                <Image src={starIcon} alt="Welcome Bonus" />
              </Box>
              <p className={styles.linksTitle}>{t('sidebar_menu.get_welcome_bonus')}</p>
            </Box>
            {showWelcomeBonusDialog && (
              <GetWelcomeBonusDialog
                handleClose={(isShow) => setShowWelcomeBonusDialog(isShow)}
                open={showWelcomeBonusDialog}
              />
            )}
          </Box>
        )}

        {prartnerAgrrementAccepted && hasPackage ? (
          <Box className={classNames(styles.navItem)}>
            <Box
              className={styles.link}
              style={{ width: '22px !important' }}
              onClick={() => setShowRedeemSpecialBonuDialogDialog(!showRedeemSpecialBonuDialog)}
            >
              <Box className={styles.linkIcon} style={{ width: '22px !important' }}>
                <Image
                  src={bonusValue > 0 ? orangeStarIcon : starIcon}
                  alt="Redeem Special Bonus"
                />
              </Box>
              <Box>
                <p className={styles.linksTitle} style={{ paddingBottom: '10px' }}>
                  {t('sidebar_menu.redeem_special_bonus')}
                </p>
                {bonusValue > 0 && <p className={styles.referralBonusValue}>{bonusValue} USCA</p>}
              </Box>
            </Box>
            {showRedeemSpecialBonuDialog && (
              <RedeemSpecialBonus
                open={showRedeemSpecialBonuDialog}
                handleClose={(isShow) => setShowRedeemSpecialBonuDialogDialog(isShow)}
              />
            )}
          </Box>
        ) : (
          ''
        )}

        <Box
          className={classNames(styles.navItem, styles.navItemPartner, styles.partnerLink)}
          style={
            !prartnerAgrrementAccepted && hasPackage
              ? {
                  background: 'linear-gradient(347.67deg, #EAD06A 17.93%, #D98B4C 82.07%)',
                  borderRadius: '5px',
                }
              : {}
          }
        >
          {!link ? (
            <Box
              onClick={(e) => {
                if (!hasPackage) {
                  setShowPurchasePackage(true);
                  e.preventDefault();
                }

                if (hasPackage && !prartnerAgrrementAccepted) {
                  setShowJoinPartnerDialog(true);
                  e.preventDefault();
                }
              }}
            >
              <Box className={styles.link}>
                <Box className={styles.linkIcon}>
                  <Image src={partnerProgram} alt="Affiliate programme" />
                </Box>
                <p
                  className={styles.linksTitle}
                  style={prartnerAgrrementAccepted && hasPackage ? { color: '#fff' } : {}}
                >
                  {t('sidebar_menu.affiliate_programme')}
                </p>
              </Box>
            </Box>
          ) : (
            <Link
              href={link}
              target="_blank"
              passHref
              legacyBehavior={false}
              className={styles.link}
            >
              <Box className={styles.linkIcon}>
                <Image src={partnerProgram} alt="Affiliate programme" />
              </Box>
              <p
                className={styles.linksTitle}
                style={prartnerAgrrementAccepted && hasPackage ? { color: '#fff' } : {}}
              >
                {t('sidebar_menu.247_and_co_personal_account')}
              </p>
            </Link>
          )}
        </Box>

        <Box className={classNames(styles.navItem)}>
          <Link
            href={'/faq'}
            passHref
            legacyBehavior={false}
            onClick={() => redirectToRouter('/faq')}
          >
            <Box className={styles.link} style={{ width: '22px !important' }}>
              <Box className={styles.linkIcon} style={{ width: '22px !important' }}>
                <Image src={Question} style={{ width: 22, height: 22 }} alt="FAQ" />
              </Box>
              <p className={styles.linksTitle} style={{ paddingBottom: '10px' }}>
                {t('sidebar_menu.faq')}
              </p>
            </Box>
          </Link>
        </Box>
        {/*<Box className={styles.navItem} onClick={handleClick}>
          <Box className={styles.link}>
            <Box className={styles.linkIcon}>
              <PrivacyTipIcon
                className={styles.linkIcon}
                sx={{ color: '#4DABF5' }}
              />
            </Box>
            <p className={styles.linksTitle}>SCA Ecosystem</p>
            <Box className={styles.img}>
              <Image
                src={openCollapse ? deselectArrowIcon : selectArrowIcon}
                alt=""
              />
            </Box>
          </Box>
        </Box>
        <Collapse in={openCollapse} timeout="auto">
          <Box className={styles.navItem2} ml={3}>
            <Link
              href="https://sca-innovative.com/"
              target={isMobile ? '' : '_blank'}
              passHref
            >
              <a className={styles.link} target={isMobile ? '' : '_blank'}>
                <p className={styles.linksTitle}>SCA Homepage</p>
              </a>
            </Link>
          </Box>
          <Box className={styles.navItem2} ml={3}>
            <Link
              href="https://247and.co"
              target={isMobile ? '' : '_blank'}
              passHref
            >
              <a className={styles.link} target={isMobile ? '' : '_blank'}>
                <p className={styles.linksTitle}>247and Co Homepage</p>
              </a>
            </Link>
          </Box>
          <Box className={styles.navItem2} ml={3}>
            <Link
              href="https://scadk.finance"
              target={isMobile ? '' : '_blank'}
              passHref
            >
              <a className={styles.link} target={isMobile ? '' : '_blank'}>
                <p className={styles.linksTitle}>SCA Homepage</p>
              </a>
            </Link>
          </Box>
          <Box className={styles.navItem2} ml={3}>
            <Link
              href="https://iib-insurance.co.uk"
              target={isMobile ? '' : '_blank'}
              passHref
            >
              <a className={styles.link} target={isMobile ? '' : '_blank'}>
                <p className={styles.linksTitle}>
                  Imperial Insurance Broker Homepage
                </p>
              </a>
            </Link>
          </Box>
          <Box className={styles.navItem2} ml={3}>
            <Link
              href="https://t.me/MXBI_EN"
              target={isMobile ? '' : '_blank'}
              passHref
            >
              <a className={styles.link} target={isMobile ? '' : '_blank'}>*/}
        {/*<TelegramIcon*/}
        {/*    className={styles.linkIcon}*/}
        {/*    sx={{ color: '#4DABF5' }}*/}
        {/*/>*/}
        {/*<p className={styles.linksTitle}>Official Telegram Channel</p>
              </a>
            </Link>
          </Box>
          <Box className={styles.navItem2} ml={3}>
            <Link
              href="https://www.youtube.com/@MXBI_EN"
              target={isMobile ? '' : '_blank'}
              passHref
            >
              <a className={styles.link} target={isMobile ? '' : '_blank'}>
                {/*<YouTubeIcon*/}
        {/*  className={styles.linkIcon}*/}
        {/*  sx={{ color: '#4DABF5' }}*/}
        {/*/>*/}
        {/*<p className={styles.linksTitle}>Official YouTube Channel</p>
              </a>
            </Link>
          </Box>
        </Collapse>*/}
      </Box>
    );
  }, [
    isLoggedIn,
    profile,
    enabled,
    isKycConfirmed,
    prartnerAgrrementAccepted,
    showWelcomeBonusDialog,
    hasPackage,
    accessViewItem,
    handleClick,
    openCollapse,
    getClassByRoute,
    redirectToRouter,
  ]);

  return (
    <>
      <Box className={styles.containerMobile}>
        <button
          className={classNames(styles.buttonMobile, {
            [styles['activeButton']]: isOpenMenu,
          })}
          onClick={toggleMenu}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="#ffffff" />
          </svg>
        </button>
        <Link href="https://sca-innovative.com/" passHref>
          <button className={styles.logoButton}>
            <Image src={logo} alt="" style={{ width: 71, height: 83 }} />
          </button>
        </Link>
        <button
          className={classNames(styles.buttonMobile, {
            [styles['activeButton']]: isOpenWallet,
          })}
          onClick={toggleWallet}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 18V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H19C20.1 3 21 3.9 21 5V6H12C10.89 6 10 6.9 10 8V16C10 17.1 10.89 18 12 18H21ZM12 16H22V8H12V16ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z"
              fill="#ffffff"
            />
          </svg>
        </button>
      </Box>

      <Grow in={isOpenMenu}>
        <Box className={styles.collapse}>{navItemsList}</Box>
      </Grow>

      <Box
        className={classNames(
          styles.container,
          {
            [styles['shortMenu']]: hideTitles,
            [styles['widthShort']]: hideTitles,
            [styles['widthWide']]: !hideTitles,
          },
          'with-scroll',
        )}
      >
        <Box className={styles.logoWrapper}>
          <button type="button" className={styles.hamButton} onClick={toggleTitles}>
            <Image style={{ width: 24, height: 24 }} src={menuIcon} alt="" />
          </button>
          <Link href="https://sca-innovative.com/" passHref>
            <div className={styles.logo}>
              <Image style={{ width: 71, height: 83 }} src={logo} alt="" />
            </div>
          </Link>
        </Box>
        {navItemsList}
        <CustomDialog
          title={t('join_affiliate_programme.title')}
          open={showJoinPartnerDialog}
          handleClose={() => setShowJoinPartnerDialog(false)}
        >
          <PartnerAgreement
            callback={() => {
              setShowJoinPartnerDialog(false);
              getLinkTo247andPersonalAccount();
            }}
          />
        </CustomDialog>
        <CustomDialog
          open={showPurchasePackage}
          title={t('personal_data.purchase_package')}
          handleClose={() => setShowPurchasePackage(false)}
        >
          <Typography>{t('personal_data.dear_client')}</Typography>
          <Typography>{t('personal_data.content_1')}</Typography>
          {!asPath.startsWith('/staking') && (
            <Typography>{t('personal_data.content_2')}</Typography>
          )}
          <Box>
            {t('personal_data.please')}{' '}
            <Typography
              component={'span'}
              sx={{ margin: '0px 6px' }}
              onClick={() => setShowPurchasePackage(false)}
            >
              <Link href="/packages" passHref>
                {t('personal_data.purchase_any_package')}
              </Link>
            </Typography>
            {t('personal_data.first')}
          </Box>
        </CustomDialog>
        <CustomDialog
          title=""
          open={showClientAgreementDialog}
          handleClose={() => setShowClientAgreementDialog(false)}
        >
          <ServiceAgreement />
        </CustomDialog>
        <CustomDialog
          title=""
          open={showPartnershipAgreementDialog}
          handleClose={() => setShowPartnershipAgreementDialog(false)}
        >
          <PartnerAgreementContent />
        </CustomDialog>
        <CustomDialog
          title=""
          open={showStakingAgreementDialog}
          handleClose={() => setShowStakingAgreementDialog(false)}
        >
          <StakingAgreement />
        </CustomDialog>
        <CustomDialog
          title=""
          open={showPrivacyPolicyDialog}
          handleClose={() => setShowPrivacyPolicyDialog(false)}
        >
          <PrivacyPolicy />
        </CustomDialog>
      </Box>
      {isLoading && <Preloader />}
    </>
  );
};
