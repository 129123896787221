import { useReadExchangeGetUser } from '@app/contracts';
import { useAccount } from 'wagmi';
import { type Address } from 'viem';
import { usePackages } from './usePackages';

export const useUser = (): { user: any; isLoading: boolean; refetch: () => void } => {
  const { address } = useAccount();
  const { packages, isLoading: packagesLoading } = usePackages();
  const {
    data: user,
    isLoading: ownUserLoading,
    refetch,
  } = useReadExchangeGetUser({
    address: address && (process.env.EXCHANGE_ADDRESS! as Address),
    args: [address!],
  });

  const activePackage = packages?.find((p) => user?.packageId && BigInt(p.id) === user.packageId);
  return {
    isLoading: packagesLoading || ownUserLoading,
    user: {
      ...user,
      package: activePackage,
    },
    refetch,
  };
};

export type User = ReturnType<typeof useUser>['user'];
