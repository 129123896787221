import styles from './style.module.scss'
import React from 'react'
import Image from 'next/image'
import { IPreviewModalProps } from '@app/types'
import FitScreenIcon from '@mui/icons-material/FitScreen'
import { IconButton } from '@mui/material'
import { Box } from '@mui/material'

const ImagePreviewModal = (props: IPreviewModalProps) => {
  const { image } = props

  const [isFullSize, setIsFullSize] = React.useState(false)

  const toggleFullSize = () => {
    setIsFullSize(!isFullSize)
  }

  return (
    <Box className={isFullSize ? styles.outerLayerFullSize : styles.outerLayer}>
      <Box className={styles.wrapper}>
        <Image
          src={image}
          alt="sertificate"
          itemType={'image'}
          layout={'fill'}
          objectFit={'contain'}
        />
        <IconButton className={styles.btn}>
          <FitScreenIcon fontSize={'large'} onClick={toggleFullSize} />
        </IconButton>
      </Box>
    </Box>
  )
}

export default ImagePreviewModal
