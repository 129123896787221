import ErrorIcon from '@app/assets/icons/error.svg';
import SuccessIcon from '@app/assets/icons/success.svg';
import { useModal } from '@app/hooks';
import { ETxStatus, ITxStatus } from '@app/types';
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Image from 'next/image';
import { DefaultFetchDataErrorMsg } from '../../shared/DefaultFetchDataErrorMsg';
import styles from './style.module.scss';
import { useTranslation } from 'next-i18next';

interface ITxStatusModal {
  params: ITxStatus;
}

const TxStatus = ({
  params: { status = ETxStatus.LOADING, description = '', errorType },
}: ITxStatusModal) => {
  const { titleName } = useModal();
  const { t } = useTranslation();

  const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getTitle = () => {
    switch (status) {
      case 'loading':
        return `${titleName ? capitalizeFirst(titleName) + ` ${t('tx_status.confirmation')}` : t('tx_status.transaction_confirmation')}`;
      case 'success':
        return `${titleName ? capitalizeFirst(titleName) + ` ${t('tx_status.verified_successfully')}` : t('tx_status.successful_transaction')}`;
      case 'error':
        return 'An error has occurred';
      default:
        return `${titleName ? capitalizeFirst(titleName) + ` ${t('tx_status.confirmation')}` : t('tx_status.transaction_confirmation')}`;
    }
  };

  const getDescription = () => {
    switch (status) {
      case 'loading':
        return `${t('tx_status.please_wait')} ...`;
      case 'success':
        return description;
      case 'error':
        return (
          description || (
            <DefaultFetchDataErrorMsg type={errorType} linkClass={styles.modal__helpLink} />
          )
        );
      default:
        return `${t('tx_status.please_wait')} ...`;
    }
  };

  return (
    <Grid className={styles.modal}>
      <Grid container spacing={3} className={styles.modal__finish}>
        <Grid item xs={3} className={styles.modal__img}>
          {status === 'loading' && <CircularProgress size={60} />}
          {status === 'error' && <Image src={ErrorIcon} alt="error icon" />}
          {status === 'success' && <Image src={SuccessIcon} alt="success icon" />}
        </Grid>
        <Grid item xs={9} className={`${styles.modal__title_finish} white`}>
          <h2>{getTitle()}</h2>
        </Grid>
      </Grid>
      <Grid className={styles.modal__description}>{getDescription()}</Grid>
    </Grid>
  );
};

export default TxStatus;
