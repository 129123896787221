import {
  BLOCKCHAIN_HISTORY
} from '@app/constants';
import {
  IBlockchainHistoryData
} from '@app/types/transactions';
import { httpClient } from '../httpClient';

export const transactionsService = {
  async getBlockchainHistory() {
    const { data } = await httpClient.get<IBlockchainHistoryData>(
      BLOCKCHAIN_HISTORY
    );
    return data;
  },
};
