import { exchangeAbi, useWatchExchangeEvent } from '@app/contracts';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { Event } from './usePurchaseHistory';
import { type Address, Log } from 'viem';
import { ethers } from 'ethers';
import { addEventIfNotExists, getBlockByBlockHash } from '@app/utils/event';
import { useWatchPreviousEvents } from '@app/hooks/useWatchPreviousEvents';

export const useWithdrawalHistory = () => {
  const [events, setEvents] = useState(<Event[]>[]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);
  const [logs, setLogs] = useState<Log[]>();
  const { address }: any = useAccount();

  useEffect(() => {
    if (!address) return;
  });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.EXCHANGE_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.EXCHANGE_ADDRESS! as Address,
    event: 'SellTokens',
    args: {
      user: address,
    },
    onLogs(logs) {
      setLogs(logs);
    },
  });

  useWatchExchangeEvent({
    address: process.env.EXCHANGE_ADDRESS! as Address,
    eventName: 'SellTokens',
    args: {
      user: address,
    },
    poll: true,
    pollingInterval: 1000,
    onLogs(logs: Log[]) {
      setLogs(logs);
    },
    onError(error) {
      setIsHistoryLoading(false);
      console.warn('Error', error);
    },
  });

  useEffect(() => {
    setIsHistoryLoading(true);
    if (logs && logs.length > 0) {
      logs.forEach(async function (event: Log) {
        const decodedData = new ethers.Interface(exchangeAbi).parseLog({
          data: event.data,
          topics: [...event?.topics],
        });

        if (decodedData && decodedData?.args[1]) {
          const block = await getBlockByBlockHash(event.blockHash);

          const newEvent = {
            time: Number(block.timestamp),
            eventType: 'Withdrawal',
            package: undefined,
            tokens: BigInt(decodedData?.args[1]?.toString()),
            transactionHash: event?.transactionHash as string,
          };
          setEvents((currentEvents) => addEventIfNotExists<Event>(currentEvents, newEvent));
        }
      });
    }
    setIsHistoryLoading(false);
  }, [logs]);

  return { events, isLoading: isHistoryLoading };
};
