import process from 'process'
import { IReferralLinkProps } from '@app/types'

export const getInviterAddressParam = (): string | undefined => {
  return typeof window !== 'undefined'
    ? new URL(window.location.toString()).searchParams.get('inviter_wallet_address') || undefined
    : undefined
}

export const getReferralLink = ({ referralAddress, fundId }: IReferralLinkProps = {}): string => {
  let link = location.origin
  if (fundId && referralAddress) {
    link += `/staking/${fundId}?inviter_wallet_address=${referralAddress}`
  } else if (referralAddress) {
    link += `/?inviter_wallet_address=${referralAddress}`
  }
  return link
}

export const getLinkToGenerateQRCode = ({
  referralAddress,
  fundId,
}: IReferralLinkProps = {}): string => {
  let link = `${process.env.REACT_APP_HTTP_API_URL}/qr/generate`
  if (fundId && referralAddress) {
    link += `?fund_id=${fundId}&referral_address=${referralAddress}`
  } else if (referralAddress) {
    link += `?referral_address=${referralAddress}`
  }
  return link
}
