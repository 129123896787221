import { ReactElement } from 'react';

export * from './ui';
export * from './settings';
export * from './form';
export * from './packages';
export * from './transactions';
export * from './user';
export * from './modal';
export * from './fetchData';
export * from './sum-sub';

export interface ITokenBalancesQuery {
  stable: string;
  utility: string;
}

// @deprecated - use interfaces/errors
export interface IError {
  name?: string; //in provider activate
  code?: number; //in MM errors
  message?: string;
  status?: number; // server errors
}

// @deprecated - use interfaces/errors
export interface IServerError {
  statusCode: number;
  message: string;
}

export interface ISwap {
  utility: number;
  stable: number;
  checkIsProfileFilled: boolean; //false from permissions
}

export interface IDataSource {
  id: string;
  date: Date | string;
  packageName: string;
  packageValue: string;
  price: string;
  statusId: string;
}

export interface IVerificationRequest {
  message: string;
}

export enum ESnackbarSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum IGridPermission {
  OPERATION = 'operation',
  AMOUNT = 'amount',
  CREATED = 'created',
  INFO = 'info',
  CONFIRM = 'confirm',
}

export enum ESortTypes {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EAgreementTypes {
  client = 'client',
  partner = 'partner',
}

export interface IReferralLinkProps {
  header?: ReactElement;
  referralAddress?: string;
  fundId?: string;
}

export interface IPreviewModalProps {
  image: string;
}

export enum WithdrawalStatusEnum {
  REQUESTED = 'Requested',
  EXECUTED = 'Executed',
  REJECTED = 'Rejected',
  BLOCKED = 'Blocked',
}

export enum MigrationOperationResult {
  MIGRATION_NOT_NEEDED,
  MIGRATION_NEEDED,
  MIGRATION_COMPLETED,
}

export const SPECIAL_RATE: bigint = BigInt(10_000).valueOf();
