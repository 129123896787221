import { Web3AuthConnector } from '@web3auth/web3auth-wagmi-connector';
import { ModalConfig, Web3Auth } from '@web3auth/modal';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { CHAIN_NAMESPACES, WALLET_ADAPTERS, UX_MODE, OPENLOGIN_NETWORK_TYPE } from '@web3auth/base';
import { Chain } from 'wagmi/chains';
import { WalletServicesPlugin } from '@web3auth/wallet-services-plugin';
import { MetamaskAdapter } from '@web3auth/metamask-adapter';
import { WalletConnectV2Adapter } from '@web3auth/wallet-connect-v2-adapter';
import { getWalletConnectV2Settings } from '@web3auth/wallet-connect-v2-adapter';

export default function Web3AuthConnectorInstance(chains: Chain[]) {
  // Create Web3Auth Instance
  const name = 'SCA Alliance';
  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x' + chains[0].id.toString(16),
    rpcTarget: chains[0].rpcUrls.default.http[0], // This is the public RPC we have added, please pass on your own endpoint while creating an app
    // displayName: chains[0].name,
    displayName: 'Binance Smart Chain (BSC)',
    tickerName: chains[0].nativeCurrency?.name,
    ticker: chains[0].nativeCurrency?.symbol,
    blockExplorerUrl: chains[0].blockExplorers?.default.url[0] as string,
  };

  const privateKeyProvider = new EthereumPrivateKeyProvider({
    config: { chainConfig: chainConfig },
  });

  const web3AuthInstance = new Web3Auth({
    clientId: process.env.WEB3_AUTH_CLIENT_ID!,
    chainConfig,
    privateKeyProvider,
    uiConfig: {
      appName: name,
      loginMethodsOrder: ['google', 'facebook', 'twitter', 'apple', 'metamask', 'walletconnectv2'],
      defaultLanguage: 'en',
      modalZIndex: '2147483647',
      logoLight: `${process.env.APP_ADDRESS_HOST}/SCA_Alliance_1.png`,
      logoDark: `${process.env.APP_ADDRESS_HOST}/SCA_Alliance_1.png`,
      uxMode: UX_MODE.REDIRECT,
      mode: 'dark',
      theme: {
        primary: '#FEAF3C',
      },
    },
    web3AuthNetwork: process.env.WEB3_AUTH_SAPPHIRE_DEVNET as OPENLOGIN_NETWORK_TYPE,
    enableLogging: true,
  });

  const walletServicesPlugin = new WalletServicesPlugin({
    walletInitOptions: {
      whiteLabel: {
        hideNftDisplay: true,
        showWidgetButton: true,
      },
    },
  });

  web3AuthInstance.addPlugin(walletServicesPlugin);

  web3AuthInstance.configureAdapter(new MetamaskAdapter());

  const addWalletConnect = async () => {
    const wcSettings = await getWalletConnectV2Settings(
      'eip155',
      [chains[0]?.id?.toString()],
      process.env.WALLET_CONNECT_PROJECT_ID!,
    );

    web3AuthInstance.configureAdapter(
      new WalletConnectV2Adapter({
        adapterSettings: wcSettings.adapterSettings,
        loginSettings: wcSettings.loginSettings,
      }),
    );
  };
  addWalletConnect().then();

  const modalConfig: Record<string, ModalConfig> = {
    [WALLET_ADAPTERS.OPENLOGIN]: {
      label: 'openlogin',
      loginMethods: {
        facebook: {
          // it will hide the facebook option from the Web3Auth modal.
          name: 'facebook login',
          showOnModal: true,
        },
      },
      // setting it to false will hide all social login methods from modal.
      showOnModal: true,
    },
    [WALLET_ADAPTERS.METAMASK]: {
      label: 'MetaMask',
      loginMethods: {
        metamask: { name: 'MetaMask', showOnModal: true },
      },
    },
    [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
      label: 'WalletConnectV2',
      loginMethods: {
        walletconnectv2: { name: 'wallet_connect', showOnModal: true },
      },
    },
  };

  return {
    connector: Web3AuthConnector({
      web3AuthInstance,
      modalConfig,
    }),
    web3AuthInstance: web3AuthInstance,
  };
}
