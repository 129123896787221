import Image from 'next/image';
import ArrowLeft from 'src/assets/icons/arrowLeft.svg';
import styles from './style.module.scss';

type Props = {
  children: React.ReactNode;
  setIsOpen: (value: boolean) => void
}
// ! EMPRX-577: now former partner agreement is client, former client is privacy policy
export const AgreementLayout = ({ children, setIsOpen }: Props) => {
  return (
    <div>
      <div className={styles.backIcon}>
        <Image
          src={ArrowLeft}
          onClick={() => setIsOpen(false)}
          width={14}
          height={14}
          alt=""
        />
      </div>
      <div className={styles.agreement}>
        {children}
      </div>
    </div>
  );
};
