export enum FundType {
  RegularFunds,
  SpecialFunds,
  ModerateFunds,
}

export enum InsuranceInvestmentType {
  NONE,
  INSURANCE_UNDER_INVESTMENT,
  INSURANCE_OVER_INVESTMENT,
}

export interface IUnstakedNonCapturedProfit {
  name: string;
  id: string;
  fundId: number;
  volume: number;
  startTime: string;
  endTime: string;
  duration: number;
  defaultInterestRate: number;
  staked: number;
}

export interface IMutualFundsParticipation {
  name: string;
  id: string;
  fundId: number;
  volume: number;
  startTime: string;
  endTime: string;
  duration: number;
  defaultInterestRate: number;
  staked: number;
}

export interface IUpcomingFunds {
  name: string;
  id: string;
  fundId: number;
  volume: number;
  startTime: string;
  endTime: string;
  duration: number;
  defaultInterestRate: number;
  staked: number;
}

export interface IMetadataTicker {
  title: string;
  titleLink: string;
  link: string;
}

export interface IFundsById {
  name: string;
  id: string;
  fundId: number;
  volume: number;
  startTime: string;
  endTime: string;
  duration: number;
  defaultInterestRate: number;
  description: string;
  metadataTickers: IMetadataTicker[];
}

export interface IFundsByIdMetadataTickerFunds {
  name: string;
  id: string;
  fundId: number;
  volume: number;
  startTime: string;
  endTime: string;
  duration: number;
  defaultInterestRate: number;
  description: string;
  metadataTickerFunds: Object[];
}

export interface IUpcomingFundsData {
  data: IUpcomingFunds[];
  total: number;
}

export interface IMutualFundsParticipationData {
  data: IMutualFundsParticipation[];
  total: number;
}

export interface IUnstakedNonCapturedProfitData {
  data: IUnstakedNonCapturedProfit[];
  total: number;
}

export interface IContractsWithResult<T> {
  result?: T;
}
