import React, { useState } from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import { useProfile, useSumSub } from '@app/hooks';
import { Grid } from '@mui/material';
import styles from './style.module.scss';
import { IMessagePayload } from '@app/types';

const SumSubVerification: React.FC = () => {
  const { data: kycVerification, isLoading } = useSumSub();
  const { refetch } = useProfile();
  const [idDocSetTypes, setIdDocSetTypes] = useState<string[]>([]);

  const handleOnMessage = (data: string, payload: IMessagePayload) => {
    console.log('onMessage', data, payload);
    if (payload?.idDocSetType && !idDocSetTypes.includes(payload.idDocSetType)) {
      refetch?.();
      setIdDocSetTypes([...idDocSetTypes, payload.idDocSetType]);
    }
  };

  return (
    <Grid container className={styles.modal}>
      {kycVerification?.accessToken?.token && !isLoading && (
        <SumsubWebSdk
          testEnv={false}
          accessToken={kycVerification.accessToken.token}
          expirationHandler={() => Promise.resolve(kycVerification?.accessToken?.token)}
          config={{
            lang: 'en',
          }}
          options={{ adaptIframeHeight: true }}
          onMessage={handleOnMessage}
          onError={(data: any) => console.log('onError', data)}
        />
      )}
    </Grid>
  );
};

export default SumSubVerification;
