import styles from './style.module.scss';
import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { EButtonType } from '@app/types';
import { Button } from '@app/components/shared/Button';
import { NotificationsKeys, NotificationStatusEnum } from '@app/constants';
import { useModal } from '@app/hooks';
import { useTranslation } from 'next-i18next';

const Notification = ({ content }: { content: ReactNode }) => {
  const { close } = useModal();
  const { t } = useTranslation();

  const handleHideForeverClick = () => {
    localStorage.setItem(NotificationsKeys.ReleaseOfUscaToken, NotificationStatusEnum.HideForever);
    close();
  };

  const handleDismissClick = () => {
    localStorage.setItem(NotificationsKeys.ReleaseOfUscaToken, NotificationStatusEnum.Dismiss);
    close();
  };

  return (
    <Box className={styles.outerLayer}>
      <Box className={styles.wrapper}>{content}</Box>
      <Box className={styles.buttonWrapper}>
        <Button type={EButtonType.OUTLINED} onClick={handleHideForeverClick}>
          {t('notification_button.hide_forever')}
        </Button>
        <Button type={EButtonType.FILLED} onClick={handleDismissClick}>
          {t('notification_button.dismiss')}
        </Button>
      </Box>
    </Box>
  );
};

export default Notification;
