import React, { useState, useEffect } from 'react';
import {
  ThemeProvider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material';
import { currenciesSelectTheme } from '@app/muiThemes/currenciesSelect';
import { type Address } from 'viem';
import { useTranslation } from 'next-i18next';

export interface ICurrency {
  title: string;
  address: Address;
  currency: string;
}

const dataCurrency: ICurrency[] = [
  {
    title: 'USDT (BEP-20)',
    address: process.env.USDT_ADDRESS! as Address,
    currency: 'USDT',
  },
  {
    title: 'USDC (BEP-20)',
    address: process.env.USDC_ADDRESS! as Address,
    currency: 'USDC',
  },
];

const CurrenciesSelect: React.FC<{
  handlCurrencyChange: (item: ICurrency | undefined) => void;
}> = ({ handlCurrencyChange }) => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState<string>(dataCurrency[0].address);

  useEffect(() => {
    handlCurrencyChange(dataCurrency[0]);
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof currency>) => {
    const selectedValue = event.target.value as string;
    setCurrency(selectedValue);
    handlCurrencyChange(dataCurrency.find((item) => item.address === selectedValue));
  };

  return (
    <ThemeProvider theme={currenciesSelectTheme}>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-name-label" sx={{ color: 'white' }}>
          {t('currencies_select.choose_currency')}
        </InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={currency}
          onChange={handleChange}
          input={<OutlinedInput label={t('currencies_select.choose_currency')} />}
          sx={{ color: 'white', borderColor: 'white' }}
        >
          {dataCurrency.map((item) => (
            <MenuItem key={item.title} value={item.address}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default CurrenciesSelect;
