import { BLOCKCHAIN_HISTORY } from '@app/constants';
import { httpClient } from '../httpClient';

export type BonusPackage = {
  id: number;
  package_title: string;
  package_id: string;
  // created_at: string;
};

export type BonusGiftPayload = {
  args: any[];
  sig: string;
};

export type BonusHistory = {
  data: BonusPackage[];
  total: number;
};

export const bonusPackagesService = {
  async getBonusPackageHistory(params: {
    address: string;
    page?: number;
    pageSize?: number;
  }): Promise<BonusHistory> {
    const { data } = await httpClient.get<{
      total: number;
      data: BonusPackage[];
    }>('/user/bonus/history', { params });
    return data;
  },

  async getGiftSignature(params: { bonusPackageId: number; to: string }) {
    const { data } = await httpClient.get<BonusGiftPayload>(
      '/user/bonus/gift-signature',
      { params }
    );
    return data;
  },
};
