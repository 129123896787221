import {
  useWriteBonusEvaluatorClaimBonus,
  useReadBonusEvaluatorBonusAllowanceOf,
  useReadBonusEvaluatorGetEnabled,
  useSimulateBonusEvaluatorClaimBonus,
} from '@app/contracts';
import { useUser } from '@app/hooks/useUser';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAccount, useWaitForTransactionReceipt } from 'wagmi';
import { useQueryClient } from '@tanstack/react-query';
import { type Address } from 'viem';
import { CustomDialog } from '../shared/Dialog';
import {
  NoConnectedAccountContent,
  OnlyBonusPackageContent,
  PackageNotPurchasedContent,
  WelcomeBonusContent,
} from './compomnents';
import { useTranslation } from 'next-i18next';
import { useBalanceAndGasChecker } from '@app/hooks/useBalanceAndGasChecker';

export const GetWelcomeBonusDialog: React.FC<{
  handleClose: (isShow: boolean) => void;
  open: boolean;
}> = ({ handleClose, open }) => {
  const wagmiClient = useQueryClient();
  const { user } = useUser();
  const { address, isConnected } = useAccount();
  const { t } = useTranslation();
  const { data: enabled } = useReadBonusEvaluatorGetEnabled({
    address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
  });

  const { data: welcomeBonusAllowance } = useReadBonusEvaluatorBonusAllowanceOf({
    address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
    args: [address!, user?.package?.size!],
  });

  const state = useMemo(() => {
    if (!isConnected) return 'noConnected' as const;
    else if (user.packageId === BigInt(0) ?? true) return 'notPurchased' as const;
    else if (!enabled ?? false) return 'noBonus' as const;
    else if (welcomeBonusAllowance === BigInt(0) ?? false) return 'bonusClaimed' as const;
    else if ((welcomeBonusAllowance && welcomeBonusAllowance > BigInt(0)) ?? false)
      return 'bonusAvailable' as const;
  }, [isConnected, welcomeBonusAllowance, enabled, user.packageId]);

  const [isLoading, setIsLoading] = useState(false);

  const { data: claimBonusConfig } = useSimulateBonusEvaluatorClaimBonus({
    address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
    query: {
      enabled: state === 'bonusAvailable',
    },
  });

  const { data: claimBonusData, writeContract: claimBonus } = useWriteBonusEvaluatorClaimBonus();

  const { isSuccess } = useWaitForTransactionReceipt({
    hash: claimBonusData,
  });

  const { validateFunds: claimBonusValidateFunds } = useBalanceAndGasChecker({
    onSuccess: () => {
      setIsLoading(true);
      claimBonus(claimBonusConfig!.request);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      wagmiClient.invalidateQueries({
        predicate: (query: any) => {
          const key = query.queryKey[0] as { address: string };
          return key.address === process.env.BONUS_EVALUATOR_ADDRESS;
        },
      });
      handleClose(false);
      setIsLoading(false);
    }
  }, [isSuccess]);

  return (
    <CustomDialog
      open={open}
      title={t('get_welcome_bonus.title')}
      handleClose={() => handleClose(false)}
    >
      <Box>
        <Typography>{t('get_welcome_bonus.dear_client')}</Typography>

        {/* If a person has not connected an account */}
        {state === 'noConnected' && <NoConnectedAccountContent />}

        {/* Button behavior: if the package is not purchased, */}
        {state === 'notPurchased' && <PackageNotPurchasedContent handleClose={handleClose} />}

        {/* If the welcome bonus has already been received */}
        {state === 'bonusClaimed' && (
          <Box>
            <Typography>{t('get_welcome_bonus.already_received')}</Typography>
          </Box>
        )}

        {/* if he has a 10 percent bonus available */}
        {state === 'bonusAvailable' && <WelcomeBonusContent />}

        {/* If a person received a bonus package, but he did not have a purchased package, */}
        {state === 'noBonus' && <OnlyBonusPackageContent />}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
        {state === 'bonusAvailable' && (
          <LoadingButton onClick={claimBonusValidateFunds} variant="contained" loading={isLoading}>
            {t('get_welcome_bonus.redeem')}
          </LoadingButton>
        )}
        {state !== 'bonusAvailable' && (
          <Button onClick={() => handleClose(!open)} variant="outlined">
            {t('get_welcome_bonus.dismiss')}
          </Button>
        )}
      </Box>
    </CustomDialog>
  );
};
