import { exchangeAbi, useReadExchangeListPackages } from '@app/contracts';
import { type Address } from 'viem';
import { useRate } from './useRate';
import { SPECIAL_RATE } from '@app/types';
import { UseReadContractReturnType } from 'wagmi';
import { useEffect, useState } from 'react';

// @ts-ignore
export type Package = UseReadContractReturnType<typeof exchangeAbi, 'listPackages'>[number] & {
  price: (current: { size: bigint } | undefined) => Record<string, bigint>;
  value: (current: { size: bigint } | undefined) => bigint;
};

export const usePackages = (): { packages: any[]; isLoading: boolean; refetch: () => void } => {
  const {
    data: packages,
    isLoading,
    refetch,
  } = useReadExchangeListPackages({
    address: process.env.EXCHANGE_ADDRESS as Address,
  });

  const { rate, isLoading: isRateLoading } = useRate();
  const [data, setData] = useState<any>();

  useEffect(() => {
    setData({
      packages:
        packages
          ?.filter((p) => !p.deleted)
          ?.map((p) => {
            return {
              ...p,
              value: (current: { size: bigint } | undefined) =>
                p.size - ((current?.size ?? BigInt(0)) * SPECIAL_RATE) / SPECIAL_RATE,
              price: (current: { size: bigint } | undefined) => ({
                usdt: rate
                  ? p.size - ((current?.size ?? BigInt(0)) * SPECIAL_RATE) / SPECIAL_RATE
                  : BigInt(0),
              }),
            };
          }) ?? [],
    });
  }, [rate, packages]);

  return {
    ...data,
    isLoading: isLoading || isRateLoading,
    refetch,
  };
};
