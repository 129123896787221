import React, { FC } from 'react';
import { useCountdown } from '@app/hooks';
import styles from './style.module.scss';
import { Box } from '@mui/material';

export const CountdownTimer: FC<{ targetDate: number }> = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  const dateTimeDisplay = (value: number, type: string) => {
    return (
      <Box className={styles.countdown}>
        <p>
          {value < 10 ? 0 : ''}
          {value}
        </p>
        <span>{type}</span>
      </Box>
    );
  };

  const expiredNotice = () => {
    return (
      <Box className="expired-notice">
        <span>DONE</span>
      </Box>
    );
  };

  const showCounter = (days: number, hours: number, minutes: number, seconds: number) => {
    return (
      <Box className="show-counter">
        <a rel="noopener noreferrer" className={styles.container}>
          {days > 0 && dateTimeDisplay(days, 'Days')}
          {days > 0 && <p>:</p>}
          {dateTimeDisplay(hours, 'Hours')}
          <p>:</p>
          {dateTimeDisplay(minutes, 'Mins')}
          <p>:</p>
          {dateTimeDisplay(seconds, 'Seconds')}
        </a>
      </Box>
    );
  };

  if (days + hours + minutes + seconds <= 0) {
    return expiredNotice();
  }

  return showCounter(days, hours, minutes, seconds);
};
