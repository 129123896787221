import { InstallWalletModal } from './InstallWalletModal';
import { NeedInviterAddressAndAgreement } from './NeedInviterAddressAndAgreement';
import { IModalContext } from '@app/stores/modal/modalContextTypes';
import { ECurrentModal } from '@app/types';

type IAuthModals = {
	currentModal: IModalContext['currentModal'],
}

const AuthModals = ({ currentModal }: IAuthModals) => {
  return (
    <>
      {currentModal === ECurrentModal.AUTH__NEED_MM &&
				<InstallWalletModal />
      }
      {currentModal === ECurrentModal.AUTH__NEED_INVITER_ADDRESS &&
				<NeedInviterAddressAndAgreement />
      }
    </>
  );
};

export default AuthModals;
