import { useReadExchangeGetExchangeRate } from '@app/contracts';
import { type Address } from 'viem';

export const useRate = (coin = process.env.USDT_ADDRESS as Address) => {
  const { data: rate, isLoading } = useReadExchangeGetExchangeRate({
    address: process.env.EXCHANGE_ADDRESS as Address,
    args: [coin],
  });

  return { rate, isLoading };
};
