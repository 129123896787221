import { AxiosError } from 'axios';

// interfaces
export type IError = AxiosError | IMMError | unknown;

export interface IServerError {
  data: {
    status: number,
    message: string,
  }
}

export interface IMMError {
  code: number,
  message: string,
  stack?: string,
}

export interface IUnclearMMError {
  error: IMMError | undefined,
}

export interface IUnknownError {
  message: string,
  stack?: string,
}

export interface IJsonRpcError {
  code: number,
  data: string,
  message: string,
}

// enums
export enum EErrorType {
  MM = 'Metamask',
  JSONRPC = 'JSON RPC',
  SERVER = 'Server',
}
