import React, { FC, useEffect, useMemo, useState } from 'react';
import { useModal, useProfile } from '@app/hooks';
import { NEED_INVITER_ADDRESS, useAuthtoken } from './store';
import { ECurrentModal, MigrationOperationResult } from '@app/types';
import { CustomDialog } from '@app/components/shared/Dialog';
import { Typography } from '@mui/material';
import { getInviterAddressParam } from '@app/utils/url';
import ReleaseOfUSCATokenContent from '@app/components/Notifications/ReleaseOfUSCATokenContent';
import { NotificationsKeys, NotificationStatusEnum } from '@app/constants';
import { useAccount, useBalance } from 'wagmi';
import type { Address } from 'viem';
import { useTriggerRefresh } from '@app/hooks/useTriggerRefresh';
import { useReadTokenConverterGetMigrationStatus } from '@app/contracts';

export const AuthContext: FC<{ children: React.ReactElement }> = ({ children }) => {
  const authtoken = useAuthtoken();
  const { data: profile, isLoggedIn } = useProfile();
  const {
    setCurrentModal,
    currentModal,
    setNotificationContentProps,
    isOpen: isOpenCurrentModal,
  } = useModal();
  const [showConnectionLost, setShowConnectionLost] = useState(false);
  const [modalQueue, setModalQueue] = useState<ECurrentModal[]>([]);
  const { address } = useAccount();

  useEffect(() => {
    if (modalQueue.length > 0 && !currentModal) {
      const nextModal = modalQueue[0];
      setCurrentModal(nextModal);
      setModalQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [currentModal, modalQueue, setCurrentModal]);

  useEffect(() => {
    if (isLoggedIn && profile && !profile.user) {
      setModalQueue((prevQueue) => [...prevQueue, ECurrentModal.FILL_PROFILE]);
    }
  }, [isLoggedIn, setCurrentModal, profile]);

  useEffect(() => {
    const inviterAddressParam = getInviterAddressParam();

    if (inviterAddressParam && !isLoggedIn) {
      setModalQueue((prevQueue) => [...prevQueue, ECurrentModal.REFERRAL_LINK_LOGIN]);
    } else if (authtoken === NEED_INVITER_ADDRESS) {
      setModalQueue((prevQueue) => [...prevQueue, ECurrentModal.AUTH__NEED_INVITER_ADDRESS]);
    } else if (inviterAddressParam && isLoggedIn) {
      setCurrentModal('');
    }
  }, [authtoken, setCurrentModal, isLoggedIn]);

  useEffect(() => {
    if (getInviterAddressParam()) {
      localStorage['inviter_wallet_address'] = getInviterAddressParam();
      localStorage['inviter_wallet_address_locked'] = true;
    }
  }, []);

  const { data: oldSCA, refetch: refetchBalanceOldSca } = useBalance({
    token: process.env.OLD_SCA_ADDRESS as Address,
    address,
  });

  const { data: migrationStatus, error: migrationStatusError } =
    useReadTokenConverterGetMigrationStatus({
      address: process.env.TOKEN_CONVERTER_ADDRESS! as Address,
      args: [address!],
      query: {
        enabled: !!address,
      },
    });

  const isAvailableOldSCABalance = useMemo(() => {
    return oldSCA?.formatted && Number(oldSCA?.formatted) > 0.1;
  }, [oldSCA?.formatted]);

  const isMigrationNeeded = migrationStatus === MigrationOperationResult.MIGRATION_NEEDED;

  useEffect(() => {
    const releaseOfUscaToken = localStorage.getItem(NotificationsKeys.ReleaseOfUscaToken);

    if (!(isAvailableOldSCABalance && isMigrationNeeded)) return;
    if (isOpenCurrentModal) return;

    if (
      isLoggedIn &&
      profile &&
      !!profile.user &&
      releaseOfUscaToken !== NotificationStatusEnum.HideForever
    ) {
      setModalQueue((prevQueue) => [...prevQueue, ECurrentModal.NOTIFICATION]);
      setNotificationContentProps(<ReleaseOfUSCATokenContent />);
    }
  }, [
    isLoggedIn,
    profile,
    setCurrentModal,
    setNotificationContentProps,
    oldSCA,
    isAvailableOldSCABalance,
    isMigrationNeeded,
    isOpenCurrentModal,
  ]);

  useTriggerRefresh(refetchBalanceOldSca);

  return (
    <>
      {children}

      <CustomDialog
        title="Connection was lost"
        open={showConnectionLost}
        handleClose={() => setShowConnectionLost(false)}
      >
        <Typography>Dear Client,</Typography>
        <Typography>Please check your Internet connection and try it again</Typography>
      </CustomDialog>
    </>
  );
};
