import styles from '../style.module.scss';
import classnames from 'classnames';
import classNames from 'classnames';

export const StakingAgreement = () => {
  return (
    <div className={classnames(styles.wrapper, 'with-scroll')}>
      <h1 className={styles.h1}>STAKING AGREEMENT</h1>
      <div className={styles.agreementDate}>
        <p className={styles.p}>#1.000.2-17.02/2023-STKGN</p>
        <p className={styles.p}>17/02/2023</p>
      </div>
      <h2 className={styles.h2}>Staking Agreement</h2>
      <section className={styles.section}>
        <h2 className={styles.h2}>Preamble</h2>
        <p className={styles.p}>This Stacking Agreement (the &quot;Agreement&quot;) governs the legal relationship
					between SCA
					Inc. (the &quot;Company&quot;) and you (the &quot;Client&quot;).
        </p>
        <p className={styles.p}>This Agreement shall be used for token-stacking on the SCA Project (<a href={'https://sca-innovative.com/'}>https://sca-innovative.com/</a>)
					and all sub-domains of the Project (the &quot;Project&quot;) for the purpose of receiving staking fees, as
					defined
					below, and shall not be used for any other services offered by the Project now or in the future.
        </p>
        <p className={styles.p}>The Client declares that, on behalf of himself or on behalf of an institution or
					company, the Client may legally place tokens in the Project and comply with the terms listed in this
					Agreement, as well as in the Project&apos;s Trading Rules.
        </p>
        <p className={styles.p}>BY PLACING MTOK TOKENS IN THE PROJECT&apos;S STACKING SYSTEM, YOU AGREE TO THE TERMS AND
					CONDITIONS, MAKE THE STATEMENTS AND WARRANTIES SET FORTH IN THIS AGREEMENT, AND BECOME A PARTY TO THIS
					STACKING AGREEMENT.
        </p>
        <p className={styles.p}>IF YOU DO NOT AGREE TO ALL OR SOME OF THE TERMS OF THIS AGREEMENT, OR IF ANY OF THE
					STATEMENTS AND WARRANTIES SET FORTH HEREIN ARE INACCURATE WITH RESPECT TO YOU, YOU MUST NOT PLACE TOKENS FOR
					THE STACKING SERVICE OR OTHERWISE USE THE SCA PROJECT SERVICES.
        </p>
      </section>
      <section className={styles.section}>
        <h2 className={styles.h2}>Definitions</h2>
        <p className={styles.p}>Terms used in this Agreement with capitalized initial letters have the meanings ascribed
					to them in this section. Any term defined in the singular shall have a corresponding definition in the plural
					(and vice versa).
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Agreement&quot;</b> means this Stacking Agreement, all of its terms and
					conditions, and
					all Exhibits to this
					Agreement.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Confidential information&quot;</b> means all non-public information disclosed
					by one
					party to another party in
					connection with this Agreement that the disclosing party notes as confidential.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;USCA token&quot;</b> – means the utilitarian token of the SCA project, the
					value of
					this token depends on the results of trading on cryptocurrency exchanges and other exchanges.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;MTOK Token&quot;</b> – means an SCA project stable token, which cannot be
					used for any
					purpose other than participation in Project Staking and always has a fixed value: 1 USCA Token equals $1.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Effective date&quot;</b> means the date on which Client places MTOK tokens in
					the SCA project Stacking.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Steaking fees&quot;</b> - tokens distributed based on the profit that the
					Project has made as a result of conducting business activities. The Steaking fee is always equal to the
					pre-specified % value indicated on the Project&apos;s web resources. Staking fees shall always be paid in USCA
					tokens at the exchange rate applicable on the date of payment.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Client&quot;</b> means a Party using the services of the Project to receive
					Steaking Fees.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Party&quot;</b> means the Company or the Client, as the case may be, and
					&quot;Parties&quot; means the Company and the Client together.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Platform&quot;</b> means SCA&apos;s technology platform and infrastructure
					that the Company uses to provide the Steaking service, as well as the Project&apos;s web resources involved in
					the
					process of providing the Steaking service.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Steaking&quot;</b> means Client&apos;s use of the Project&apos;s services to
					receive steaking fees.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Supported network&quot;</b> means the Binance Smart Chain (BSC) open-source
					blockchain network, as well as smart contracts on the specified network on which our Platform can provide
					Stacking services.
        </p>
        <p className={styles.p}>
          <b className={styles.bold}>&quot;Web Site&quot;</b> means any web site operated and maintained by Project
          SCA, including but not limited to <a href={'https://sca-innovative.com/'}>https://sca-innovative.com/</a>.
        </p>
      </section>
      <section className={styles.section}>
        <ol className={styles.ol}>
          <li className={styles.bold}>General
            <ol className={classNames(styles.ol, styles.nestedOl)}>
              <li>This agreement is a Firm Offer. A firm offer is an offer addressed to a strictly defined (limited)
								circle of persons, who are the Company&apos;s Clients in accordance with the Client agreement, effective
								only
								for as long as such persons remain Clients and Partners of the Company, and related to the acceptance of
								additional rights and obligations associated with partnership activities.
              </li>
              <li>This Agreement shall become effective immediately when the Client places MTOK tokens in the SCA
								project Staking.
              </li>
              <li>The Agreement is concluded by the Parties indefinitely as long as the Parties are engaged in
								activities related to the implementation of the terms of this Agreement.
              </li>
              <li>The client confirms that he/she is a legally capable and adult according to the local laws of the
								country of residence and enters into this agreement voluntarily, at his/her own discretion, after having
								received financial advice.
              </li>
              <li>The Client is notified of the possible risks, as well as of responsibility for non-performance and/or
								improper performance of the terms and conditions of this Agreement.
              </li>
              <li>The Parties agree to treat electronic and paper (&quot;hard&quot;) copies of this agreement as equal
								in value.
              </li>
              <li>In order to comply with the requirements of international legislation aimed at combating money
								laundering and terrorist financing, the Client guarantees his/her refusal to use Staking services for
								money laundering, sponsorship of terrorism, etc.
              </li>
            </ol>
          </li>
          <li className={styles.bold}>Obligations
            <ol className={classNames(styles.ol, styles.nestedOl)}>
              <li>During the Term (as defined below), the Project will provide the following:
                <ol className={classNames(styles.ol, styles.nestedOl)}>
                  <li>The platform will operate with fairness and professionalism and in accordance with applicable
										industry standards.
                  </li>
                  <li>The Company declares that the Stacking Fee is accrued as a result of profits from the
										Company&apos;s
										trading activity on the international stock market.
                  </li>
                  <li>The Platform is guaranteed to pay the Steaking Fee at the end of the Steaking Period.
                  </li>
                  <li>The Platform will use reasonable security measures to protect the integrity and availability of
										services.
                  </li>
                </ol>
              </li>
              <li>During the Term, Client understands, agrees and acknowledges the following:
                <ol className={classNames(styles.ol, styles.nestedOl)}>
                  <li>The Client expressly and explicitly confirms that he is familiar with this Agreement and
										unconditionally accepts all paragraphs of the Agreement.
                  </li>
                  <li>The Client expressly and explicitly agrees that the Company&apos;s trading activity on the
										international stock market constitutes the Company&apos;s Commercial Secret and may not be disclosed
										in
										any way.
                  </li>
                  <li>MTOK Token Stacking means that the Client signs a smart contract for Stacking, which leads to the
										blocking of the amount of MTOK Tokens provided by the Client for Stacking.
                  </li>
                  <li>When the Steaking expires, the smart contract ends automatically. The Client receives back the
										amount of MTOK Tokens sent to the Steaking and the Steaking Fee in the form of a pre-specified
										percentage of the fee on the Project website.
                  </li>
                  <li>The Client may unlock the MTOK Tokens in the Stacking system at any time on his/her own, but
										he/she will be deprived of the unclaimed Stacking Fee for the unlocked amount of the MTOK Tokens.
                  </li>
                  <li>В The Stacking Fee for MTOK Tokens is credited to the Client&apos;s wallet in USCA Tokens, the
										price of
										which is volatile and confirmed by the rate on cryptocurrency exchanges, where the USCA Token is
										placed as part of the trading pair.
                  </li>
                </ol>
              </li>
              <li>Tokens may be placed or withdrawn at the discretion of Clients, but in all cases tokens placed will be
								subject to the Binance Smart Chain (BSC) network protocol. This includes, but is not limited to,
								transaction fees on the Supported Network, as well as minimum balances and required Token amounts.
              </li>
              <li>THE CLIENT IS RESPONSIBLE FOR THE SECURITY OF HIS/HER ACCOUNTS AND KEYS AT ALL TIMES.</li>
              <li>THE SCA PROJECT WILL NEVER ASK FOR PRIVATE KEYS UNDER ANY CIRCUMSTANCES.</li>
              <li>THE SCA PROJECT WILL NOT STORE THE CLIENT&apos;S PRIVATE KEYS AND WILL NOT ACT AS A CUSTODIAN OF THE
								CLIENT&apos;S FUNDS.
              </li>
              <li>Client is expected to have knowledge of blockchain technology, stacking, credentials, keys and details
								about the Supported Network and the Supported Network Protocol. In addition, they are expected to
								conduct their own thorough investigation of the Supported Network, Tokens, and other matters addressed
								in this Agreement when deciding whether to place Tokens using the Service.
              </li>
            </ol>
          </li>
          <li className={styles.bold}>
						Privacy
            <ol className={classNames(styles.ol, styles.nestedOl)}>
              <li>In accordance with the terms of this Agreement, the Parties undertake not to disclose confidential
								information that becomes known to the Parties in the course of the relevant relationship. Such
								information includes, in particular, personal (private) data of the Client, Commercial Secret of the
								Client and/or the Company and/or official partners of the Company and/or Affiliated legal entities of
								the Company, as well as other specific information, which should not become and at the moment is not
								publicly available information.
              </li>
            </ol>
          </li>
          <li className={styles.bold}>
						Settlement of disputable situations
            <ol className={classNames(styles.ol, styles.nestedOl)}>
              <li>All disputes or claims arising from the provisions of this Agreement, the Parties will try to settle
								by correspondence or negotiations. The claim of the Client can be accepted by the Company for
								consideration only in written form and not later than three calendar days from the moment of occurrence
								of a disputable situation.
              </li>
              <li>The Company shall review the claim within no more than fifteen (15) business days.
              </li>
              <li>
								If the Client had the intention to perform any action, but did not perform it for any reason, the
								Company shall not compensate the Client for any lost profits or losses incurred as a result thereof and
								shall not compensate the Client for moral damages.
              </li>
              <li>In the event of a dispute, that is not expressly stipulated in this Agreement, the Company will make
								the final decision on the claim based on generally accepted market practice and notions of fair
								settlement of the disputed situation.
              </li>
              <li>If the parties are unable to resolve a dispute through negotiation, disputes shall be resolved through
								the court procedure established by law at the place of registration of the Company.
              </li>
            </ol>
          </li>
        </ol>
      </section>
    </div>
  );
};
