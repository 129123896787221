import { useCallback, useEffect, useState } from 'react';
import { Connector, useAccount, useConnect, useDisconnect } from 'wagmi';
import { useProfile } from '@app/hooks/useProfile';
import { useWeb3Auth } from '../../pages/_app';
import { loadingAuthSignal } from '@app/stores/auth/store';
import { getWalletNames, WalletNamesEnum } from '@app/utils/wallets';
import { isMobileBrowser } from '@app/helpers';
import { IUseConnectAuthFormReturn } from '@app/types/auth-form';
import { ADAPTER_STATUS } from '@web3auth/base';

export const useConnectAuthForm = (): IUseConnectAuthFormReturn => {
  const [walletNameToInstall, setWalletNameToInstall] = useState<WalletNamesEnum>();
  const [showInstallWalletModal, setShowInstallWalletModal] = useState(false);
  const { disconnect } = useDisconnect();
  const { refetch } = useProfile();
  const [isLoading, setLoading] = useState(false);
  const [isWeb3AuthConnected, setIsWeb3AuthConnected] = useState(false);
  const [intervalId, setIntervalId] = useState<any | null>(null);
  // @ts-ignore
  const { web3Auth } = useWeb3Auth();
  const { isConnected } = useAccount();

  const connectedWalletNames = getWalletNames();

  const { connect, connectors, isPending, error, isError } = useConnect({
    mutation: {
      onSuccess: async (data) => {
        await refetch();
      },
      onError(error) {
        disconnect();
        setLoading(false);
      },
    },
  });

  const hiddenW3aContainer = () => {
    try {
      const w3aContainer = document?.getElementById?.('w3a-container');
      const walletIframe = document?.getElementById?.('walletIframe');
      if (walletIframe) walletIframe.style.display = 'none';
      if (w3aContainer) w3aContainer.style.display = 'none';
    } catch (e) {
      console.warn(e);
    }
  };

  const onDisconnect = useCallback(() => {
    disconnect();
    hiddenW3aContainer();
  }, [disconnect, window]);

  useEffect(() => {
    const checkWeb3AuthStatus = () => {
      if (web3Auth?.status === ADAPTER_STATUS.CONNECTED && isConnected) {
        onDisconnect();
      }

      if (web3Auth?.status === ADAPTER_STATUS.READY) {
        setIsWeb3AuthConnected(true);
        if (intervalId) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      }
    };

    if (!intervalId) {
      setIntervalId(setInterval(checkWeb3AuthStatus, 1000));
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [web3Auth, intervalId]);

  useEffect(() => {
    const subscription = loadingAuthSignal.subscribe((loading: boolean) => {
      setLoading(loading);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [loadingAuthSignal]);

  const handleWalletConnect = (connector: Connector) => {
    setLoading(true);
    const connectorName = connector.name as WalletNamesEnum;
    const w3aContainer = document.getElementById('w3a-container');
    const walletIframe = document.getElementById('walletIframe');
    if (connectorName === WalletNamesEnum.Web3Auth) {
      if (walletIframe) walletIframe.style.display = '';

      if (w3aContainer) w3aContainer.style.display = '';
    } else {
      if (walletIframe) walletIframe.style.display = 'none';
      if (w3aContainer) w3aContainer.style.display = 'none';
    }
    const isTrustWalletAbsent =
      connectorName === WalletNamesEnum.TrustWallet &&
      !connectedWalletNames.includes(connectorName);
    const isTrustWalletMobileAbsent = isTrustWalletAbsent && isMobileBrowser();
    const isMetaMaskAbsent =
      connectorName === WalletNamesEnum.MetaMask && !connectedWalletNames.includes(connectorName);
    const isMetaMaskMobileAbsent = isMetaMaskAbsent && isMobileBrowser();

    switch (true) {
      case isTrustWalletMobileAbsent:
        window.open(`https://link.trustwallet.com/open_url?url=${window.location.href}`);
        break;
      case isTrustWalletAbsent:
        setWalletNameToInstall(WalletNamesEnum.TrustWallet);
        setShowInstallWalletModal(true);
        break;
      case isMetaMaskMobileAbsent:
        window.open(`https://metamask.app.link/dapp/${window.location.href}`);
        break;
      case isMetaMaskAbsent:
        setWalletNameToInstall(WalletNamesEnum.MetaMask);
        setShowInstallWalletModal(true);
        break;
      default: {
        connect({ connector });
      }
    }
  };

  const handleAccountsChanged = (accounts: any) => {
    disconnect();
  };

  useEffect(() => {
    if (typeof (window as any).ethereum !== 'undefined') {
      (window as any).ethereum.on('accountsChanged', handleAccountsChanged);
    }
  }, [connectedWalletNames]);

  useEffect(() => {
    if (isError && error?.name === 'Error') {
      const walletConnectConnector = connectors.find(
        (connector: Connector) => connector?.name === WalletNamesEnum.WalletConnect,
      ) as Connector;

      connect({ connector: walletConnectConnector });
    }
  }, [connect, isError, error, connectors]);

  return {
    connectors,
    isPending,
    isLoading,
    isWeb3AuthConnected,
    walletNameToInstall,
    showInstallWalletModal,
    connectedWalletNames,
    handleWalletConnect,
    setShowInstallWalletModal,
    onDisconnect,
  };
};
