import React from 'react';
import { PACKAGES_PAGE } from 'routesConfig';
import { Package, usePackages } from '@app/hooks';
import { packageNameConverter } from '@app/utils/packages';
import { formatEther } from 'viem';
import { EButtonType } from '@app/types';
import packageIcon from 'src/assets/icons/packageIcon.svg';
import { Button } from '../../shared/Button';
import styles from './style.module.scss';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

type Props = {
  package: Package;
  isOpen?: () => void;
};

export const CurrentPackage = ({ package: packageItem, isOpen }: Props) => {
  const { packages } = usePackages();
  const { t } = useTranslation();
  const showUpgradeBtn = packages?.find((p) => p.size > (packageItem?.size ?? BigInt(0)));

  return (
    <div className={styles.infoWrapper}>
      <div className={styles.packageContent}>
        <div className={styles.infoIcon}>
          <Image src={packageIcon} alt="" />
        </div>
        <div className={styles.packageTitleWrapper}>
          <span className={styles.packageTitle}>
            {t('current_package.active_package')}
            <br />
            {packageNameConverter(+formatEther(packageItem.size))}
          </span>
          {/* <span className={styles.packageSubTitle}>
            Accrued {accrued} tokens
          </span> */}
        </div>
      </div>
      {showUpgradeBtn && (
        <Button
          type={EButtonType.OUTLINED}
          href={PACKAGES_PAGE}
          customClass={styles.packageButton}
          onClick={() => isOpen?.()}
        >
          {t('current_package.upgrade_now')}
        </Button>
      )}
    </div>
  );
};
