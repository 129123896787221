import styles from './style.module.scss';
import Image from 'next/image';
import infoIcon from '../../../assets/icons/infoIcon.svg';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const NoteText = ({ children, className }: Props) => {
  return (
    <div className={`${styles.note} ${className} light`}>
      <div className={styles.image}>
        <Image src={infoIcon} alt="info" width={20} height={20} />
      </div>
      <span className={styles.note__text}>{children}</span>
    </div>
  );
};
