import React, { BaseSyntheticEvent, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';
import { type Address, formatEther } from 'viem';
import { Box, Button, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

import { useReadBonusEvaluatorBonusOf } from '@app/contracts';
import { useModal } from '@app/hooks';
import modalStyles from './style.module.scss';
import { LargeInput } from '../../../shared/LargeInput';
import { useCreateWithdrawal } from '@app/hooks/useWithdrawals';
import CurrenciesSelect, { ICurrency } from '@app/components/CurrenciesSelect/CurrenciesSelect';
import { alreadyRequestedAtom, updateRequestedWithdrawals } from '@app/stores/atom/infoBar';
import { useTranslation } from 'next-i18next';

interface IRequestWithdrawalModal {}

function parseLocaleNumber(stringNumber: string, locale?: string) {
  var thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  var decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.'),
  );
}

export const RequestWithdrawal = ({}: IRequestWithdrawalModal) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const { data: sca } = useBalance({
    token: process.env.USCA_ADDRESS as Address,
    address,
  });

  const alreadyRequested = useAtomValue(alreadyRequestedAtom);

  const { data: bonus } = useReadBonusEvaluatorBonusOf({
    address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
    args: [address!],
  });

  const { close } = useModal();
  const [withdrawAmountStr, setWithdrawAmount] = useState<string>('0');
  const [currencyObject, setCurrencyObject] = useState<ICurrency | undefined>();
  const [validateMsg, setValidateMsg] = useState<string>('');

  const availableBalance = Math.max(
    +(sca?.formatted ?? '0') - (alreadyRequested ?? 0) - +formatEther(bonus ?? BigInt(0)),
    0,
  );

  const withdrawAmount = parseLocaleNumber(withdrawAmountStr);
  const isBonusEnabled = withdrawAmount > 0 && withdrawAmount <= availableBalance;

  const handleChangeWithdraw = (event: BaseSyntheticEvent, value: string) => {
    setWithdrawAmount(Math.floor(Number(value)).toString());
    setValidateMsg('');
  };

  const setMaxWithdrawAmount = () => {
    setWithdrawAmount(Math.floor(availableBalance).toString());
  };

  const { mutate } = useCreateWithdrawal({
    onSuccess: () => {
      updateRequestedWithdrawals();
      close();
    },
  });

  const handleClick = async () => {
    mutate({
      amount: withdrawAmountStr,
      currency: currencyObject?.currency!,
    });
  };

  return (
    <Box className={modalStyles.modal}>
      <Typography component={'div'} className={modalStyles.modal__title}>
        {t('request_withdrawal.convert_to')} {currencyObject?.currency}
        <Typography className={modalStyles.modal__title_span}>
          {currencyObject?.title.split(' ')[1]}
        </Typography>
      </Typography>
      <LargeInput
        tokenName="USCA"
        buttonText={t('request_withdrawal.max')}
        value={withdrawAmountStr}
        name="withdrawAmount"
        handleChangeValue={handleChangeWithdraw}
        handleBtn={setMaxWithdrawAmount}
        isNumberOnly
        maxValue={+(sca?.formatted ?? '0')}
        minValue={10}
        maxErrorMsg={t('request_withdrawal.enough_balance')}
        isAutoComplete={false}
        subtitlesBalance={[
          { title: `${t('request_withdrawal.available_usca')}:`, value: +(sca?.formatted ?? '0') },
          {
            title: `${t('request_withdrawal.bonus_tokens')}:`,
            value: +formatEther(bonus ?? BigInt(0)),
          },
          { title: `${t('request_withdrawal.already_requested')}:`, value: alreadyRequested },
          { title: `${t('request_withdrawal.max_request_amount')}:`, value: availableBalance },
        ]}
        setValidateMsg={setValidateMsg}
        validateMsg={validateMsg}
      >
        <Box mt={2}>
          <CurrenciesSelect
            handlCurrencyChange={(item) => {
              setCurrencyObject(item);
              setValidateMsg('');
            }}
          />
        </Box>
      </LargeInput>

      <Button disabled={!isBonusEnabled} onClick={handleClick} sx={{ mt: 2 }} variant="contained">
        {t('request_withdrawal.request')} {currencyObject?.currency}
      </Button>
    </Box>
  );
};
