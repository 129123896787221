import React, { BaseSyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';
import { type Address, formatEther, parseEther } from 'viem';
import { Box, Typography } from '@mui/material';
import process from 'process';
import {
  useReadOldScaTokenBonusOf,
  useSimulateTokenConverterConvert,
  useWriteTokenConverterConvert,
} from '@app/contracts';
import { LoadingButton } from '@mui/lab';
import { useAtomValue } from 'jotai';
import modalStyles from './style.module.scss';
import { LargeInput } from '../../../shared/LargeInput';
import { useModal } from '@app/hooks';
import { alreadyRequestedAtom } from '@app/stores/atom/infoBar';
import { useTranslation } from 'next-i18next';

const nf = new Intl.NumberFormat();

export const ConvertToUSCA = () => {
  const { setCurrentModal } = useModal();
  const { address } = useAccount();
  const { t } = useTranslation();
  const [withdrawAmountStr, setWithdrawAmount] = useState<string>('0');
  const [validateMsg, setValidateMsg] = useState<string>('');
  const alreadyRequested = useAtomValue(alreadyRequestedAtom);

  const { data: oldSCA } = useBalance({
    token: process.env.OLD_SCA_ADDRESS as Address,
    address,
  });

  const { data: bonusToken } = useReadOldScaTokenBonusOf({
    address: process.env.OLD_SCA_ADDRESS as Address,
    args: [address!],
  });

  const { error: configError } = useSimulateTokenConverterConvert({
    address: process.env.TOKEN_CONVERTER_ADDRESS! as Address,
    query: {
      enabled: Number(withdrawAmountStr || 0) > 0,
    },
  });

  const { writeContract: convert, isSuccess, isPending, error } = useWriteTokenConverterConvert();

  const availableBalance = Math.max(+(oldSCA?.formatted ?? '0') - (alreadyRequested ?? 0),
      0,
  );

  useEffect(() => {
    if (error) {
      console.log('convert error', error);
    }

    if (configError) {
      console.log('configError', configError);
    }
  }, [error, configError]);

  const isAvailableSCAToConvert = useMemo(() => {
    return oldSCA?.formatted && Number(oldSCA?.formatted) > 0;
  }, [oldSCA?.formatted]);

  useEffect(() => {
    if (Number(availableBalance) > 0) {
      setWithdrawAmount(availableBalance.toString());
    }
  }, [availableBalance]);

  const handleChangeWithdraw = (event: BaseSyntheticEvent, value: string) => {
    setWithdrawAmount(value.toString());
    setValidateMsg('');
  };

  useEffect(() => {
    if (isSuccess) {
      setCurrentModal('');
    }
  }, [isSuccess]);

  const convertHandler = useCallback(() => {
    convert({ address: process.env.TOKEN_CONVERTER_ADDRESS as Address });
  }, []);

  return (
    <Box className={modalStyles.modal}>
      <>
        <Typography className={modalStyles.modal__title}>{t('convert_to_usca.title')}</Typography>
        <LargeInput
          tokenName="SCA"
          value={withdrawAmountStr}
          name="withdrawAmount"
          handleChangeValue={handleChangeWithdraw}
          isNumberOnly
          isDisabled
          maxValue={+(oldSCA?.formatted ?? '0')}
          maxErrorMsg={t('convert_to_usca.enough_balance')}
          isAutoComplete={false}
          subtitlesBalance={[
            { title: `${t('convert_to_usca.available_sca')}:`, value: +(oldSCA?.formatted ?? '0') },
            {
              title: `${t('convert_to_usca.bonus_sca_tokens')}:`,
              value: Number(formatEther(bonusToken ?? BigInt(0))),
            },
          ]}
          setValidateMsg={setValidateMsg}
          validateMsg={validateMsg}
        ></LargeInput>

        <LoadingButton
          loading={!isAvailableSCAToConvert || isPending}
          disabled={Number(withdrawAmountStr) === 0}
          variant="contained"
          sx={{ mt: 2 }}
          onClick={convertHandler}
        >
          {t('convert_to_usca.title')}
        </LoadingButton>
      </>
    </Box>
  );
};
