export enum WalletNamesEnum {
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
  TrustWallet = 'TrustWallet',
  Exodus = 'Exodus',
  Web3Auth = 'Web3Auth',
}

export const getWalletNames = (): WalletNamesEnum[] => {
  const availableWallets: WalletNamesEnum[] = [WalletNamesEnum.WalletConnect]

  if ('trustwallet' in window) {
    availableWallets.push(WalletNamesEnum.TrustWallet)
  }

  if ((window as any)?.ethereum?.isMetaMask) {
    availableWallets.push(WalletNamesEnum.MetaMask)
  }

  if ('exodus' in window && window.exodus) {
    availableWallets.push(WalletNamesEnum.Exodus)
  }

  return availableWallets
}
