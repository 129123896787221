import { createTheme } from '@mui/material';

export const currenciesSelectTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#012E3B',
          color: 'white',
          position: 'absolute',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Playfair',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            borderColor: '#5FEDCF',
          },
          '&:hover .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            borderColor: '#FEAF3C',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Playfair',
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: 'white',
        },
      },
    },
  },
});
