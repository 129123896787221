export const MAIN_PAGE = '/';
// export const DEPOSIT_PAGE = '/deposit';
export const PACKAGES_PAGE = '/packages';
export const STAKING_PAGE = '/staking';
export const STAKE_PAGE = '/stake';
export const SWAP_PAGE = '/swap';
export const SETTINGS_PAGE = '/settings';
export const TRANSACTIONS_PAGE = '/transactions';
export const VERIFY_EMAIL_PAGE = '/verify-email';
// export const HELP_PAGE = '/help';
export const HELP_PAGE = '#';
export const NOT_FOUND_PAGE = '/404';
