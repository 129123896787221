import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

export const NoConnectedAccountContent: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography mb={1}>{t('no_connect_account.description_1')}</Typography>
    </Box>
  );
};
