import { exchangeAbi, useWatchExchangeEvent } from '@app/contracts';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { usePackages } from './usePackages';
import { type Address, Log } from 'viem';
import { ethers } from 'ethers';
import { addEventIfNotExists, getBlockByBlockHash } from '@app/utils/event';
import { useWatchPreviousEvents } from '@app/hooks/useWatchPreviousEvents';
import { packageNameConverter } from '@app/utils/packages';
import { formatEther } from 'viem';

export type Events = {
  time: number;
  eventType: string;
  package: string | undefined;
  tokens: BigInt;
  transactionHash: string;
};

export const useClaimBonusPackageHistory = () => {
  const [events, setEvents] = useState<Events[]>([]);
  const { packages, isLoading } = usePackages();
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const { address } = useAccount();
  const [logsClaimBonusPackage, setLogsClaimBonusPackage] = useState<Log[]>();
  const [logsUpgradeBonusPackage, setLogsUpgradeBonusPackage] = useState<Log[]>();

  useEffect(() => {
    if (!address) return;
  });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.EXCHANGE_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.EXCHANGE_ADDRESS! as Address,
    event: 'ClaimBonusPackage',
    args: {
      user: address,
    },
    onLogs(logs) {
      setLogsClaimBonusPackage(logs);
    },
  });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.EXCHANGE_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.EXCHANGE_ADDRESS! as Address,
    event: 'UpgradeClaimedBonusPackage',
    args: {
      user: address,
    },
    onLogs(logs) {
      setLogsUpgradeBonusPackage(logs);
    },
  });

  useWatchExchangeEvent({
    address: process.env.EXCHANGE_ADDRESS! as Address,
    eventName: 'ClaimBonusPackage',
    args: {
      user: address,
    },
    poll: true,
    pollingInterval: 1000,
    onLogs(logs: Log[]) {
      setLogsClaimBonusPackage(logs);
    },
    onError(error) {
      setIsHistoryLoading(false);
      console.warn('ClaimBonusPackage Error', error);
    },
  });

  useWatchExchangeEvent({
    address: process.env.EXCHANGE_ADDRESS! as Address,
    eventName: 'UpgradeClaimedBonusPackage',
    args: {
      user: address,
    },
    poll: true,
    pollingInterval: 1000,
    onLogs(logs: Log[]) {
      setLogsUpgradeBonusPackage(logs);
    },
    onError(error) {
      setIsHistoryLoading(false);
      console.warn('UpgradeClaimedBonusPackage Error', error);
    },
  });

  useEffect(() => {
    setIsHistoryLoading(true);

    if (logsClaimBonusPackage && logsClaimBonusPackage.length > 0) {
      logsClaimBonusPackage.forEach(async function (event: Log) {
        const decodedData = new ethers.Interface(exchangeAbi).parseLog({
          data: event.data,
          topics: [...event?.topics],
        });

        if (decodedData && decodedData?.args[2] && decodedData?.args[5]) {
          const packageItem = packages.find((p) => decodedData?.args[2] === p.id);
          const block = await getBlockByBlockHash(event.blockHash);

          const newEvent = {
            time: Number(block.timestamp),
            eventType: 'Claim Bonus Package',
            package: packageItem ? packageNameConverter(+formatEther(packageItem.size)) : undefined,
            tokens: decodedData?.args[5],
            transactionHash: event?.transactionHash as string,
          };
          setEvents((currentEvents) => addEventIfNotExists<Events>(currentEvents, newEvent));
        }
      });
    }

    if (logsUpgradeBonusPackage && logsUpgradeBonusPackage.length > 0) {
      logsUpgradeBonusPackage.forEach(async function (event: Log) {
        const decodedData = new ethers.Interface(exchangeAbi)?.parseLog({
          data: event.data,
          topics: [...event?.topics],
        });

        if (decodedData && decodedData?.args[2] && decodedData?.args[5]) {
          const packageItem = packages.find((p) => decodedData?.args[2] === p.id);
          const block = await getBlockByBlockHash(event.blockHash);

          const newEvent = {
            time: Number(block.timestamp),
            eventType: 'Upgrade Claimed Bonus Package',
            package: packageItem ? packageNameConverter(+formatEther(packageItem.size)) : undefined,
            tokens: decodedData?.args[5],
            transactionHash: event?.transactionHash as string,
          };
          setEvents((currentEvents) => addEventIfNotExists<Events>(currentEvents, newEvent));
        }
      });
    }
    setIsHistoryLoading(false);
  }, [logsClaimBonusPackage, logsUpgradeBonusPackage]);

  return { events, isLoading: isLoading || isHistoryLoading };
};
