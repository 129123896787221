import { bonusEvaluatorAbi, useWatchBonusEvaluatorBonusClaimedEvent } from '@app/contracts';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { Event } from './usePurchaseHistory';
import { type Address, Log } from 'viem';
import { ethers } from 'ethers';
import { addEventIfNotExists, getBlockByBlockHash } from '@app/utils/event';
import { useWatchPreviousEvents } from '@app/hooks/useWatchPreviousEvents';

export const useBonusClaimHistory = () => {
  const { address }: any = useAccount();
  const [events, setEvents] = useState<Event[]>([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [bonusClaimEventLogs, setBonusClaimEventLogs] = useState<Log[]>();
  const [bonusClaimEvents, setBonusClaimEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (!address) return;
  });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.BONUS_EVALUATOR_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.BONUS_EVALUATOR_ADDRESS! as Address,
    event: 'BonusClaimed',
    args: {
      user: address,
    },
    onLogs(logs) {
      setBonusClaimEventLogs(logs);
    },
  });

  useWatchBonusEvaluatorBonusClaimedEvent({
    address: process.env.BONUS_EVALUATOR_ADDRESS! as Address,
    args: {
      user: address,
    },
    onLogs(logs: Log[]) {
      setBonusClaimEventLogs(logs);
    },
    onError(error) {
      console.warn('Bonus Claim Error', error);
    },
  });

  useEffect(() => {
    if (bonusClaimEventLogs && bonusClaimEventLogs.length > 0) {
      bonusClaimEventLogs.forEach(async (log: Log) => {
        const decodedData = new ethers.Interface(bonusEvaluatorAbi)?.parseLog({
          data: log.data,
          topics: [...log?.topics],
        });
        const block = await getBlockByBlockHash(log.blockHash);

        const newEvent = {
          time: Number(block.timestamp),
          eventType: 'Bonus Claimed',
          package: undefined,
          tokens: BigInt(decodedData?.args[1]?.toString() ?? '0'),
          transactionHash: log?.transactionHash?.toString() ?? 'No data',
        };
        setBonusClaimEvents((currentEvents) => addEventIfNotExists<Event>(currentEvents, newEvent));
      });
    }
  }, [bonusClaimEventLogs]);

  useEffect(() => {
    setIsHistoryLoading(true);
    // TODO: NEED TO CHECK
    // setEvents((prevEvents) => [...prevEvents, ...bonusClaimEvents])
    setEvents([...bonusClaimEvents]);
    setIsHistoryLoading(false);
  }, [bonusClaimEvents]);

  return { events, isLoading: isHistoryLoading };
};
