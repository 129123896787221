export enum EUserBlock {
  FULL = 'full',
  STAKING = 'staking',
  BALANCE = 'balance',
}

export enum EStatusKyc {
  NOT_STARTED = 'notstarted',
  PENDING = 'pending',
  COMPLETED = 'completed',
  INIT = 'init',
}

export enum EIdDocSetType {
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  SELFIE = 'SELFIE',
}

export interface IMessagePayload {
  idDocSetType: EIdDocSetType;
  [key: string]: any;
}

export enum EWeb3Auth {
  WEB3AUTH = 'web3auth',
  METAMASK = 'metamask',
  OPENLOGIN = 'openlogin',
}
