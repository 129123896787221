import { useEffect } from 'react';
import { useSocket } from '@app/hooks/useSocket';
import { IUserData } from '@app/stores/auth/authTypes';

interface IMsg {
  userId: string;
}

// call to http://localhost:3000/api/webhook-update-user-data
export const useWebhookUpdateUserData = (
  refetch: (userAddress: string) => void,
  profile: IUserData | undefined,
) => {
  const { socket } = useSocket();

  useEffect(() => {
    if (!profile || !socket) return;

    socket.on(profile.id, (msg: IMsg) => {
      refetch?.(msg?.userId);
    });
  }, [profile, socket]);
};
