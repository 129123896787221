import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { CustomDialog } from '../shared/Dialog';
import {
  useReadBonusEvaluatorGetReferralBonusValue,
  useSimulateBonusEvaluatorRedeemReferralBonuses,
  useWriteBonusEvaluatorRedeemReferralBonuses,
} from '@app/contracts';
import { Address, formatEther } from 'viem';
import { useAccount, useWaitForTransactionReceipt } from 'wagmi';
import { useTranslation } from 'next-i18next';
import { useBalanceAndGasChecker } from '@app/hooks/useBalanceAndGasChecker';

export const RedeemSpecialBonus: React.FC<{
  handleClose: (isShow: boolean) => void;
  open: boolean;
}> = ({ handleClose, open }) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { data: referralBonusValue } = useReadBonusEvaluatorGetReferralBonusValue({
    address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
    account: address! as Address,
    args: [address as Address],
  });

  const bonusValue = useMemo(() => {
    return +formatEther(referralBonusValue?.bonusValue ?? BigInt(0));
  }, [referralBonusValue]);

  const { data: claimBonusConfig } = useSimulateBonusEvaluatorRedeemReferralBonuses({
    address: process.env.BONUS_EVALUATOR_ADDRESS as Address,
    account: address! as Address,
    query: {
      enabled: !!bonusValue,
    },
  });

  const { data: claimBonusData, writeContract: claimBonus } =
    useWriteBonusEvaluatorRedeemReferralBonuses();

  const { isSuccess } = useWaitForTransactionReceipt({
    hash: claimBonusData,
  });

  const { validateFunds: claimBonusValidateFunds } = useBalanceAndGasChecker({
    onSuccess: () => {
      setIsLoading(true);
      claimBonus(claimBonusConfig!.request);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      handleClose(false);
      setIsLoading(false);
    }
  }, [isSuccess]);

  return (
    <CustomDialog
      open={open}
      title={t('redeem_special_bonus.title')}
      handleClose={() => handleClose(false)}
    >
      <Box>
        <Typography>{t('redeem_special_bonus.dear_client')}</Typography>
        <Typography>
          {t('redeem_special_bonus.description_1')} {bonusValue} USCA.
        </Typography>
        <Typography>{t('redeem_special_bonus.description_2')}</Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'end' }} mt={2}>
        <LoadingButton
          onClick={claimBonusValidateFunds}
          variant="contained"
          loading={isLoading}
          disabled={!(bonusValue > 0)}
        >
          {t('redeem_special_bonus.redeem')}
        </LoadingButton>

        <Button onClick={() => handleClose(!open)} variant="outlined" sx={{ marginLeft: '30px' }}>
          {t('redeem_special_bonus.dismiss')}
        </Button>
      </Box>
    </CustomDialog>
  );
};
