import { atom } from 'jotai';

import { requestedWithdrawals } from '@app/hooks';
import { IUserData } from '@app/stores/auth/authTypes';
import atomStore from '@app/stores/atom/index';

export const profileAtom = atom<IUserData | undefined>(undefined);
export const alreadyRequestedAtom = atom(0);
export const scaFormattedBalance = atom<string | undefined>(undefined);

export const updateRequestedWithdrawals = async () => {
  const profile = atomStore.get(profileAtom);

  if (!!profile?.user) {
    try {
      const alreadyRequestedData = await requestedWithdrawals();
      atomStore.set(alreadyRequestedAtom, alreadyRequestedData.alreadyRequested);
    } catch (error) {
      atomStore.set(alreadyRequestedAtom, 0);
    }
  }
};

atomStore.sub(profileAtom, () => {
  if (atomStore.get(profileAtom)) {
    updateRequestedWithdrawals();
  }
});

atomStore.sub(scaFormattedBalance, () => {
  if (atomStore.get(scaFormattedBalance)) {
    const timeout = setTimeout(() => {
      updateRequestedWithdrawals();
      clearTimeout(timeout);
    }, 5000);
  }
});
