import { BonusHistory, bonusPackagesService } from '@app/services';
import { useEffect, useState } from 'react';

export const useBonusPackages = (
  address: string | undefined,
  page = 1,
  pageSize = 25
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [result, setResult] = useState<BonusHistory>({ data: [], total: 0 });

  useEffect(() => {
    if (!address) return;
    getData();
  }, [address, page]);

  const getData = async () => {
    setIsLoading(true);
    try {
      setResult(
        await bonusPackagesService.getBonusPackageHistory({
          address: address as string,
          page,
          pageSize,
        })
      );
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  return { result, isLoading, isError };
};
