import * as Yup from 'yup';

export const UserSettingsSchema = Yup.object().shape({
  id: Yup.string().nullable().notRequired(),
  firstName: Yup.string()
    .required('Name is required')
    .min(2, 'Must be at least 2 characters long')
    .max(50, 'Must be max 50 characters long')
    .matches(/^[A-z\s-]+$/gi, 'Incorrect name format'),
  lastName: Yup.string()
    .required('Surname is required')
    .min(2, 'Must be at least 2 characters long')
    .max(50, 'Must be max 50 characters long')
    .matches(/^[A-z\s-]+$/gi, 'Incorrect surname format'),
  country: Yup.string()
    .required('Country is required')
    .matches(/^[A-z\s-]+$/gi, 'Incorrect country format'),
  city: Yup.string().nullable().notRequired(),
  // city: Yup.string().required('City is required').matches(/^[A-z\s-']+$/gi, 'Incorrect city format'),
  email: Yup.string().required('Email is required').email('Email is incorrect'),
  phone: Yup.string().nullable().required('Phone is required'),
  telegram: Yup.string().nullable().notRequired(),
  whatsapp: Yup.string().nullable().notRequired(),
});
