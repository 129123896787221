import { IFetchDataProps, TFnReturn } from '@app/types/fetchData';
import catchErrorCallback from './catchErrorCallback';

export const fetchData = ({
  fn,
  fnName,
  txStatusCallback,
  snackbarCallback,
  loadingCallback,
  autoStopLoading = true,
  throwErrors,
  customDefaultMsg,
  customErrorCallback,
} : IFetchDataProps) : TFnReturn => {
  try {
    loadingCallback && loadingCallback(true);
    return fn();
  } catch (error: unknown) {
    catchErrorCallback({
      error,
      fnName,
      customErrorCallback,
      txStatusCallback,
      snackbarCallback,
      customDefaultMsg,
      throwErrors,
    });
  } finally {
    autoStopLoading && loadingCallback && loadingCallback(false);
  }
};

export default fetchData;
