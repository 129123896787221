import { useAccount, useBlockNumber } from 'wagmi';
import { useEffect } from 'react';

export const useTriggerRefresh = (
  refetch: () => void,
): { blockNumber: bigint | undefined; refetch: () => void } => {
  const { address } = useAccount();

  const { data: blockNumber } = useBlockNumber({
    watch: true,
  });

  useEffect(() => {
    if (blockNumber && address) {
      const timeoutId = setTimeout(() => {
        refetch?.();
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [blockNumber]);

  return { blockNumber, refetch };
};
