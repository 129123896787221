import FillProfile from '@app/components/Modal/fillProfile';
import RequestWithdrawalModals from '@app/components/Modal/transactions';
import UserBlock from '@app/components/Modal/userBlock';
import { useModal, useUserBlocked } from '@app/hooks';
import { ECurrentModal } from '@app/types';
import { useEffect } from 'react';
import styles from '../../Modal/style.module.scss';
import { Modal } from '../Modal';
import AuthModals from '../auth';
import TxStatus from '../txStatus';
import SumSubVerification from '@app/components/Modal/sumsubVerification';
import { AuthFormModal } from '@app/components/sideBar/AuthForm';
import QRCodeModal from '../QRCode';
import ImagePreviewModal from '@app/components/Modal/ImagePreview';
import Notification from '@app/components/Modal/Notification';

export const AppModals = () => {
  const {
    currentModal,
    txData,
    setIsClosable,
    setCustomContentClass,
    referralLinkProps,
    imageDataProps,
    notificationContentProps,
  } = useModal();
  const userBlock = useUserBlocked();

  useEffect(() => {
    if (
      currentModal === ECurrentModal.CHANGE_NETWORK ||
      currentModal === ECurrentModal.AUTH__NEED_MM ||
      currentModal === ECurrentModal.AUTH__NEED_INVITER_ADDRESS
    ) {
      setIsClosable(false);
      return;
    }
    if (currentModal === ECurrentModal.PERMISSION__REQUESTS) {
      setCustomContentClass(styles.permissionContent);
      return;
    }

    setCustomContentClass('');
    setIsClosable(true);
  }, [currentModal, setCustomContentClass, setIsClosable]);

  return (
    <>
      {currentModal && (
        <Modal>
          {currentModal === ECurrentModal.TX_STATUS && <TxStatus params={txData} />}
          {currentModal === ECurrentModal.SUMSUB_VERIFICATION && <SumSubVerification />}
          {currentModal === ECurrentModal.USER_BLOCK && <UserBlock type={userBlock} />}
          {currentModal === ECurrentModal.FILL_PROFILE && <FillProfile />}
          {currentModal === ECurrentModal.WITHDRAWAL__REQUESTS && (
            <RequestWithdrawalModals currentModal={currentModal} />
          )}
          {currentModal === ECurrentModal.CONVERT_TO_USCA && (
            <RequestWithdrawalModals currentModal={currentModal} />
          )}
          {currentModal.includes('Auth') && <AuthModals currentModal={currentModal} />}
          {currentModal === ECurrentModal.REFERRAL_LINK_LOGIN && <AuthFormModal />}
          {currentModal === ECurrentModal.QRCode && (
            <QRCodeModal
              header={referralLinkProps?.header}
              fundId={referralLinkProps?.fundId}
              referralAddress={referralLinkProps?.referralAddress}
            />
          )}
          {currentModal === ECurrentModal.IMAGE_PREVIEW && (
            <ImagePreviewModal image={imageDataProps.image} />
          )}
          {currentModal === ECurrentModal.NOTIFICATION && (
            <Notification content={notificationContentProps} />
          )}
        </Modal>
      )}
    </>
  );
};

export default AppModals;
