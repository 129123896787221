import {
  stakingAbi,
  useWatchStakingDividendedEvent,
  useWatchStakingStakedEvent,
  useWatchStakingUnstakedEvent,
} from '@app/contracts';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { Event } from './usePurchaseHistory';
import { type Address, Log } from 'viem';
import { ethers } from 'ethers';
import { addEventIfNotExists, getBlockByBlockHash } from '@app/utils/event';
import { useWatchPreviousEvents } from '@app/hooks/useWatchPreviousEvents';

export const useStakeHistory = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const { address } = useAccount();

  const [stakedEventLogs, setStakedEventLogs] = useState<Log[]>();
  const [stakedEvents, setStakedEvents] = useState<Event[]>([]);

  const [unStakedEventLogs, setUnStakedEventLogs] = useState<Log[]>();
  const [unStakedEvents, setUnStakedEvents] = useState<Event[]>([]);

  const [dividendedEventLogs, setDividendedEventLogs] = useState<Log[]>();
  const [dividendedEvents, setDividendedEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (!address) return;
  });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.STAKING_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.STAKING_ADDRESS! as Address,
    event: 'Staked',
    args: {
      staker: address,
    },
    onLogs(logs) {
      setStakedEventLogs(logs);
    },
  });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.STAKING_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.STAKING_ADDRESS! as Address,
    event: 'Unstaked',
    args: {
      staker: address,
    },
    onLogs(logs) {
      setUnStakedEventLogs(logs);
    },
  });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.STAKING_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.STAKING_ADDRESS! as Address,
    event: 'Dividended',
    args: {
      staker: address,
    },
    onLogs(logs) {
      setDividendedEventLogs(logs);
    },
  });

  useWatchStakingStakedEvent({
    address: process.env.STAKING_ADDRESS! as Address,
    args: {
      staker: address,
    },
    onLogs(logs: Log[]) {
      setStakedEventLogs(logs);
    },
    onError(error) {
      console.log('Staked event Error', error);
    },
  });

  useWatchStakingUnstakedEvent({
    address: process.env.STAKING_ADDRESS! as Address,
    args: {
      staker: address,
    },
    onLogs(logs: Log[]) {
      setUnStakedEventLogs(logs);
    },
    onError(error) {
      console.log('Unstake event Error', error);
    },
  });

  useWatchStakingDividendedEvent({
    address: process.env.STAKING_ADDRESS! as Address,
    args: {
      staker: address,
    },
    onLogs(logs: Log[]) {
      setDividendedEventLogs(logs);
    },
    onError(error) {
      console.log('Dividended event Error', error);
    },
  });

  useEffect(() => {
    if (stakedEventLogs && stakedEventLogs.length > 0) {
      stakedEventLogs.forEach(async (log: Log) => {
        const decodedData = new ethers.Interface(stakingAbi).parseLog({
          data: log.data,
          topics: [...log.topics],
        });

        let amount: bigint = BigInt(0);

        if (decodedData && decodedData?.args[2]) {
          amount = BigInt(decodedData?.args[2]?.toString());
        }
        const block = await getBlockByBlockHash(log.blockHash);
        const newEvent = {
          time: Number(block.timestamp) ?? BigInt(0),
          eventType: 'Stake',
          package: undefined,
          tokens: amount ?? BigInt(0),
          transactionHash: log?.transactionHash?.toString() ?? 'No data',
        };
        setStakedEvents((currentEvents) => addEventIfNotExists(currentEvents, newEvent));
      });
    }
  }, [stakedEventLogs]);

  useEffect(() => {
    if (unStakedEventLogs && unStakedEventLogs.length > 0) {
      unStakedEventLogs.forEach(async (log: Log) => {
        const decodedData = new ethers.Interface(stakingAbi).parseLog({
          data: log.data,
          topics: [...log.topics],
        });

        let amount: bigint = BigInt(0);

        if (decodedData && decodedData?.args[2]) {
          amount = BigInt(decodedData?.args[2]?.toString());
        }

        const block = await getBlockByBlockHash(log.blockHash);
        const newEvent = {
          time: Number(block.timestamp) ?? BigInt(0),
          eventType: 'Unstake',
          package: undefined,
          tokens: amount ?? BigInt(0),
          transactionHash: log?.transactionHash?.toString() ?? 'No data',
        };

        setUnStakedEvents((currentEvents) =>
          addEventIfNotExists<Log | any>(currentEvents ?? [], newEvent),
        );
      });
    }
  }, [unStakedEventLogs]);

  useEffect(() => {
    if (dividendedEventLogs && dividendedEventLogs.length > 0) {
      dividendedEventLogs.forEach(async (log: Log) => {
        const decodedData = new ethers.Interface(stakingAbi).parseLog({
          data: log.data,
          topics: [...log.topics],
        });

        let amount: bigint = BigInt(0);

        if (decodedData && decodedData?.args[3]) {
          amount = BigInt(decodedData?.args[3]?.toString());
        }

        const block = await getBlockByBlockHash(log.blockHash);

        const newEvent = {
          time: Number(block.timestamp) ?? BigInt(0),
          eventType: 'Dividend',
          package: undefined,
          tokens: amount ?? BigInt(0),
          transactionHash: log?.transactionHash?.toString() ?? 'No data',
        };
        setDividendedEvents((currentEvents) => addEventIfNotExists(currentEvents, newEvent));
      });
    }
  }, [dividendedEventLogs]);

  useEffect(() => {
    setIsHistoryLoading(true);
    // TODO: NEED TO CHECK
    // setEvents((prevEvents) => [...prevEvents, ...stakedEvents, ...unStakedEvents,
    setEvents([...stakedEvents, ...unStakedEvents, ...dividendedEvents]);
    setIsHistoryLoading(false);
  }, [stakedEvents, unStakedEvents, dividendedEvents]);

  return { events, isLoading: isHistoryLoading };
};
