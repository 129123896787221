export const LOCAL_STORAGE = {
  WALLET_CONNECTION_TYPE: 'WALLET_CONNECTION_TYPE',
  WALLET_CONNECT: 'walletconnect',
}

export enum NotificationStatusEnum {
  Dismiss = 'Dismiss',
  HideForever = 'HideForever',
}

export const NotificationsKeys = {
  ReleaseOfUscaToken: 'ReleaseOfUscaToken',
}
