import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { Button, Menu, MenuItem, ThemeProvider } from '@mui/material';
import { currenciesSelectTheme } from '@app/muiThemes/currenciesSelect';
import moment from 'moment/moment';
import 'moment/locale/ru';
import 'moment/locale/de';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { push, query, asPath, locale } = useRouter();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(locale ?? 'en');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl!);

  useEffect(() => {
    setSelectedLanguage(locale ?? 'en');
    moment.locale(locale ?? 'en');
  }, [locale]);

  const handleMenuOpen = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    moment.locale(language);
    i18n.changeLanguage(language);
    push({ query }, asPath, { locale: language });
    handleMenuClose();
  };

  return (
    <ThemeProvider theme={currenciesSelectTheme}>
      <Button
        id="positioned-button"
        // aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={{ width: 70, color: 'white', paddingRight: '0px' }}
        onClick={handleMenuOpen}
        sx={{ marginRight: '-10px' }}
      >
        <ReactCountryFlag
          countryCode={selectedLanguage === 'en' ? 'GB' : selectedLanguage!.toUpperCase()}
          svg
        />
        <span style={{ marginLeft: '5px' }}>{selectedLanguage!.toUpperCase()}</span>
      </Button>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {['en', 'ru', 'de'].map((lng) => (
          <MenuItem key={lng} value={lng} onClick={() => handleLanguageChange(lng)}>
            <ReactCountryFlag countryCode={lng === 'en' ? 'GB' : lng.toUpperCase()} svg />
            <span style={{ marginLeft: '5px' }}>{lng.toUpperCase()}</span>
          </MenuItem>
        ))}
      </Menu>
    </ThemeProvider>
  );
};
