import { useEffect } from 'react';

export const useClearConsole = (delay = 2000) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;
    const timer = setTimeout(() => {
      console.clear();
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);
};
