import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IError } from '../types';
import { StatusCodes } from '@app/constants';

export const getWalletString = (wallet: string, index = 11): string => {
  return wallet.substring(0, index) + '...' + wallet.substring(wallet.length - index);
};

interface IParseErrorParams {
  /**
   * customize server error depending on status
   * 1) take original message and change for user
   * 2) return original message without change
   */
  parseServerError?: (error: IError | any) => string | ReactJSXElement;
}

//todo: перенести error в объект params
export const parseError = (error: IError | any, params = {}): string | ReactJSXElement => {
  const { parseServerError }: IParseErrorParams = params;

  //* server errors
  if (error.status) {
    switch (error.status) {
      case StatusCodes.UNAUTHORIZED:
        return 'Unauthorized users are not allowed to perform this operation';
      case StatusCodes.INTERNAL_SERVER_ERROR:
        return 'Internal server error';
      default:
        return parseServerError ? parseServerError(error.data) : error.data.message;
    }
  }
  // *Error obj
  if (error.message) return error.message;

  return '';
};

export const getRandomInt = () => {
  return Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER - 1)) + 1;
};

/**
 * Uppercase first char of string `_string`.
 */
export const uppercaseFirstChar = (_str: string): string => {
  if (_str) {
    return _str.charAt(0).toUpperCase() + _str.slice(1);
  }
  return '';
};

export const copyExec = (text: string) => {
  const oInput = document.createElement('input');
  oInput.value = text;
  document.body.appendChild(oInput);
  oInput.select(); // выбираем объект
  document.execCommand('Copy'); // выполняем команду копирования браузера
  oInput.className = 'oInput';
  oInput.style.display = 'none';
};
