import Image from 'next/image';
import styles from './style.module.scss';
import ErrorIcon from 'src/assets/icons/error.svg';
import { EUserBlock } from '@app/types';
import { SupportCenterErrorMessage } from '@app/components/shared/SupportCenterErrorMessage';
import { useTranslation } from 'next-i18next';

interface IProps {
  type?: EUserBlock;
}

const UserBlock = ({ type }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.modal}>
      <div className={styles.modal__img}>
        <Image src={ErrorIcon} alt="error icon" />
      </div>
      <h2 className={`${styles.modal__title} white`}>{t('user_block.you_blocked')}</h2>
      <div className={styles.modal__description}>
        {type && `${t('user_block.block_type')}: ${type}.`}
        <SupportCenterErrorMessage retryMsg={true} />
      </div>
    </div>
  );
};

export default UserBlock;
