import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { Event } from './usePurchaseHistory';
import { type Address, Log } from 'viem';
import { stakingAbi, useWatchTokenConverterConvertTokensEvent } from '@app/contracts';
import { ethers } from 'ethers';
import { addEventIfNotExists, getBlockByBlockHash } from '@app/utils/event';
import { useWatchPreviousEvents } from '@app/hooks/useWatchPreviousEvents';

export const useConvertTokensHistory = () => {
  const { address }: any = useAccount();
  const [events, setEvents] = useState<(Event | undefined)[]>([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [convertTokenEventLogs, setConvertTokenEventLogs] = useState<Log[]>();
  const [convertTokenEvents, setConvertTokenEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (!address) return;
  });

  useWatchPreviousEvents<Log[]>({
    fromBlock: process.env.TOKEN_CONVERTER_CONTRACT_BORN_PACKAGE_NUMBER! as Address,
    address: process.env.TOKEN_CONVERTER_ADDRESS! as Address,
    event: 'ConvertTokens',
    args: {
      user: address,
    },
    onLogs(logs) {
      setConvertTokenEventLogs(logs);
    },
  });

  useWatchTokenConverterConvertTokensEvent({
    address: process.env.TOKEN_CONVERTER_ADDRESS as Address,
    args: {
      user: address,
    },
    onLogs(logs: Log[]) {
      setConvertTokenEventLogs(logs);
    },
    onError(error) {
      console.log('Convert Tokens Error', error);
    },
  });

  useEffect(() => {
    if (convertTokenEventLogs && convertTokenEventLogs.length > 0) {
      convertTokenEventLogs.forEach(async (log: Log) => {
        const decodedData = new ethers.Interface(stakingAbi).parseLog({
          data: log.data,
          topics: [...log.topics],
        });

        let amount: bigint = BigInt(0);

        if (decodedData && decodedData?.args[4]) {
          amount = BigInt(decodedData?.args[4]?.toString());
        }

        const block = await getBlockByBlockHash(log.blockHash);

        const newEvent = {
          time: Number(block.timestamp),
          eventType: 'Convert Tokens',
          package: undefined,
          tokens: amount,
          transactionHash: log?.transactionHash?.toString() ?? 'No data',
        };
        setConvertTokenEvents((currentEvents) =>
          addEventIfNotExists<Event>(currentEvents, newEvent),
        );
      });
    }
  }, [convertTokenEventLogs]);

  useEffect(() => {
    setIsHistoryLoading(true);
    // TODO: NEED TO CHECK
    // setEvents((prevEvents) => [...prevEvents, ...convertTokenEvents])
    setEvents([...convertTokenEvents]);
    setIsHistoryLoading(false);
  }, [convertTokenEvents]);

  return { events, isLoading: isHistoryLoading };
};
