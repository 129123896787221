import { useEffect } from 'react';
import { useModal } from '@app/hooks';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import styles from './style.module.scss';

const FillProfile = () => {
  const { close } = useModal();
  const { route } = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    if (route === '/settings') {
      close();
    }
  }, [route]);

  return (
    <div className={styles.modal}>
      <h2 className={`${styles.modal__title} white`}>{t('fill_profile.title')}</h2>
      <div className={styles.modal__description}>
        {t('fill_profile.description_1')}{' '}
        <Link href={'settings'}>{t('fill_profile.personal_data')}</Link>{' '}
        {t('fill_profile.description_2')}
      </div>
    </div>
  );
};

export default FillProfile;
