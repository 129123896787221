import { IAsyncFetchDataProps } from '@app/types/fetchData';
import catchErrorCallback from './catchErrorCallback';
import { ETxStatus } from '@app/types';

export async function asyncFetchData <TAsyncReturn>({
  fn,
  fnName,
  txStatusCallback,
  snackbarCallback,
  loadingCallback,
  autoStopLoading = true,
  throwErrors,
  customDefaultMsg,
  successMsg,
  customErrorCallback,
  parseServerError,
  showSuccessTx,
} : IAsyncFetchDataProps<TAsyncReturn>) : Promise<TAsyncReturn | undefined> {
  try {
    loadingCallback && loadingCallback(true);
    //* await is necessary here. Otherwise, we won't catch any errors.
    const res = await fn();
    showSuccessTx && txStatusCallback && txStatusCallback({
      status: ETxStatus.SUCCESS,
      description: successMsg || 'This operation was successful',
    });
    return res;
  } catch (error: unknown) {
    catchErrorCallback({
      error,
      fnName,
      customErrorCallback,
      txStatusCallback,
      snackbarCallback,
      customDefaultMsg,
      parseServerError,
      throwErrors,
    });
  } finally {
    autoStopLoading && loadingCallback && loadingCallback(false);
  }
}

export default asyncFetchData;
