import { useEffect, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';
import { estimateFeesPerGas } from '@wagmi/core';
import { parseEther, formatEther, Address } from 'viem';
import { APP_CONFIG, chain } from '../../pages/_app';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

interface UseBalanceAndGasCheckerProps {
  currencyAddress?: string;
  requiredAmount?: number;
  onSuccess: () => Promise<void> | void;
  onError?: (errorResponse: unknown, errorMessage: string) => void;
}

interface UseBalanceAndGasCheckerReturn {
  hasSufficientFunds: boolean;
  error: string;
  validateFunds: () => void;
}

export const useBalanceAndGasChecker = ({
  currencyAddress,
  requiredAmount,
  onSuccess,
  onError,
}: UseBalanceAndGasCheckerProps): UseBalanceAndGasCheckerReturn => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const [hasSufficientFunds, setHasSufficientFunds] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { data: tokenBalance, refetch: refetchTokenBalance } = useBalance({
    address,
    token: currencyAddress as Address,
    query: {
      enabled: false,
    },
  });

  const { data: ethBalance, refetch: refetchEthBalance } = useBalance({
    address,
    query: {
      enabled: false,
    },
  });

  const validateFunds = async () => {
    try {
      if (requiredAmount !== undefined) {
        if (requiredAmount < 1) {
          setErrorMessage(t('balanceChecker.invalidAmount'));
          setHasSufficientFunds(false);
          return;
        }

        const { data: updatedTokenBalance } = await refetchTokenBalance();

        if (
          updatedTokenBalance &&
          BigInt(updatedTokenBalance.value.toString() || '0') <
            parseEther(requiredAmount.toString())
        ) {
          setErrorMessage(
            t('balanceChecker.insufficientBalance', {
              balance: updatedTokenBalance.formatted,
              symbol: updatedTokenBalance.symbol,
              amount: requiredAmount,
            }),
          );
          setHasSufficientFunds(false);
          return;
        }
      }

      const { data: updatedEthBalance } = await refetchEthBalance();

      const fees = await estimateFeesPerGas(APP_CONFIG, {
        formatUnits: 'wei',
        chainId: chain.id,
        type: 'legacy',
      });

      const gasEstimate = fees.gasPrice ?? fees.maxFeePerGas;
      if (gasEstimate) {
        const gasCost = gasEstimate * BigInt(971000);

        if (BigInt(updatedEthBalance?.value.toString() || '0') < gasCost) {
          setErrorMessage(
            t('balanceChecker.insufficientGas', {
              balance: formatEther(updatedEthBalance?.value ?? BigInt('0')),
              symbol: updatedEthBalance?.symbol,
              requiredGas: formatEther(gasCost),
            }),
          );
          setHasSufficientFunds(false);
        } else {
          setHasSufficientFunds(true);
          setErrorMessage('');
          try {
            await onSuccess?.();
          } catch (error: any) {
            console.warn(error);
            onError?.(error, errorMessage);
          }
        }
      } else {
        setErrorMessage(t('balanceChecker.gasEstimateError'));
      }
    } catch (error: any) {
      console.warn(error);
      onError?.(error, errorMessage);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage('');
    }
  }, [errorMessage]);

  return { hasSufficientFunds, error: errorMessage, validateFunds };
};
