import {
  useReadBonusEvaluatorBonusAllowanceOf,
  useReadBonusEvaluatorGetPercentage,
} from '@app/contracts';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useAccount } from 'wagmi';
import { formatEther, type Address } from 'viem';
import { useUser } from '@app/hooks';
import { useTranslation } from 'next-i18next';

export const nf = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 4,
});

export const WelcomeBonusContent: React.FC<{}> = () => {
  const { address } = useAccount();
  const { user } = useUser();
  const { t } = useTranslation();
  const { data: percentage } = useReadBonusEvaluatorGetPercentage({
    address: process.env.BONUS_EVALUATOR_ADDRESS! as Address,
  });

  const { data: welcomeBonusAllowance } = useReadBonusEvaluatorBonusAllowanceOf({
    address: process.env.BONUS_EVALUATOR_ADDRESS! as Address,
    args: [address!, user?.package?.size!],
  });

  return (
    <Box>
      <Typography mb={1}>{t('welcome_bonus_content.description_1')}</Typography>
      <Typography mb={1}>
        {t('welcome_bonus_content.description_2_1')} {Number(percentage)}%{' '}
        {t('welcome_bonus_content.description_2_2')}
      </Typography>
      <Typography mb={2} mt={2} variant="subtitle2">
        {t('welcome_bonus_content.description_3')}{' '}
        {nf.format(+formatEther(welcomeBonusAllowance ?? BigInt(0)))} USCA
      </Typography>
      <Typography variant="caption">{t('welcome_bonus_content.description_4')}</Typography>
    </Box>
  );
};
