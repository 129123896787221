import { Box, Typography } from '@mui/material';
import React from 'react';

import styles from './style.module.scss';
import { Button } from '@app/components/shared/Button';
import { EButtonType, ECurrentModal } from '@app/types';
import { useModal, useProfile } from '@app/hooks';
import { CopiedToClipboardButtonTooltip } from '@app/components/shared/Tooltips/CopiedToClipboardTooltip';
import { useTranslation } from 'next-i18next';

const ModalHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography className={styles.modalTitle}>{t('referral_link_block.share_sca')}</Typography>
      <Typography className={styles.modalSubtitle}>
        {t('referral_link_block.your_qr_code')}
      </Typography>
      <Typography className={styles.modalSubtitle}>
        {t('referral_link_block.users_registered')}
      </Typography>
    </>
  );
};

export const ReferralLinkBlock = () => {
  const { setCurrentModal, setReferralLinkProps } = useModal();
  const { data: profile } = useProfile();
  const { t } = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <Typography className={styles.title}>
        {t('referral_link_block.your_referral_link')}
      </Typography>
      <Typography className={styles.subtitle}>{t('referral_link_block.provided_by')}</Typography>

      <Box className={styles.btnWrap}>
        <CopiedToClipboardButtonTooltip
          referralAddress={profile?.address}
          buttonText={t('referral_link_block.copy_link')}
          placement={'top'}
          buttonType={EButtonType.FILLED}
          title={t('referral_link_block.title_referral')}
        />
        <Button
          type={EButtonType.FILLED}
          onClick={() => {
            setReferralLinkProps({
              header: <ModalHeader />,
              referralAddress: profile?.address,
              fundId: undefined,
            });
            setCurrentModal(ECurrentModal.QRCode);
          }}
        >
          {t('referral_link_block.qr_code')}
        </Button>
      </Box>
    </Box>
  );
};
