import React, { FC, useState } from 'react';
import { CustomDialog } from '@app/components/shared/Dialog';
import { Grid, Tooltip, Typography } from '@mui/material';
import styles from './style.module.scss';
import { EButtonType } from '@app/types';
import { Button } from '@app/components/shared/Button';
import process from 'process';
import { Connector, useAccount } from 'wagmi';
import { type Address } from 'viem';
import { tryAddToken } from '@app/components/sideBar/AuthForm/AuthForm';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

export const AddTokenDialog: FC<{ onClose: () => void }> = ({ onClose }) => {
  const title = '';
  const { address } = useAccount();
  const { t } = useTranslation();
  const [clipboardValue, setClipboardValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const uscaAddress = process.env.USCA_ADDRESS;
  const { connector } = useAccount();

  const addTokenUSCA = () => {
    setIsLoading(true);
    tryAddToken(address as Address, connector as Connector)
      .then((message) => {
        toast.success(message);
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const copyToClipboard = (text: string | undefined) => {
    navigator?.clipboard?.writeText(text!);
    setClipboardValue(text!);
  };

  return (
    <CustomDialog open title={title} handleClose={onClose}>
      <Typography variant="body1" mb={2}>
        {t('add_token.dear_client')}
      </Typography>
      <Typography variant="body1" mb={1}>
        {t('add_token.you_adding_usca')}{' '}
        <Tooltip
          title={
            clipboardValue === uscaAddress ? t('add_token.copied') : t('add_token.copy_clipboard')
          }
        >
          <span className={styles.orange} onClick={() => copyToClipboard(uscaAddress)}>
            {uscaAddress}
          </span>
        </Tooltip>{' '}
        {t('add_token.to_you_wallet_address')}
      </Typography>
      <Typography variant="body1" mb={1}>
        {t('add_token.manually_wallet_settings')}
      </Typography>

      <Grid container direction="row" mt={3}>
        <Button
          customClass={styles.btn}
          loading={isLoading}
          loadingPosition={'start'}
          type={EButtonType.FILLED}
          onClick={() => addTokenUSCA()}
        >
          {isLoading ? t('add_token.waiting_confirmation') : t('add_token.proceed')}
        </Button>

        <Button customClass={styles.btnDismiss} type={EButtonType.OUTLINED} onClick={onClose}>
          {t('add_token.dismiss')}
        </Button>
      </Grid>
    </CustomDialog>
  );
};
