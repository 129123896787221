import { AxiosError, AxiosResponse } from 'axios';
import { EErrorType, IError, IMMError } from '@app/types/errors';
import { hasOnlyMessage, isAxiosError, isJsonRpcError, isMMError } from '@app/helpers';

interface IGetErrorType {
  error: unknown;
}

interface IGetErrorTypeReturn {
  handledError: AxiosError | AxiosResponse | IMMError | unknown;
  errorType: EErrorType | undefined;
}

export const getErrorType = ({ error } : IGetErrorType) : IGetErrorTypeReturn => {
  if (isAxiosError(error)) {
    return { handledError: error.response, errorType: EErrorType.SERVER };
  }

  if (isMMError(error)) {
    return { handledError: error, errorType: EErrorType.MM };
  }

  /**
   *  JSON.parse(JSON.stringify ползволяет добраться до кода ошибки от Метамаск во многих случаях
   */
  const parsedError: IError | undefined = JSON.parse(JSON.stringify(error));
  if (isMMError(parsedError)) {
    return { handledError: parsedError, errorType: EErrorType.MM };
  }

  const jsonRpcErrorSubstr = 'Internal JSON-RPC error.';
  const jsonRpcErrorStr = hasOnlyMessage(error) && error.message.includes(jsonRpcErrorSubstr) ?
    error.message.substring(jsonRpcErrorSubstr.length)
    : undefined;
  const jsonRpcObj = jsonRpcErrorStr ? JSON.parse(jsonRpcErrorStr): undefined;
  if (isJsonRpcError(jsonRpcObj)) {
    return { handledError: jsonRpcObj, errorType: EErrorType.JSONRPC };
  }

  if (hasOnlyMessage(error)) {
    return { handledError: error, errorType: undefined };
  }

  return { handledError: error, errorType: undefined };
};

export default getErrorType;
