import styles from './style.module.scss';
import React, { useEffect, useState } from 'react';
import { axios } from '@app/services/httpClient/axios';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { EButtonType, IReferralLinkProps } from '@app/types';
import { getLinkToGenerateQRCode } from '@app/utils/url';
import { Button } from '@app/components/shared/Button';
import { CopiedToClipboardButtonTooltip } from '@app/components/shared/Tooltips/CopiedToClipboardTooltip';
import { Box } from '@mui/material';
import { isBrowserMetaMask } from '@app/utils/user';
import { useTranslation } from 'next-i18next';

const QRCodeModal = (props: IReferralLinkProps) => {
  const { referralAddress, fundId, header } = props;
  const { t } = useTranslation();
  const [imageData, setImageData] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getQrImage = async () => {
    const link = getLinkToGenerateQRCode({
      fundId,
      referralAddress,
    });

    try {
      const response = await axios.post(link, {
        base64: true,
      });
      if (response.data.includes('base64')) {
        setImageData(response.data);
        setIsLoading(false);
      } else {
        toast.error('Generate QR error');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      const errMessage = axiosError.message || 'Generate QR error';
      toast.error(errMessage);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQrImage().then();
  }, []);

  if (isLoading) {
    return (
      <Box className={styles.loaderWrapper}>
        <CircularProgress size={70} />
      </Box>
    );
  }

  return (
    <Box
      className={styles.outerLayer}
      sx={{
        padding: {
          xs: '0px',
          sm: '38px',
        },
      }}
    >
      <Box className={styles.wrapper}>
        {header}
        <Box
          sx={{
            width: {
              sm: '100%',
              md: 400,
            },
            height: {
              sm: '100%',
              md: 400,
            },
          }}
        >
          <img src={imageData} alt="qr" style={{ width: '100%' }} />
        </Box>

        {!isBrowserMetaMask() && (
          <a href={imageData} className={styles.link} download={'Qr_code.png'}>
            <Button customClass={styles.btn} type={EButtonType.OUTLINED}>
              {t('qr_code.download')}
            </Button>
          </a>
        )}
        <CopiedToClipboardButtonTooltip
          referralAddress={referralAddress}
          fundId={fundId}
          buttonText={t('qr_code.button_text')}
          buttonType={EButtonType.OUTLINED}
          title={t('qr_code.title')}
        />
      </Box>
    </Box>
  );
};

export default QRCodeModal;
