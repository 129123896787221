import React from 'react';
import { Box } from '@mui/material';
import styles from './styles.module.scss';

export enum NoticeColorEnum {
  blue = '#4DABF50F',
  green = '#5FEDCF',
  red = '#FF4B4B',
  orange = '#FEAF3C',
}

type NoticeProps = {
  color?: keyof typeof NoticeColorEnum;
  children: React.ReactNode;
};

export const Notice = ({ color = 'blue', children }: NoticeProps) => {
  return (
    <Box className={styles.wrapper} style={{ backgroundColor: NoticeColorEnum[color] }}>
      {children}
    </Box>
  );
};
