import { NoteText } from '@app/components/shared/NoteText';

interface Props {
	problem?: string,
	customClass?: string,
}

export const ContactLiveChatMsg = ({ problem = '', customClass = '' }: Props) => {
  return (
    <NoteText className={customClass}>
			If you have problems {problem}, please contact
			our Support Service via web-site livechat
    </NoteText>
  );
};