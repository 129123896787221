import React, { useEffect, useMemo, useState } from 'react';
import {
  exchangeAbi,
  useReadErc20Allowance,
  useReadExchangeGetExchangeRate,
  useSimulateErc20Approve,
  useSimulateExchangeBuyTokens,
  useWatchExchangeEvent,
  useWriteErc20Approve,
  useWriteExchangeBuyTokens,
} from '@app/contracts';
import { ethers } from 'ethers';
import { SPECIAL_RATE } from '@app/types';
import { User } from '@app/hooks/useUser';
import { useAccount, useBalance, useWaitForTransactionReceipt } from 'wagmi';
import { type Address, formatEther, Log, parseEther } from 'viem';
import CurrenciesSelect, { ICurrency } from '@app/components/CurrenciesSelect/CurrenciesSelect';
import { BalanceDisplay } from './components/BalanceDisplay';
import styles from './style.module.scss';
import { CustomDialog } from '@app/components/shared/Dialog';
import { LargeInput } from '@app/components/shared/LargeInput';
import { Grid, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'next-i18next';
import { useBalanceAndGasChecker } from '@app/hooks/useBalanceAndGasChecker';

const nf = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});

export const BuyTokens: React.FC<{
  user: User;
  open: boolean;
  onClose?: () => void;
}> = ({ user, open, onClose }): JSX.Element => {
  const { chain, address } = useAccount();
  const { t } = useTranslation();
  const [amountStr, setAmountStr] = useState('0');
  const [validateMsg, setValidateMsg] = useState<string>('');
  const [currencyObject, setCurrencyObject] = useState<ICurrency | undefined>();
  const [availableTokens, setAvailableTokens] = useState<bigint>(BigInt(0));
  const [logs, setLogs] = useState<Log[]>();

  useEffect(() => {
    if (!address) return;
  });

  const { data: userBalance } = useBalance({
    token: currencyObject?.address,
    address,
  });

  const { data: rate } = useReadExchangeGetExchangeRate({
    address: process.env.EXCHANGE_ADDRESS! as Address,
    args: [currencyObject?.address!],
  });

  useWatchExchangeEvent({
    address: process.env.EXCHANGE_ADDRESS! as Address,
    eventName: 'BuyTokens',
    args: {
      user: address,
    },
    poll: true,
    pollingInterval: 1000,
    onLogs(logs: Log[]) {
      setLogs(logs);
    },
    onError(error) {
      console.warn('Error', error);
    },
  });

  useEffect(() => {
    let tempAvailableTokens: bigint = user?.package?.tokensAvailable ?? BigInt(0);

    if (tempAvailableTokens > BigInt(0) && logs && logs.length > 0) {
      logs.forEach(function (event: Log) {
        const decodedData = new ethers.Interface(exchangeAbi).parseLog({
          data: event.data,
          topics: [...event.topics],
        });

        if (decodedData && decodedData?.args[1]) {
          const amount: bigint = BigInt(decodedData?.args[1]?.toString());
          tempAvailableTokens = tempAvailableTokens - amount;
        }
      });
    }

    setAvailableTokens(tempAvailableTokens);
  }, [user, logs]);

  const tokensAvailable = user.package?.unlimitedBuying
    ? parseEther(SPECIAL_RATE.toString())
    : user?.tokensAvailable;

  const { data: allowance, refetch: refetchAllowance } = useReadErc20Allowance({
    address: currencyObject?.address as Address,
    args: [address!, process.env.EXCHANGE_ADDRESS as Address],
  });

  const amount = parseEther(String(amountStr));

  const tokenPrice: bigint =
    rate && user.package
      ? (((amount * rate) / SPECIAL_RATE) * (user.package?.commission + SPECIAL_RATE)) /
        SPECIAL_RATE
      : BigInt(0);

  const enoughAllowance = tokenPrice && BigInt(allowance ?? 0) >= tokenPrice;
  const tokensWithinLimits: boolean =
    (user.tokensAvailable ?? BigInt(0)) >= amount || user.package?.unlimitedBuying;

  const approveArgs =
    tokenPrice && rate && amount && !enoughAllowance && tokensWithinLimits
      ? ([process.env.EXCHANGE_ADDRESS! as Address, tokenPrice] as const)
      : undefined;

  const { data: approveConfig } = useSimulateErc20Approve({
    address: currencyObject?.address as Address,
    args: approveArgs,
    query: {
      enabled: !!approveArgs,
    },
  });

  const buyArgs =
    rate && tokenPrice && enoughAllowance && tokensWithinLimits && amount && amount > 0
      ? ([currencyObject?.address as Address, amount] as const)
      : undefined;

  const {
    isPending: approveLoading,
    isSuccess: approveSuccess,
    writeContract: approve,
    data: approveData,
  } = useWriteErc20Approve();

  const { data: buyConfig, error: errorSimulateExchangeBuyTokens } = useSimulateExchangeBuyTokens({
    address: process.env.EXCHANGE_ADDRESS as Address,
    args: buyArgs,
    query: {
      enabled: !!buyArgs,
    },
  });

  const {
    data: hash,
    isPending: buyLoading,
    isSuccess: buySuccess,
    writeContract: buy,
  } = useWriteExchangeBuyTokens();

  const { data: approveReceipt } = useWaitForTransactionReceipt({
    hash: approveData,
    query: {
      enabled: !enoughAllowance,
    },
  });

  const maxValue = useMemo(() => {
    if (user.package?.unlimitedBuying) {
      return t('buy_tokens.unlimited');
    } else {
      return tokensAvailable ? Number(formatEther(tokensAvailable)).toFixed(2) : 0;
    }
  }, [user.package?.unlimitedBuying, tokensAvailable]);

  const price = tokenPrice ? `${formatEther(tokenPrice)}` : '...';

  const { validateFunds: handleApproveValidateFunds } = useBalanceAndGasChecker({
    currencyAddress: currencyObject?.address ?? '',
    requiredAmount: parseInt(amountStr, 10),
    onSuccess: () => approve?.(approveConfig!.request),
  });

  const { validateFunds: buyTokenValidateFunds } = useBalanceAndGasChecker({
    currencyAddress: currencyObject?.address ?? '',
    requiredAmount: parseInt(amountStr, 10),
    onSuccess: () => buy?.(buyConfig!.request),
  });

  useEffect(() => {
    if (approveReceipt && approveReceipt.status === 'success') {
      refetchAllowance();
    }
  }, [approveReceipt]);

  return (
    <CustomDialog open={open} title={t('buy_tokens.title')} handleClose={onClose}>
      {!buySuccess && (
        <Typography mb={4} className={styles.balanceTitle}>
          {t('buy_tokens.available_for_purchase')}: {maxValue} USCA
        </Typography>
      )}

      {!buySuccess && (
        <Grid container gap={2} direction="column">
          <LargeInput
            tokenName="USCA"
            name={''}
            value={amountStr}
            minValue={1}
            isNumberOnly
            maxErrorMsg={`${t('buy_tokens.you_can_buy_up')} ${maxValue} USCA`}
            handleChangeValue={(_, value) => setAmountStr(value?.length ? value : '0')}
            maxValue={maxValue}
            setValidateMsg={setValidateMsg}
            validateMsg={validateMsg}
          />

          <CurrenciesSelect handlCurrencyChange={(item) => setCurrencyObject(item)} />

          {!enoughAllowance && (
            <LoadingButton
              variant="contained"
              className={styles.btn}
              onClick={handleApproveValidateFunds}
              loading={approveLoading || (approveSuccess && !approveReceipt)}
              loadingPosition="start"
              disabled={!approve || approveLoading || (approveSuccess && !approveReceipt)}
            >
              {approveLoading || (approveSuccess && !approveReceipt)
                ? t('buy_tokens.waiting_confirmation')
                : `${t('buy_tokens.allow_wallet_to_spend')} ${price} ${currencyObject?.currency}`}
            </LoadingButton>
          )}

          {enoughAllowance && (
            <LoadingButton
              variant="contained"
              className={styles.btn}
              onClick={buyTokenValidateFunds}
              loading={buyLoading}
              loadingPosition="start"
              disabled={!buy || buyLoading}
            >
              {t('buy_tokens.pay')} {price} {currencyObject?.currency}
            </LoadingButton>
          )}
        </Grid>
      )}

      {!buySuccess && (
        <Typography mt={2} className={styles.balanceTitle}>
          {t('buy_tokens.user_account')}: {nf.format(+(userBalance?.formatted ?? 0))}{' '}
          {currencyObject?.currency}
        </Typography>
      )}

      {buySuccess && hash && chain?.blockExplorers && (
        <>
          <Typography component={'div'} mb={2} variant="body1">
            <p>
              {t('buy_tokens.description_1')} {nf.format(+formatEther(amount))}{' '}
              {t('buy_tokens.usca_tokens')}!
            </p>
            <p>{t('buy_tokens.description_2')}</p>
          </Typography>
          <Link
            href={`${chain.blockExplorers.default.url}/tx/${hash}`}
            target="_blank"
            rel="noreferer"
          >
            {t('buy_tokens.view_transaction')} {chain.blockExplorers.default.name}
          </Link>
        </>
      )}
      {!buySuccess && <BalanceDisplay currencyObject={currencyObject} rate={rate} user={user} />}
    </CustomDialog>
  );
};
