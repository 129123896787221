import { ContactLiveChatMsg } from '@app/components/shared/ContactLiveChatMsg';
import { exchangeAbi } from '@app/contracts';
import { useModal } from '@app/hooks/useModal';
import { setReferrerAddress } from '@app/stores/auth/store';
import { EButtonType } from '@app/types';
import { Grid, Button as MuiButton, Typography } from '@mui/material';
import { useDisconnect, useReadContract } from 'wagmi';
import { type Address, isAddress } from 'viem';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Button } from '../../../shared/Button';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { AgreementLayout } from '../AgreementLayout/AgreementLayout';
import { ServiceAgreement } from './ServiceAgreement';
import styles from './style.module.scss';
import { PrivacyPolicy } from '@app/components/Modal/auth/PrivacyPolicy/PrivacyPolicy';
import { useTranslation } from 'next-i18next';

export const NeedInviterAddressAndAgreement = () => {
  const { close } = useModal();
  const { disconnect } = useDisconnect();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [address, setAddress] = useState<string>(localStorage['inviter_wallet_address']);
  const [checked, setChecked] = useState(false);
  const [checkedPP, setCheckedPP] = useState(false);
  const [isShowUserAgree, setIsShowUserAgree] = useState(false);
  const [isShowPrivacyPolicy, setIsShowPrivacyPolicy] = useState(false);
  const isInputDisabled = localStorage['inviter_wallet_address_locked'];

  const isBtnDisabled = !checked || !!error || !address || !checkedPP;

  const { data: ref } = useReadContract({
    abi: exchangeAbi,
    address: process.env.EXCHANGE_ADDRESS as Address,
    functionName: 'getUser',
    args: [address as Address],
  });

  useEffect(() => {
    if (!address) {
      return;
    }

    if (!isAddress(address)) {
      setError(t('need_inviter.must_be_valid'));
      return;
    } else {
      setError('');
    }

    if (
      ref &&
      ref.packageId === BigInt(0) &&
      address?.toLowerCase() !== process.env.PARTNER_ADMIN_INVITER_ADDRESS?.toLowerCase()
    ) {
      setError(t('need_inviter.invalid_referrer_address'));
    }

    // async function fetchData() {
    //   try {
    //     if (
    //       ref?.packageId === BigInt(0) &&
    //       address?.toLowerCase() !== process.env.PARTNER_ADMIN_INVITER_ADDRESS?.toLowerCase()
    //     ) {
    //       setError('Invalid referrer address');
    //     }
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }

    // fetchData();
  }, [address, ref]);

  const handleChange = ({ target: { value } }: BaseSyntheticEvent) => {
    setAddress(value);
  };

  const cancel = () => {
    disconnect();
    close();
  };

  const handleChangeCheck = () => {
    setChecked(!checked);
  };

  const handleChangeCheckPP = () => {
    setCheckedPP(!checkedPP);
  };

  const handleClick = () => {
    close();
    if (address) {
      setReferrerAddress(address);
    }
  };

  const handleOpenUserAgreement = () => {
    setIsShowUserAgree(!isShowUserAgree);
  };

  const handleOpenPrivacyPolicy = () => {
    setIsShowPrivacyPolicy(!isShowPrivacyPolicy);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && checked && address && checkedPP) {
      event.preventDefault();
      close();
      if (address) {
        setReferrerAddress(address);
      }
    }
  };

  return (
    <div className={styles.modal}>
      {isShowUserAgree && (
        <AgreementLayout setIsOpen={setIsShowUserAgree}>
          <ServiceAgreement />
        </AgreementLayout>
      )}
      {isShowPrivacyPolicy && (
        <AgreementLayout setIsOpen={setIsShowPrivacyPolicy}>
          <PrivacyPolicy />
        </AgreementLayout>
      )}
      {!isShowUserAgree && !isShowPrivacyPolicy && (
        <>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={11}>
              <h2 className="white">{t('need_inviter.enter_referral_link')}</h2>
            </Grid>
            <Grid item xs={1}>
              <MuiButton variant="text" sx={{ minWidth: 'auto' }} onClick={cancel}>
                <Typography variant="h5">&times;</Typography>
              </MuiButton>
            </Grid>
          </Grid>
          <div className={styles.mainContainer}>
            <div className={styles.modalInviterAddress}>
              <input
                disabled={isInputDisabled}
                className={styles.input}
                value={address}
                onChange={handleChange}
              />
              {error && <ErrorMessage message={error} className={styles.errorBlock} />}
              <Button
                customClass={styles.btn}
                type={EButtonType.FILLED}
                onClick={handleClick}
                disabled={isBtnDisabled}
              >
                {t('need_inviter.enter')}
              </Button>
            </div>
            <div className={styles.clientAgreement}>
              <input
                className={styles.checkbox}
                type={'checkbox'}
                checked={checked}
                onChange={handleChangeCheck}
                onKeyDown={onKeyDown}
              />
              <p className={styles.clientAgreementText} onClick={handleOpenUserAgreement}>
                <u className="primary">{t('need_inviter.sca_services_agreement')}</u>
              </p>
            </div>
            <div className={styles.clientAgreement}>
              <input
                className={styles.checkbox}
                type={'checkbox'}
                checked={checkedPP}
                onChange={handleChangeCheckPP}
                onKeyDown={onKeyDown}
              />
              <p className={styles.clientAgreementText} onClick={handleOpenPrivacyPolicy}>
                <u className="primary">{t('need_inviter.sca_privacy_policy')}</u>
              </p>
            </div>
            <ContactLiveChatMsg
              customClass={styles.helpInfo}
              problem={t('need_inviter.with_inviter_address')}
            />
          </div>
        </>
      )}
    </div>
  );
};
