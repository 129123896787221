import { httpClient } from '../httpClient';
import {
  ACCEPT_BONUS_PACKAGE,
  ADDITIONAL_TOKENS_AVAILABLE,
  ADMIN_ADDRESS,
  BONUS_PACKAGES,
  GIVE_AND_ACTIVATE_BONUS_PACKAGE,
  GIVE_BONUS_PACKAGE,
  PACKAGE,
  PACKAGE_PURCHASE,
  PACKAGES__AVAILABLE,
  USER_HISTORY,
} from '@app/constants';
import {
  IAcceptBonusPackage,
  IAcceptBonusPackageResponse,
  IAdditionalTokenAvailable,
  IBonusPackage,
  IGiveBonusPackage,
  IGiveBonusPackageResponse,
  IHistoryParams,
  IPackage,
  IPackageHistory,
} from '@app/types/packages';

export const packagesService = {
  async getPackages() {
    const { data } = await httpClient.get<IPackage[]>(PACKAGE, { params: { 'sort-price': 'ASC' } });
    return data;
  },

  async getPackagesAvailable() {
    const { data } = await httpClient.get<IPackage[]>(PACKAGES__AVAILABLE);
    return data;
  },

  async getBonusPackages(): Promise<IBonusPackage[]> {
    const { data } = await httpClient.get<IBonusPackage[]>(BONUS_PACKAGES);
    return data;
  },

  async acceptBonusPackage({ bonusPackageId, tokens }: IAcceptBonusPackage): Promise<IAcceptBonusPackageResponse> {
    const { data } = await httpClient.post<IAcceptBonusPackageResponse>(ACCEPT_BONUS_PACKAGE, {
      bonusPackageId,
      tokens,
    } as IAcceptBonusPackage);
    return data;
  },

  async giveBonusPackage({ userAddress, bonusPackageId, tokens }: IGiveBonusPackage): Promise<IGiveBonusPackageResponse> {
    const { data } = await httpClient.post<IGiveBonusPackageResponse>(GIVE_BONUS_PACKAGE, {
      userAddress,
      bonusPackageId,
      tokens,
    } as IGiveBonusPackage);
    return data;
  },

  async giveAndActivateBonusPackage({
    userAddress,
    bonusPackageId,
    tokens,
  }: IGiveBonusPackage): Promise<IGiveBonusPackageResponse> {
    const { data } = await httpClient.post<IGiveBonusPackageResponse>(GIVE_AND_ACTIVATE_BONUS_PACKAGE, {
      userAddress,
      bonusPackageId,
      tokens,
    } as IGiveBonusPackage);
    return data;
  },

  async getAdminAddress() {
    const { data } = await httpClient.get<string>(ADMIN_ADDRESS);
    return data;
  },

  async getUserHistory(params: IHistoryParams) {
    const { data } = await httpClient.get<IPackageHistory>(USER_HISTORY + '/' + params.key, { params });
    return data;
  },

  async sendTransactionHash(customData: { packageId: string, transactionHash: string }) {
    const { data } = await httpClient.post<{ transactionHash: string }>(PACKAGE_PURCHASE, customData);
    return data;
  },

  async getAdditionalTokenAvailable(): Promise<IAdditionalTokenAvailable> {
    const { data } = await httpClient.get<IAdditionalTokenAvailable>(ADDITIONAL_TOKENS_AVAILABLE);
    return data;
  },
};
