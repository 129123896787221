import { useState } from 'react';

interface IClipboardHook {
  copyToClipboard: (text: string | undefined) => Promise<void>;
  readFromClipboard: () => Promise<void>;
  clipboardValue: string;
}

export const useClipboard = (): IClipboardHook => {
  const [clipboardValue, setClipboardValue] = useState('');

  const copyToClipboard = async (text: string | undefined) => {
    try {
      await navigator?.clipboard?.writeText(text!);
      setClipboardValue(text!);
    } catch (err) {}
  };

  const readFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setClipboardValue(text);
    } catch (err) {}
  };

  return { copyToClipboard, readFromClipboard, clipboardValue };
};
